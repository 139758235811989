import React from 'react';
import { List, Datagrid, TextField, EditButton, Filter, TextInput } from 'react-admin';

import ListActionBar from '../common/ui/ListActionBar';
import exporter from './exporter';

const CustomerFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

const CustomerList = props => (
  <List
    {...props}
    bulkActionButtons={null}
    filters={<CustomerFilter />}
    actions={<ListActionBar />}
    exporter={exporter}
  >
    <Datagrid rowClick="edit">
      <TextField label="Customer Code" source="customerCode" />
      <TextField label="Sub Code" source="customerSubCode" />
      <TextField label="Company Name" source="companyName" />
      <TextField label="Contact Name" source="contactName" />
      <TextField label="Phone" source="phone" />
      <TextField label="Email" source="email" />
      <TextField label="Address" source="address" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CustomerList;
