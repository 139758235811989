import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { linkToRecord } from 'react-admin';
import { ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';

const styles = theme => ({
  baseItem: {
    minHeight: theme.spacing.unit * 6,
  },
  selectedItem: {
    minHeight: theme.spacing.unit * 6,
    background: theme.palette.action.selected,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

const enhance = compose(
  withRouter,
  withStyles(styles),
);

const SelectableList = ({ ids, data, basePath, optionText, getItemUrl = linkToRecord, classes, match: { params } }) =>
  ids.map(id => {
    const record = data[id];
    const itemClass = record.id === Number(params.id) ? classes.selectedItem : classes.baseItem;
    return (
      <Tooltip title={record[optionText]} key={id}>
        <Link to={getItemUrl(basePath, record.id)} className={classes.link}>
          <ListItem button className={classes.link} classes={{ root: itemClass }}>
            <ListItemText primary={record[optionText]} />
          </ListItem>
        </Link>
      </Tooltip>
    );
  });

SelectableList.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.objectOf(PropTypes.object),
};

export default enhance(SelectableList);
