import { GET_LIST } from 'react-admin';
import dataProvider from '../../../../provider/dataProvider';
import resources from '../../../../constants/resources';
import { getDiscountValue, getEstimatedTotalPriceExVat, getTotalPriceExVat } from '../../../../services/calcOrdersFunc';

export const handleChangeCustomer = (formData, field, props) => async (e, id) => {
  const {
    fetchStartAction,
    fetchEndAction,
    showNotificationAction,
    changeValue,
    customers,
    productItems,
    vatCodes,
  } = props;
  const saleOrderItemsIdx = formData.saleOrderItems.map(_ => _.productId);

  let customerPrices = [];
  let priceListPrices = [];
  let basePrices = [];
  const { priceListId } = customers[id];
  fetchStartAction();

  const promises = [
    dataProvider(GET_LIST, resources.PRODUCT_PRICE, {
      pagination: { page: 1, perPage: saleOrderItemsIdx.length || 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: { customerId: id, productId: saleOrderItemsIdx },
    }),
    dataProvider(GET_LIST, resources.PRODUCT, {
      pagination: { page: 1, perPage: saleOrderItemsIdx.length || 1 },
      sort: { field: 'id', order: 'DESC' },
      filter: { id: saleOrderItemsIdx },
    }),
  ];
  if (priceListId) {
    promises.push(
      dataProvider(GET_LIST, resources.PRICE_LIST_ITEM, {
        pagination: { page: 1, perPage: saleOrderItemsIdx.length || 1 },
        sort: { field: 'id', order: 'DESC' },
        filter: { priceListId, productId: saleOrderItemsIdx },
      }),
    );
  }

  try {
    const [customerPrice, productPrice, priceListPrice] = await Promise.all(promises);
    customerPrices = customerPrice.data;
    basePrices = productPrice.data;
    if (priceListPrice) {
      priceListPrices = priceListPrice.data;
    }
  } catch (err) {
    showNotificationAction(err.message, 'error');
  }

  fetchEndAction();
  const { deliveryAddress } = Object.values(customers).find(x => x.id === id) || {};

  changeValue('deliveryAddress', deliveryAddress);
  changeValue('deliveryAddressId', 0);
  changeValue('customerGroupId', (customers[id] || {}).customerGroupId);
  changeValue('currencyId', (customers[id] || {}).currencyType);
  changeValue('notes', (customers[id] || {}).popupNotes);

  if (field === 'code') changeValue('hiddenCustomerId', id);
  if (field === 'name') changeValue('customerId', id);

  (formData.saleOrderItems || []).forEach((item, index) => {
    const { discount } = customers[id];
    const { weight, quantity, productId } = item;

    const currentCustomerPrice = customerPrices.find(_ => _.productId === productId);
    const currentPriceListPrice = priceListPrices.find(_ => _.productId === productId);
    const currentBasePrice = basePrices.find(_ => _.id === productId);

    let price = currentBasePrice.pricePerUnit;
    let method = currentBasePrice.priceMethod;

    if (currentCustomerPrice) {
      price = currentCustomerPrice.pricePerUnit;
      method = currentCustomerPrice.priceMethod;
    } else if (currentPriceListPrice && priceListId) {
      price = currentPriceListPrice.pricePerUnit;
      method = currentPriceListPrice.priceMethod;
    }

    const vatCodeId = (productItems[productId] || {}).vatcodeId;
    const vatCode = vatCodes[vatCodeId];

    const totalPriceExVat = getTotalPriceExVat(method, price, discount, weight || quantity);
    const discountValue = getDiscountValue(price, discount);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(`saleOrderItems[${index}].pricePerUnit`, Number(price).toFixed(2));
    changeValue(`saleOrderItems[${index}].disc`, (discount || 0).toFixed(2));
    changeValue(`saleOrderItems[${index}].discPricePerUnit`, discountValue.toFixed(2));
    changeValue(`saleOrderItems[${index}].totalPriceExVat`, totalPriceExVat.toFixed(2));
    changeValue(`saleOrderItems[${index}].totalVatAmount`, estimatedTotalPriceExVat.toFixed(2));
  });
};
