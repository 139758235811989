import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import classnames from 'classnames';
import isNumber from 'lodash/isNumber';
import { compose } from 'redux';
import { connect } from 'react-redux';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontFamily: 'Public Sans',
    padding: '12px 16px',
    color: '#525252',
    minHeight: 48,
    borderRadius: 8,
    marginTop: 24,

    '&:hover': {
      backgroundColor: '#F6F7F9',
    },

    '&:first-child': {
      marginTop: 0,
    },
    '&:not($subItem).isActive': {
      backgroundColor: '#4491CF',
      color: '#fff',
    },
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'capitalize',
    flexGrow: 1,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 16,
    fill: 'none',

    '&:not(.isNewIcon) path': {
      fill: 'currentColor',
    },
  },
  expandIcon: {
    fill: 'currentColor',
    marginLeft: 8,
  },
  subItem: {
    marginTop: 20,
    minHeight: 24,
    padding: 0,
    color: '#808080',

    '&:first-child': {
      marginTop: 0,
    },

    '&:hover': {
      backgroundColor: 'transparent',
      color: '#525252',
    },

    '&.isActive': {
      color: '#4491CF',

      '&:hover': {
        color: '#2E77B3',
      },

      '& $dot': {
        visibility: 'visible',
      },
    },
  },
  dot: {
    visibility: 'hidden',
    backgroundColor: 'currentColor',
    borderRadius: 4,
    width: 6,
    height: 6,
    marginRight: 8,
  },
  badge: {
    minWidth: 39,
    height: 20,
    backgroundColor: '#EC463C',
    borderRadius: 16,
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Component = ({
  open,
  classes,
  name,
  expanded,
  showExpandIcon,
  isSubItem,
  isActive,
  icon,
  link,
  onClick,
  notifications,
  isNewIcon,
}) => {
  let preparedNotifications = notifications;

  if (isNumber(notifications)) {
    preparedNotifications = notifications > 99 ? `${notifications}+` : notifications;
  }

  const Icon = icon || null;

  const showIcon = Icon && ((isSubItem && !open) || !isSubItem);
  const showDot = isSubItem && (!Icon || (Icon && open));

  return (
    <MenuItem
      classes={{ root: classNames(classes.root, { [classes.subItem]: isSubItem, isActive }) }}
      component={link ? NavLink : undefined}
      to={link}
      onClick={onClick}
      disableRipple={isSubItem}
    >
      {showIcon && <Icon className={classnames(classes.icon, { isNewIcon: isNewIcon || isSubItem })} />}
      {showDot && <span className={classes.dot} />}
      <span className={classes.text}>{name}</span>
      {!!preparedNotifications && <span className={classes.badge}>{preparedNotifications}</span>}
      {showExpandIcon && (
        <>{expanded ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={classes.expandIcon} />}</>
      )}
    </MenuItem>
  );
};

Component.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  showExpandIcon: PropTypes.bool,
  isSubItem: PropTypes.bool,
  isActive: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.any,
  link: PropTypes.string,
  onClick: PropTypes.func,
  notifications: PropTypes.number,
  isNewIcon: PropTypes.bool,
};

export const NewDesignMenuItem = compose(
  connect(state => ({ open: state.admin.ui.sidebarOpen })),
  withStyles(styles),
)(Component);
