import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, ReferenceField,NumberField,WithPermissions } from 'react-admin';

import resources from '../../constants/resources';
import ListActionBar from '../common/ui/ListActionBar';
import Filter from './Filter';
import formats from '../../constants/formats';
import exporter from './exporter';
import { UiList } from '../common/ui/NewDesign/List';
import { NewDesignFilter } from './styles/NewDesignFilter';
import { UiTable } from '../common/ui/NewDesign/Table/index';
import { CustomerTableCell } from '../common/tableCells/Customer';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  table: {
    '& .deliveryAddress': {
      width: 400,

      '@media (max-width: 1440px)': {
        width: 228,
      },
    },
  },
  field: {
    display: 'inline-block',
    width: '100%',
  },
});
const PurchaseOrderList = ({ permissions, classes, ...props }) => {
  return permissions ? (
   <UiList 
    {...props} 
  filters={<NewDesignFilter />} 
  bulkActionButtons={null} 
  actions={<ListActionBar />} 
  exporter={exporter}
  sort={{ field: 'supplierId', order: 'DESC' }}
  classNameTable={classes.table}
  title='Orders'
  >
    {tableProps => (
    <UiTable rowClick="edit" howEditAction {...tableProps}>
      <NumberField label="Purchase Order" source="id" />
      <ReferenceField label="Supplier" source="supplierId" reference={resources.SUPPLIER} linkType="show" allowEmpty>
        <TextField source="companyName" />
      </ReferenceField>
      <DateField label="Order Date" source="timeDate" locales={formats.LOCALES} showTime />
    </UiTable>
    )}
  </UiList>
  ) : null;
};
PurchaseOrderList.propTypes = {
  permissions: PropTypes.string,
};
const PurchaseOrderListWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <PurchaseOrderList permissions={permissions} {...props} />} />
);
export default withStyles(styles)(PurchaseOrderListWithPermissions);
