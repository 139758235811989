import React, { useState } from 'react';
import { bool, func, instanceOf } from 'prop-types';
import { SaveButton, Toolbar as RaToolbar } from 'react-admin';
import { Button, CircularProgress, withStyles } from '@material-ui/core';

import { openReport } from '../../../common/services/openReport';
import resources from '../../../../constants/resources';
import toolbar from '../styles/toolbar';
import { UiButton } from '../../../common/ui/NewDesign/Button';
import { SaveIcon } from '../../../common/icons/Save';
import { CopyIcon } from '../../../common/icons/Copy';

const styles = theme => ({
  ...toolbar(theme),

  containerNew: {
    backgroundColor: 'transparent',
    marginTop: 32,
    marginBottom: 32,
    minHeight: 'auto',
    padding: 0,
    justifyContent: 'flex-end',
    gap: '16px',
  },
});

const Toolbar = ({ classes, isEdit, record: { id, dispatched }, showNotificationAction, isCustomer, ...props }) => {
  const [loading, setLoading] = useState(false);
  const onClick = () => openReport(id, resources.SALE_ORDER_REPORT, showNotificationAction, setLoading);
  return (
    <RaToolbar {...props} className={classes.containerNew}>
      <UiButton
        component={SaveButton}
        icon={<SaveIcon />}
        label="Save"
        redirect="list"
        submitOnEnter
        disabled={dispatched}
        variant="text"
      />

      {!isCustomer && isEdit && (
        <UiButton component={Button} disabled={loading} onClick={onClick}>
          {loading ? (
            <CircularProgress size={25} thickness={5} />
          ) : (
            <>
              <CopyIcon />
              <span>Open Report</span>
            </>
          )}
        </UiButton>
      )}
    </RaToolbar>
  );
};

export default withStyles(styles)(Toolbar);

Toolbar.propTypes = {
  classes: instanceOf(Object).isRequired,
  record: instanceOf(Object).isRequired,
  isEdit: bool.isRequired,
  showNotificationAction: func.isRequired,
  isCustomer: bool,
};
