import React from 'react';
import PropTypes from 'prop-types';

import { TablePagination } from '@material-ui/core';
import { sanitizeListRestProps } from 'react-admin';

const emptyArray = [];

const SimplePagination = ({ page, perPage, total, setPage, translate, ...rest }) => {
  const getNbPages = () => Math.ceil(total / perPage) || 1;

  const handlePageChange = (event, _page) => {
    if (event) {
      event.stopPropagation();
    }

    if (_page < 0 || _page > getNbPages() - 1) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', {
          page: _page + 1,
        }),
      );
    }
    setPage(_page + 1);
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return translate('ra.navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    });
  };

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onChangePage={handlePageChange}
      rowsPerPageOptions={emptyArray}
      component="span"
      labelDisplayedRows={labelDisplayedRows}
      {...sanitizeListRestProps(rest)}
    />
  );
};

SimplePagination.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func,
  translate: PropTypes.func,
};

export default SimplePagination;
