import React from 'react';
import PropTypes from 'prop-types';
import { Filter, TextInput } from 'react-admin';

const PricingFilter = ({ searchLabel, source, fullWidth, ...rest }) => (
  <Filter {...rest}>
    <TextInput label={searchLabel} source={source} fullWidth={fullWidth} alwaysOn />
  </Filter>
);

PricingFilter.propTypes = {
  searchLabel: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

export default PricingFilter;
