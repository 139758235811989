import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListController } from 'ra-core';
import ListToolbar from 'ra-ui-materialui/esm/list/ListToolbar';

import { ListActions } from './Actions';
import { BulkActionsToolbar } from '../../BulkActionsToolbar';
import classnames from 'classnames';
import { UiPagination } from './Pagination';

const getColumnWidth = (columns, padding) => `calc((100% - ${columns * padding - padding}px)/${columns})`;

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  filtersWrapper: {
    position: 'relative',
  },
  filters: {
    padding: 16,
    borderRadius: 8,
    boxShadow: '0px 4px 18px 0px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#fff',

    '& > div': {
      padding: 0,
    },
  },
  toolbar: {
    gap: '24px',
    width: '100%',
    margin: 0,
    padding: 0,
    alignItems: 'start',

    display: 'flex',

    '& > div': {
      width: '100%',
      maxWidth: getColumnWidth(5, 24),
    },

    '@media (max-width: 1600px)': {
      '& > div': {
        maxWidth: getColumnWidth(3, 24),
      },
    },

    '@media (max-width: 1024px)': {
      '& > div': {
        maxWidth: getColumnWidth(2, 24),

        '&:nth-child(1), &:nth-child(2), &:nth-child(3)': {
          maxWidth: getColumnWidth(3, 24),
        },
      },
    },

    '& > div:last-child': {
      display: 'none',
    },

    '& .filter-field': {
      '& > div:first-child': {
        width: '100%',
      },

      '& > div:last-child': {
        display: 'none',
      },
    },
  },
  table: {
    padding: 8,
    borderRadius: 12,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 18px 0px rgba(0, 0, 0, 0.08)',
  },
});

const Component = ({ classes, filters, children, pagination, classNameTable, ...props }) => {
  return (
    <ListController {...props}>
      {controllerProps => (
        <div className={classes.root}>
          <ListActions {...props} {...controllerProps} />
          <div className={classes.filtersWrapper}>
            {props.bulkActions !== false &&
              props.bulkActionButtons !== false &&
              props.bulkActionButtons &&
              !props.bulkActions && (
                <BulkActionsToolbar {...controllerProps}>{props.bulkActionButtons}</BulkActionsToolbar>
              )}
            {filters && (
              <div className={classes.filters}>
                <ListToolbar
                  className={classes.toolbar}
                  filters={filters}
                  {...controllerProps}
                  bulkActions={props.bulkActions}
                  permanentFilter={props.filter}
                />
              </div>
            )}
          </div>

          <div className={classnames(classes.table, classNameTable)}>
            {children({
              ...controllerProps,
              hasBulkActions: props.bulkActions || props.bulkActionButtons,
            })}
            {/*<Pagination {...controllerProps} />*/}
            <UiPagination {...controllerProps} />
          </div>
        </div>
      )}
    </ListController>
  );
};

export const UiList = withStyles(styles)(Component);
