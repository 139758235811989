const styles = () => ({
  form: {
    display: 'flex',
    gap: '48px',
    flexDirection: 'column',
    margin: '48px 0',
  },

  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },

  button: {
    width: '100%',
    textTransform: 'initial',

    '&.filled': {
      textTransform: 'uppercase',
    },
  },

  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

    '& input': {
      height: 'auto',
    },
  },

  inputIcon: {
    width: 20,
    height: 20,
    color: '#101010',
  },

  adornment: {
    cursor: 'pointer',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',

    '&.disabled': {
      cursor: 'default',

      '& $inputIcon': {
        color: '#A9AFBC',
      },
    },
  },

  poweredWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    fontFamily: 'Public Sans',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },

  poweredLogo: {
    width: 93,
    height: 55,
  },

  text: {
    fontFamily: 'Public Sans',
  },
});

export default styles;
