import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ChevronRightIcon } from '../../../icons/ChevronRight';
import classnames from 'classnames';

const styles = () => ({
  root: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonBase: {
    background: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    fontFamily: 'Public Sans',
    fontSize: 14,
    display: 'flex',
  },
  buttonPage: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 32,
    height: 32,
    color: '#9D9D9D',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: 4,
    padding: '2px 6px',
    border: 'none',

    '&:not(.isHellip):hover': {
      backgroundColor: '#DCDCDC',
    },

    '&.isActive': {
      backgroundColor: '#EEEEEE',
      color: '#494646',
      fontSize: 14,
    },
    '&.isHellip': {
      color: '#0F4686',
    },
  },
  button: {
    padding: '6px 8px 6px 16px',
    border: '1px solid #757575',
    borderRadius: 8,
    color: '#000000',
    gap: '4px',
    fontWeight: 500,
    lineHeight: '24px',
    alignItems: 'center',

    '&.isLeft': {
      padding: '6px 16px 6px 8px',
    },
  },
  icon: {
    width: 20,
    height: 20,

    '&.isLeft': {
      transform: 'rotate(180deg)',
    },
  },
});

const TablePaginationActions = ({ setPage, page: localePage, perPage, total, classes }) => {
  const currentPage = localePage - 1;

  const changePage = (event, page) => {
    event && event.stopPropagation();
    setPage(page + 1);
  };

  const handleBackButtonClick = event => changePage(event, currentPage - 1);

  const handleNextButtonClick = event => changePage(event, currentPage + 1);

  const range = () => {
    const nbPages = Math.ceil(total / perPage) || 1;

    if (isNaN(currentPage) || nbPages === 1) {
      return [];
    }

    const input = [];
    // display page links around the current page
    if (currentPage > 1) {
      input.push(1);
    }
    if (currentPage === 3) {
      input.push(2);
    }
    if (currentPage > 3) {
      input.push('.');
    }
    if (currentPage > 0) {
      input.push(currentPage);
    }
    input.push(currentPage + 1);
    if (currentPage < nbPages - 1) {
      input.push(currentPage + 2);
    }
    if (currentPage === nbPages - 4) {
      input.push(nbPages - 1);
    }
    if (currentPage < nbPages - 4) {
      input.push('.');
    }
    if (currentPage < nbPages - 2) {
      input.push(nbPages);
    }

    return input;
  };

  const goToPage = page => event => changePage(event, parseInt(page, 10));

  return (
    <div className={classes.root}>
      {currentPage !== 0 && (
        <button className={classnames(classes.buttonBase, classes.button, 'isLeft')} onClick={handleBackButtonClick}>
          <ChevronRightIcon className={classnames(classes.icon, 'isLeft')} />
          Prev
        </button>
      )}

      {range().map((pageNum, index) =>
        pageNum === '.' ? (
          <span key={`hyphen_${index}`} className={classnames(classes.buttonBase, classes.buttonPage, 'isHellip')}>
            &hellip;
          </span>
        ) : (
          <button
            key={pageNum}
            className={classnames(classes.buttonBase, classes.buttonPage, {
              isActive: pageNum === currentPage + 1,
            })}
            onClick={goToPage(pageNum - 1)}
          >
            {pageNum}
          </button>
        ),
      )}

      {currentPage < Math.ceil(total / perPage) - 1 && (
        <button className={classnames(classes.buttonBase, classes.button)} onClick={handleNextButtonClick}>
          Next
          <ChevronRightIcon className={classes.icon} />
        </button>
      )}
    </div>
  );
};

export const UiPagination = withStyles(styles)(TablePaginationActions);

// export const UiPagination = props => {
//   console.log(props);
//   return (
//     <Pagination {...props} ActionsComponent={withStyles(styles)(TablePaginationActions)} />
//   )
// };
