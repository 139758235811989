import React, { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Query } from 'react-admin';
import { Close as CloseIcon, AddCircleOutline as AddIcon } from '@material-ui/icons';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  LinearProgress,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    overflow: 'auto',
    '& > li:last-child': {
      borderBottom: 'none',
    },
  },
  tableRow: {
    '& .do-currency-sign': {
      width: 'auto',
      marginRight: 4,
      '& > p': {
        position: 'relative',
        top: -1,
      },
    },
  },
  disableRow: {
    backgroundColor: red[200],
    '& input, div, span': {
      color: 'white',
    },
    '& .do-currency-sign': {
      '& > p': {
        color: 'white',
      },
    },
    '& a > span': {
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
    },
  },
  headerItem: {
    textTransform: 'Capitalize',
    fontSize: '0.9rem',
  },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  inputWrapper: {
    minWidth: 90,
    '& input': {
      width: '100%',
      fontSize: '0.9rem',
    },
    '& > div': {
      width: '100%',
      minWidth: 'auto',
    },
    '& > div > div': {
      width: '100%',
      minWidth: 'auto',
    },
    '& > div > div > div': {
      width: '100%',
      minWidth: 'auto',
    },
  },
  fieldWrapper: {
    minWidth: 20,
    paddingTop: 20,
    fontSize: '0.9rem',
    '& input': {
      width: '100%',
      fontSize: '0.9rem',
    },
    '& > div': {
      width: '100%',
      minWidth: 'auto',
    },
    '& > div > div': {
      width: '100%',
      minWidth: 'auto',
    },
    '& > div > div > div': {
      width: '100%',
      minWidth: 'auto',
    },
  },
  tableCell: {
    verticalAlign: 'baseline',
    '& a > span': {
      whiteSpace: 'nowrap',
    },
    '&:first-child > div': {
      minWidth: 120,
    },
  },
  '@media (max-width: 1920px)': {
    headerItem: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    tableCell: {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
});

class TableFormIterator extends Component {
  constructor(props) {
    super(props);
    /* eslint-disable-next-line no-nested-ternary */
    this.nextId = props.fields.length ? props.fields.length : 0;

    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
  }

  removeField = index => () => {
    const { fields } = this.props;

    this.ids.splice(index, 1);

    fields.remove(index);
  };

  addField = () => {
    const { fields, addItemValidation } = this.props;

    let validate = true;
    if (addItemValidation) {
      validate = addItemValidation();
    }
    if (!validate) return;
    this.ids.push((this.nextId += 1));

    fields.push({});
  };

  isQuery = () => {
    const { requestQuery, children } = this.props;
    let isQuery = false;

    if (requestQuery)
      Children.forEach(children, input => {
        if (input.props.fromQuery) isQuery = true;
      });

    return isQuery;
  };

  renderInput = (input, member, fieldIndex) => {
    const { classes } = this.props;
    return (
      <TableCell key={input.props.source} className={classes.tableCell} padding="checkbox">
        <div className={classes.inputWrapper}>
          {cloneElement(input, {
            source: `${member}${input.props.source ? `.${input.props.source}` : ''}`,
            label: '',
            index: fieldIndex,
          })}
        </div>
      </TableCell>
    );
  };

  render() {
    const {
      classes = {},
      children,
      fields,
      meta: { error, submitFailed },
      disableAdd,
      disableRemove,
      tableRowDisable,
      requestQuery,
    } = this.props;

    if (!fields) return null;
    return (
      <div className={classes.root}>
        {submitFailed && error && <span>{error}</span>}

        <Table>
          <TableHead>
            <TableRow>
              {Children.map(children, input => (
                <TableCell key={input.source} className={classes.headerItem} padding="checkbox">
                  {input.props.label}
                </TableCell>
              ))}
              {!disableRemove && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((member, fieldIndex) => (
              <TableRow
                key={member}
                className={classNames(classes.tableRow, { [classes.disableRow]: tableRowDisable })}
              >
                {this.isQuery() ? (
                  <Query {...requestQuery(fields.get(fieldIndex))}>
                    {({ data, loading }) => {
                      return Children.map(children, input =>
                        input.props.fromQuery ? (
                          <TableCell>
                            <div className={classes.fieldWrapper}>
                              {loading ? (
                                <LinearProgress />
                              ) : (
                                <span>{(data[0] || {})[input.props.querySource] || 0}</span>
                              )}
                            </div>
                          </TableCell>
                        ) : (
                          this.renderInput(input, member, fieldIndex)
                        ),
                      );
                    }}
                  </Query>
                ) : (
                  Children.map(children, input => this.renderInput(input, member, fieldIndex))
                )}

                {!disableRemove && (
                  <TableCell className={classes.action} align="right" padding="none">
                    <Button size="small" onClick={this.removeField(fieldIndex)}>
                      <CloseIcon className={classes.leftIcon} />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {!disableAdd && (
              <TableRow>
                <TableCell colSpan={children.length + (disableRemove ? 0 : 1)}>
                  <Button size="small" onClick={this.addField}>
                    <AddIcon className={classes.leftIcon} />
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

TableFormIterator.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  disableAdd: PropTypes.bool,
  requestQuery: PropTypes.func,
  disableRemove: PropTypes.bool,
  tableRowDisable: PropTypes.bool,
  addItemValidation: PropTypes.func,
};

export default compose(withStyles(styles))(TableFormIterator);
