import { crudUpdate, UPDATE, FETCH_END, CRUD_UPDATE_SUCCESS } from 'react-admin';
import { put, takeEvery, all } from 'redux-saga/effects';

import resources from '../constants/resources';

function* worker(action) {
  const {
    meta: { resource, fetchResponse, fetchStatus },
  } = action;
  if (resource !== resources.PURCHASE_ORDER) return;
  if (fetchResponse !== UPDATE) return;
  if (fetchStatus !== FETCH_END) return;

  const {
    requestPayload: {
      data: { purchaseOrderItems },
      previousData: { purchaseOrderItems: previousPurchaseOrderItems },
    },
  } = action;

  const deletedPurchaseOrderItems = previousPurchaseOrderItems.filter(
    ({ id }) => !purchaseOrderItems.some(({ id: oldId }) => oldId === id),
  );

  const actions = deletedPurchaseOrderItems.map(item => {
    const actionCrudUpdate = crudUpdate(
      resources.PURCHASE_ORDER_ITEM,
      item.id,
      { ...item, purchaseOrderId: null },
      item,
      '',
      null,
    );

    return {
      ...actionCrudUpdate,
      meta: { ...actionCrudUpdate.meta, onSuccess: {} },
    };
  });
  yield all(actions.map(a => put(a)));
}

export default function* removeItemRefSaleOrder() {
  yield takeEvery(CRUD_UPDATE_SUCCESS, worker);
}
