import { ordersConstants } from '../constants/ordersConstants';

export const toFixed = num => {
  return Math.round(num * 100) / 100;
};

export const getDiscountValue = (pricePerUnit, discPercent) => {
  return toFixed(pricePerUnit - pricePerUnit * (discPercent / 100));
};

export const getDiscountPercent = (pricePerUnit, discValue) => {
  return toFixed(+pricePerUnit ? 100 - (discValue / pricePerUnit) * 100 : 0);
};

export const getTotalPriceExVat = (priceMethod, pricePerUnit, discPercent = 0, quantity = 0) => {
  let totalPriceExVat = toFixed(((pricePerUnit || 0) * (100 - discPercent)) / 100);

  switch (priceMethod) {
    case ordersConstants.quantityPriceMethod:
      totalPriceExVat *= quantity;
      break;
    case ordersConstants.weightPriceMethod:
      totalPriceExVat *= quantity;
      break;
    default:
      return 0;
  }

  return toFixed(totalPriceExVat);
};

export const getEstimatedTotalPriceExVat = (totalPriceExVat, vatCode = {}) => {
  const { percentage = 0 } = vatCode;
  return toFixed(+totalPriceExVat + +totalPriceExVat * (percentage / 100));
};

export const getBasePrice = (priceMethod, pricePerUnit, quantityReceived = 0, weightReceived = 0) => {
  let basePrice = 0;
  switch (priceMethod) {
    case ordersConstants.quantityPriceMethod:
      basePrice = quantityReceived * pricePerUnit;
      break;
    case ordersConstants.weightPriceMethod:
    case ordersConstants.typicalPriceWeightMethod:
      basePrice = weightReceived * pricePerUnit;
      break;
    default:
  }
  return basePrice || 0;
};
