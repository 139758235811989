import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import formats from '../../constants/formats';

const exporter = records => {
  const data = records.map(record => ({
    'Customer Code': record.customerCode,
    'Sub Code': record.customerSubCode,
    'Company Name': (record.companyName || '').trim(),
    'Contact Name': (record.contactName || '').trim(),
    Phone: record.phone,
    Email: record.email,
    Address: record.address,
  }));

  const csv = convertToCSV({
    data,
  });
  downloadCSV(csv, `customers_${moment().format(formats.MOMENT)}`);
};

export default exporter;
