import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  CardActions,
  ReferenceInput,
  SaveButton,
  withDataProvider,
  crudCreate,
  showNotification,
  GET_ONE,
  GET_LIST,
  BulkDeleteButton,
  TextInput,
} from 'react-admin';
import { Field } from 'redux-form';

import AutocompleteInput from '../../../common/ui/AutocompleteInputStyled';
import { PRODUCT_ID } from '../../constants';
import resources from '../../../../constants/resources';
import styles from './styles';

const onChange = (dataProvider, selectedListId, dispatch, change) => async (e, productId) => {
  const requestParams = {
    filter: { priceListId: selectedListId, productId },
    sort: { sort: 'id', order: 'DESC' },
    pagination: { page: 1, perPage: 1 },
  };
  const { data } = await dataProvider(GET_LIST, resources.PRICE_LIST_ITEM, requestParams);
  if (data.length) {
    dispatch(showNotification('The product already added to curret price list', 'warning'));
    change(PRODUCT_ID, '');
    return;
  }
  const {
    data: { priceMethod, pricePerUnit },
  } = await dataProvider(GET_ONE, resources.PRODUCT, { id: productId }, '/');

  const priceItemData = {
    priceListId: selectedListId,
    priceMethod,
    pricePerUnit,
    productId,
  };
  dispatch(crudCreate(resources.PRICE_LIST_ITEM, priceItemData, '/', false));
  change(PRODUCT_ID, '');
  dispatch({ type: 'RA/REFRESH_VIEW' });
};

const CardActionEdit = ({
  handleSubmit,
  selectedListId,
  dataProvider,
  dispatch,
  itemIdsForDelete,
  reset,
  change,
  classes: { card, fieldWrap, fieldWrapMargin, buttonWrap },
}) => (
  <CardActions className={card}>
    <div className={fieldWrap}>
      <ReferenceInput
        reference={resources.PRODUCT}
        resource={resources.PRODUCT}
        source="productId"
        label="Add Product"
        name="productId"
        allowEmpty
        onChange={onChange(dataProvider, selectedListId, dispatch, change)}
      >
        <AutocompleteInput
          optionText={item => (item.productCode && item.productCode.trim()) || ''}
          focusInputOnSuggestionClick={false}
        />
      </ReferenceInput>
    </div>

    <div className={fieldWrapMargin}>
      <Field name="description" component={TextInput} label="Name" />
    </div>

    {itemIdsForDelete && !!itemIdsForDelete.length && (
      <div className={buttonWrap}>
        <BulkDeleteButton
          selectedIds={itemIdsForDelete}
          resource={resources.PRICE_LIST_ITEM}
          onClick={() => {
            setTimeout(reset);
          }}
        />
      </div>
    )}

    <div className={buttonWrap}>
      <SaveButton type="submit" onClick={handleSubmit} />
    </div>
  </CardActions>
);

CardActionEdit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedListId: PropTypes.string.isRequired,
  dataProvider: PropTypes.func.isRequired,
  itemIdsForDelete: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.objectOf(PropTypes.string),
};

const enhance = compose(
  withDataProvider,
  withStyles(styles),
);

export default enhance(CardActionEdit);
