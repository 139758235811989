import React, { useMemo, useState } from 'react';
import { ColumnField } from './common/ColumnField';
import { MoreModal } from './common/MoreModal';

export const IngredientsField = ({ model }) => {
  if (!model) {
    return null;
  }

  const ingredients = useMemo(
    () =>
      Array(10)
        .fill(1)
        .map((_, index) => model[`ingredientField${index + 1}`])
        .filter(Boolean)
        .join(', '),
    [model],
  );

  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const openModal = () => setIsOpenedModal(true);
  const closeModal = () => setIsOpenedModal(false);

  return (
    <>
      <ColumnField checked={!!ingredients} onClick={openModal} />
      <MoreModal open={isOpenedModal} onClose={closeModal} title="Ingredients">
        {ingredients}
      </MoreModal>
    </>
  );
};
