import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, setListSelectedIds } from 'react-admin';
import { Grid } from '@material-ui/core';
import { compile } from 'path-to-regexp';
import SimplePagination from '../../common/ui/SimplePagination';
import SelectableList from '../../common/ui/SelectableList';
import CustomerProductsEdit from './edit';
import resources from '../../../constants/resources';
import Filter from '../components/Filter';

class CustomerPrices extends Component {
  componentDidUpdate(prevProps) {
    const { ids, setSelectedIds } = this.props;

    if (ids.length && !prevProps.ids.length) {
      setSelectedIds(resources.CUSTOMER, [ids[0]]);
    }
  }

  render() {
    const {
      location,
      match,
      match: { path, params },
    } = this.props;
    const { id } = params || {};
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <List
            sort={{ field: 'customerCode', order: 'ASC' }}
            location={location}
            match={match}
            basePath={path}
            resource={resources.CUSTOMER}
            exporter={false}
            hasCreate={false}
            hasEdit={false}
            hasList={false}
            hasShow={false}
            actions={null}
            bulkActionButtons={null}
            title="Customer Prices - Active"
            filters={<Filter searchLabel="Customer search" source="customerCode" />}
            pagination={<SimplePagination />}
          >
            <SelectableList optionText="customerCode" getItemUrl={(basePath, id) => compile(basePath)({ id })} />
          </List>
        </Grid>

        <Grid item xs={12} sm={8} md={9} lg={9}>
          <CustomerProductsEdit selectedId={id} />
        </Grid>
      </Grid>
    );
  }
}

CustomerPrices.propTypes = {
  location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  match: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object])).isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const resourceState = state.admin.resources[resources.CUSTOMER];

  return {
    selectedIds: resourceState.list.selectedIds,
    ids: resourceState.list.ids,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedIds: setListSelectedIds,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerPrices);
