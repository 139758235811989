import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const NewDesignSalesIcon = props => (
  <SvgIcon {...props}>
    <path d="M3 3V21H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10 10H8C7.44772 10 7 10.4477 7 11V16C7 16.5523 7.44772 17 8 17H10C10.5523 17 11 16.5523 11 16V11C11 10.4477 10.5523 10 10 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 5H16C15.4477 5 15 5.44772 15 6V16C15 16.5523 15.4477 17 16 17H18C18.5523 17 19 16.5523 19 16V6C19 5.44772 18.5523 5 18 5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
NewDesignSalesIcon.displayName = 'NewDesignSales';
NewDesignSalesIcon.muiName = 'SvgIcon';
