import React from 'react';
import { withStyles, TextField, InputAdornment, IconButton } from '@material-ui/core/';
import { Search } from '@material-ui/icons/';

const styles = theme => ({
  searchInput: {
    width: '100%',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    boxSizing: 'border-box',
    '& input': {
      width: '100%',
    },
  },
});

export default withStyles(styles)(({ classes, searchCustomer }) => {
  return (
    <div className={classes.searchInput}>
      <TextField
        placeholder="Search"
        margin="normal"
        onChange={searchCustomer}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Search">
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
});
