import React from 'react';
import { Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { UiBaseInput } from '../common/ui/NewDesign/inputs/BaseInput';

const styles = () => ({
  inputRoot: {
    height: 52,
  },
  inputDisabled: {},
  inputUnderline: {
    '&:before, &:hover:before, &$inputDisabled:before': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
  },
  input: {
    paddingLeft: 0,
    paddingRight: 16,
  },

  labelRoot: {
    transform: 'translate(0, 14px) scale(1)',
  },
  labelShrink: {
    transform: 'translate(0, 4px) scale(0.75)',
  },

  helperTextRoot: {
    marginTop: 4,
  },
});

const renderInput = ({ meta: { touched, error } = {}, input: inputProps, classes, onFocus, ...props }) => {
  const handleFocus = event => {
    inputProps.onFocus(event);
    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <UiBaseInput
      component={TextField}
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      onFocus={handleFocus}
      classes={classes}
    />
  );
};

export const AuthField = withStyles(styles)(props => <Field {...props} component={renderInput} />);
