import React from 'react';

export const SearchIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2 17.5998C14.7346 17.5998 17.6 14.7344 17.6 11.1998C17.6 7.66518 14.7346 4.7998 11.2 4.7998C7.66536 4.7998 4.79999 7.66518 4.79999 11.1998C4.79999 14.7344 7.66536 17.5998 11.2 17.5998Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2 19.1997L15.72 15.7197"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
