import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Title } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import DefaultTitle from '../common/ui/DefaultTitle';
import { HouseIcon } from './icons/House';
import { PhoneIcon } from './icons/Phone';
import { UiButton } from '../common/ui/NewDesign/Button';
import { httpClient } from '../../provider/dataProvider';
import { API_URL } from '../../constants/urls';
import { EmailIcon } from './icons/Email';

const styles = () => ({
  root: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  card: {
    display: 'flex',
    width: '608px',
    padding: '48px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    borderRadius: '8px',
    background: '#F6F7F9',
    boxShadow: 'none',
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    color: '#000',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Public Sans',
    fontWeight: 700,
    lineHeight: '24px',
    marginBottom: 0,
  },
  phone: {
    color: '#000',
    fontSize: '32px',
    fontFamily: 'Public Sans',
    fontWeight: 700,
    lineHeight: '48px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    textDecoration: 'none',
  },
});

const HelpAboutPage = ({ classes }) => {
  const [supplier, setSupplier] = useState(null);

  useEffect(() => {
    httpClient(`${API_URL}/AuthenticationSupplier/single-supplier`).then(response =>
      setSupplier(response.json.currentSupplier || {}),
    );
  }, []);

  return (
    <div className={classes.root}>
      <Title title={<DefaultTitle />} />
      {supplier ? (
        <Card className={classes.card}>
          <HouseIcon />
          <Typography paragraph className={classes.address}>
            {supplier.name}
          </Typography>
          {supplier.phone && (
            <Typography paragraph className={classes.phone} component="a" href="tel:+353045874333">
              <PhoneIcon />
              {supplier.phone}
            </Typography>
          )}
          {supplier.email && (
            <Typography paragraph className={classes.phone} component="a" href={`mailto:${supplier.email}`}>
              <EmailIcon />
              {supplier.email}
            </Typography>
          )}
          <UiButton
            label="Get Direction"
            onClick={() => {
              console.log('clicked');
            }}
          />
        </Card>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

HelpAboutPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(HelpAboutPage);
