import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CurrencySign from '../../common/ui/CurrencySign';
import { getEstimatedTotalPriceExVat, getTotalPriceExVat } from '../../../services/calcOrdersFunc';

const styles = () => ({
  totalSection: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

class TotalSection extends Component {
  static getTotal = (items = [], vatCodes = {}, productItems = {}) => {
    const total = items.reduce(
      (accumulator, item) => {
        const { totalWithDiscount, totalWithoutDiscount, totalWithVat } = accumulator;
        const { priceMethod, pricePerUnit, disc, weightReceived, quantityReceived, productId } = item;
        const { purchasesVatcodeId } = productItems[productId] || {};
        const vatCode = vatCodes[purchasesVatcodeId];

        const withDiscount = getTotalPriceExVat(priceMethod, pricePerUnit, disc, weightReceived || quantityReceived);
        const withoutDiscount = getTotalPriceExVat(priceMethod, pricePerUnit, 0, weightReceived || quantityReceived);
        const withVat = getEstimatedTotalPriceExVat(withDiscount, vatCode);

        return {
          totalWithDiscount: totalWithDiscount + withDiscount,
          totalWithoutDiscount: totalWithoutDiscount + withoutDiscount,
          totalWithVat: totalWithVat + withVat,
        };
      },
      {
        totalWithDiscount: 0,
        totalWithoutDiscount: 0,
        totalWithVat: 0,
      },
    );

    const { totalWithDiscount, totalWithoutDiscount, totalWithVat } = total;

    return {
      sub: totalWithoutDiscount,
      discount: totalWithoutDiscount - totalWithDiscount,
      vat: totalWithVat - totalWithDiscount,
      total: totalWithVat,
    };
  };

  shouldComponentUpdate(nextProps) {
    const { formData, vatCodes } = this.props;
    return (
      (formData || {}).purchaseOrderItems !== (nextProps.formData || {}).purchaseOrderItems ||
      vatCodes !== nextProps.vatCodes
    );
  }

  render() {
    const { formData, classes, vatCodes, productItems, changeValue } = this.props;
    const { sub, discount, vat, total } = TotalSection.getTotal(
      (formData || {}).purchaseOrderItems,
      vatCodes,
      productItems,
    );
    if (formData && formData.netTotal !== total) changeValue('netTotal', total);
    return (
      <Grid container spacing={24} className={classes.totalSection}>
        <Grid item>
          <Typography>
            {'Sub Total: '}
            <CurrencySign />
            {sub.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {'- Disc: '}
            <CurrencySign />
            {discount.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {'+ VAT: '}
            <CurrencySign />
            {vat.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {'TOTAL: '}
            <CurrencySign />
            {total.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

TotalSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  formData: PropTypes.instanceOf(Object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  productItems: PropTypes.objectOf(PropTypes.object),
  changeValue: PropTypes.func,
};

export default withStyles(styles)(TotalSection);
