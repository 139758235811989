import React from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid, TextField } from 'react-admin';

import LinkReportData from './LinkReportData';

const ReportList = props => {
  const {
    location: { search },
  } = props;
  return (
    <List {...props} bulkActionButtons={null} exporter={false} actions={null} sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid>
        <TextField label="Name" source="name" />
        <LinkReportData search={search} />
      </Datagrid>
    </List>
  );
};

ReportList.propTypes = {
  location: PropTypes.instanceOf(Object),
};

export default ReportList;
