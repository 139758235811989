import React from 'react';
import { List, Datagrid, TextField, DateField, NumberField, ReferenceField } from 'react-admin';

import Filter from './Filter';
import ListActionBar from '../common/ui/ListActionBar';
import EditButton from '../common/ui/EditButton';
import formats from '../../constants/formats';
import exporter from './exporter';
import { SUPPLIERS_ROUTE } from '../../constants/routes';

const IntakeDocketsList = props => (
  <List {...props} filters={<Filter />} bulkActionButtons={null} actions={<ListActionBar />} exporter={exporter}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <NumberField label="Intake Docket" source="id" textAlign="left" options={{ useGrouping: false }} />
      <ReferenceField
        label="Supplier"
        source="supplierId"
        reference="supplier"
        basePath={SUPPLIERS_ROUTE}
        linkType="show"
        allowEmpty
      >
        <TextField source="companyName" />
      </ReferenceField>
      <DateField label="Received Date" source="receivedDate" locales={formats.LOCALES} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default IntakeDocketsList;
