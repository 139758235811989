import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { addField, translate, ReferenceInputController, Labeled, TextField, LinearProgress } from 'react-admin';

const sanitizeRestProps = ({
  allowEmpty,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  locale,
  meta,
  onChange,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  referenceSource,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const ReferenceError = ({ label, error }) => <TextField error disabled label={label} value={error} margin="normal" />;

ReferenceError.propTypes = {
  error: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const ReferenceInputView = ({
  allowEmpty,
  basePath,
  children,
  choices,
  classes,
  className,
  error,
  input,
  isRequired,
  isLoading,
  label,
  meta,
  onChange,
  resource,
  setFilter,
  setPagination,
  setSort,
  source,
  translate,
  warning,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Labeled label={label} source={source} resource={resource} className={className} isRequired={isRequired}>
        <LinearProgress />
      </Labeled>
    );
  }

  if (error) {
    return <ReferenceError label={label} error={error} />;
  }
  // finding key of choices that should be display in SelectInput
  const optionText = item => {
    const optionKey = Object.keys(choices[0] || {}).filter(key => key !== 'id')[0] || 'name';
    return (item[optionKey] && item[optionKey].trim()) || '';
  };

  return React.cloneElement(children, {
    allowEmpty,
    classes,
    className,
    input,
    isRequired,
    optionText,
    label,
    resource,
    meta: {
      ...meta,
      helperText: warning || false,
    },
    source,
    choices,
    basePath,
    onChange,
    setFilter,
    setPagination,
    setSort,
    translateChoice: false,
    ...sanitizeRestProps(rest),
  });
};

ReferenceInputView.propTypes = {
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element,
  choices: PropTypes.array,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  resource: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  setPagination: PropTypes.func,
  setSort: PropTypes.func,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  warning: PropTypes.string,
};

const CriteriaReferenceInput = ({ children, ...props }) => {
  if (React.Children.count(children) !== 1) {
    throw new Error('<CriteriaReferenceInput> only accepts a single child');
  }

  return (
    <ReferenceInputController {...props}>
      {controllerProps => <ReferenceInputView {...props} {...{ children, ...controllerProps }} />}
    </ReferenceInputController>
  );
};

CriteriaReferenceInput.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  filter: PropTypes.instanceOf(Object),
  filterToQuery: PropTypes.func.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  meta: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  perPage: PropTypes.number,
  record: PropTypes.instanceOf(Object),
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['ASC', 'DESC']),
  }),
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

CriteriaReferenceInput.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: searchText => ({ q: searchText }),
  perPage: 25,
  sort: { field: 'id', order: 'DESC' },
};

export default compose(
  addField,
  translate,
)(CriteriaReferenceInput);
