import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import {
  registerResource as registerResourceAction,
  unregisterResource as unregisterResourceAction,
  translate,
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReportCriteriaFormInput from './ReportCriteriaFormInput';
import resources from '../../constants/resources';

const styles = ({ palette: { primary1Color } }) => ({
  card: {
    marginTop: 0,
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  body: { display: 'flex', alignItems: 'flex-end' },
  spacer: { width: 48 },
  icon: { color: primary1Color || '#00bcd4', paddingBottom: 0 },
  clearFix: { clear: 'right' },
});

class ReportCriteriaForm extends Component {
  constructor(props) {
    super(props);

    const { registerResource } = props;
    const resource = {
      name: resources.CRITERIA_CHOICES,
    };

    registerResource(resource);
  }

  componentWillUnmount() {
    const { unregisterResource } = this.props;
    unregisterResource(resources.CRITERIA_CHOICES);
  }

  render() {
    const { classes = {}, children, handleSubmit, isLoading } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Report Criteria</DialogTitle>
        <DialogContent>
          <Grid container spacing={24}>
            {children.map(filterElement => (
              <ReportCriteriaFormInput
                key={filterElement.props.source}
                filterElement={filterElement}
                classes={classes}
              />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" disabled={isLoading}>
            {isLoading ? <CircularProgress size={20} /> : 'Search'}
          </Button>
        </DialogActions>
      </form>
    );
  }
}

ReportCriteriaForm.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  registerResource: PropTypes.func,
  unregisterResource: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(
    null,
    {
      registerResource: registerResourceAction,
      unregisterResource: unregisterResourceAction,
    },
  ),
  translate,
  reduxForm({
    form: 'reportCriteriaForm',
    enableReinitialize: true,
  }),
)(ReportCriteriaForm);
