import React from 'react';
import { Content } from '../../../../common/ui/DOForm';
import Address from './Address';
import SaleDetails from './SaleDetails';
import PopupNotes from './PopupNotes';
import ContactDetails from './ContactDetails';
import { roles } from '../../../../../constants/roles';

const DeliveryDetails = props =>
  props.permissions === roles.CUSTOMER_ROLE ? (
    <Content {...props}>
      <Address {...props} />
      <PopupNotes {...props} />
    </Content>
  ) : (
    <Content {...props}>
      <Address {...props} />
      <SaleDetails {...props} />
      <ContactDetails {...props} />
    </Content>
  );

export default DeliveryDetails;
