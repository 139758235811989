import React from 'react';
import { List, Datagrid, TextField, NumberField, DateField, ReferenceField } from 'react-admin';

import Filter from './Filter';
import ListActionBar from '../common/ui/ListActionBar';
import EditButton from '../common/ui/EditButton';
import formats from '../../constants/formats';
import exporter from './exporter';
import numberDigits from '../../constants/numberFormat';

const InvoicesList = props => (
  <List {...props} filters={<Filter />} bulkActionButtons={null} actions={<ListActionBar />} exporter={exporter}>
    <Datagrid rowClick="edit">
      <NumberField label="ID" source="id" options={{ useGrouping: false }} />
      <NumberField label="Invoice Number" source="id" textAlign="left" options={{ useGrouping: false }} />
      <ReferenceField label="Customer" source="customerId" reference="customer" linkType="show" allowEmpty>
        <TextField source="companyName" />
      </ReferenceField>
      <NumberField label="Net Total" source="netTotal" textAlign="left" options={numberDigits} />
      <NumberField label="Printed" source="printed" textAlign="left" />
      <NumberField label="Posted" source="posted" textAlign="left" />
      <DateField label="Invoice Date" source="invoiceDate" locales={formats.LOCALES} />
      <EditButton />
    </Datagrid>
  </List>
);

export default InvoicesList;
