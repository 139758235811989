import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import resources from '../../constants/resources';
import formats from '../../constants/formats';

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'supplierId', resources.SUPPLIER).then(suppliers => {
    const data = records.map(({ id, supplierId, timeDate }) => ({
      ID: id,
      'Production Order': id,
      Supplier: (suppliers[supplierId] || {}).companyName,
      'Due Date': moment(timeDate).format(formats.DATE_TIME_EXPORT),
    }));
    const csv = convertToCSV({
      data,
    });
    downloadCSV(csv, `production_orders_${moment().format(formats.MOMENT)}`);
  });
};

export default exporter;
