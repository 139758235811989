import React from 'react';

export const EyeOnIcon = ({ className }) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        id="Vector"
        d="M8.51604 8.5161C8.30972 8.70835 8.14423 8.94019 8.02945 9.19779C7.91468 9.45539 7.85296 9.73347 7.84798 10.0154C7.84301 10.2974 7.89488 10.5775 8.0005 10.839C8.10611 11.1005 8.26332 11.338 8.46273 11.5374C8.66214 11.7368 8.89968 11.894 9.16117 11.9996C9.42265 12.1053 9.70273 12.1571 9.9847 12.1522C10.2667 12.1472 10.5447 12.0855 10.8023 11.9707C11.0599 11.8559 11.2918 11.6904 11.484 11.4841M9.11104 5.1561C9.40596 5.11935 9.70284 5.10065 10 5.1001C14.9 5.1001 17 10.0001 17 10.0001C16.6871 10.6701 16.2946 11.3 15.831 11.8761"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M6.227 6.227C4.83488 7.17524 3.72091 8.47768 3 10C3 10 5.1 14.9 10 14.9C11.3411 14.9036 12.6535 14.5116 13.773 13.773M3 3L17 17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
