import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ArrayInput,
  NumberField,
  ReferenceInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  Button,
  required,
  REDUX_FORM_NAME,
} from 'react-admin';
import * as moment from 'moment';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { getFormValues, clearFields as clearFieldsAction } from 'redux-form';
import { Link } from 'react-router-dom';

import AutocompleteInput from '../../common/ui/AutocompleteInputStyled';
import { ordersConstants } from '../../../constants/ordersConstants';
import resources from '../../../constants/resources';
import TableFormIterator from '../../common/ui/tableFormIterator';
import {
  getDiscountValue,
  getDiscountPercent,
  getTotalPriceExVat,
  getEstimatedTotalPriceExVat,
} from '../../../services/calcOrdersFunc';
import CurrencySign from '../../common/ui/CurrencySign';
import { PRODUCTS_ROUTE } from '../../../constants/routes';
import numberDigits from '../../../constants/numberFormat';
import validators from '../../../validators';

const MoneyTypography = ({ children }) => (
  <Fragment>
    {`${children} `}
    <CurrencySign />
  </Fragment>
);

MoneyTypography.propTypes = {
  children: PropTypes.string,
};

const styles = {
  linkButton: {
    '&:first-child': {
      paddingLeft: 0,
      textTransform: 'capitalize',
    },
  },
};

class CurrentOrderItems extends Component {
  shouldComponentUpdate(nextProps) {
    const { formData, vatCodes } = this.props;

    return (
      (formData || {}).purchaseOrderItems !== (nextProps.formData || {}).purchaseOrderItems ||
      vatCodes !== nextProps.vatCodes
    );
  }

  clearFields = (sources = []) => {
    const { changeValue, clearFields, isEdit } = this.props;

    if (isEdit) {
      sources.forEach(s => changeValue(s, null));
    } else {
      clearFields(sources);
    }
  };

  handleChangeProductId = (item, getSource) => (e, data) => {
    const { productItems, changeValue, vatCodes } = this.props;

    const { weightReceived, quantityReceived } = item;
    const { purchasesVatcodeId, description } = productItems[data];
    let { costPriceMethod, costPricePerUnit } = productItems[data];
    costPriceMethod = costPriceMethod || 0;
    costPricePerUnit = costPricePerUnit || 0;
    const vatCode = vatCodes[purchasesVatcodeId];

    let quantity = 0;
    switch (costPriceMethod) {
      case ordersConstants.quantityPriceMethod:
        this.clearFields([getSource('weightReceived')]);
        quantity = quantityReceived || 0;
        break;
      case ordersConstants.weightPriceMethod:
        this.clearFields([getSource('quantityReceived')]);
        quantity = weightReceived || 0;
        break;
      default:
        this.clearFields([getSource('quantityReceived'), getSource('weightReceived')]);
    }

    const totalPriceExVat = getTotalPriceExVat(costPriceMethod, costPricePerUnit, 0, quantity);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(getSource('priceMethod'), costPriceMethod);
    changeValue(getSource('productDescription'), description);
    changeValue(getSource('deliveryDate'), moment());
    changeValue(getSource('purchasesVatcodeId'), purchasesVatcodeId);
    changeValue(getSource('packTypeId'), productItems[data].metricId || 0);

    changeValue(getSource('pricePerUnit'), costPricePerUnit.toFixed(2));
    changeValue(getSource('disc'), (0).toFixed(2));
    changeValue(getSource('numberForFreezer'), costPricePerUnit.toFixed(2));
    changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
    changeValue(getSource('estimatedTotalPriceExVat'), estimatedTotalPriceExVat.toFixed(2));
  };

  handleChangeDiscountPercent = (item, getSource) => (e, newDisc = 0) => {
    const { productItems, changeValue, vatCodes } = this.props;
    const { priceMethod, pricePerUnit, weightReceived, quantityReceived, productId } = item;
    const { purchasesVatcodeId } = productItems[productId];
    const vatCode = vatCodes[purchasesVatcodeId];

    const totalPriceExVat = getTotalPriceExVat(priceMethod, pricePerUnit, newDisc, weightReceived || quantityReceived);
    const discountValue = getDiscountValue(pricePerUnit, newDisc);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(getSource('numberForFreezer'), discountValue.toFixed(2));
    changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
    changeValue(getSource('estimatedTotalPriceExVat'), estimatedTotalPriceExVat.toFixed(2));
  };

  handleChangeDiscountValue = (item, getSource) => (e, newDiscValue) => {
    const { productItems, changeValue, vatCodes } = this.props;
    const { priceMethod, pricePerUnit, weightReceived, quantityReceived, productId } = item;
    const { purchasesVatcodeId } = productItems[productId];
    const vatCode = vatCodes[purchasesVatcodeId];

    const newDiscPercent = getDiscountPercent(pricePerUnit, newDiscValue);
    const totalPriceExVat = getTotalPriceExVat(
      priceMethod,
      pricePerUnit,
      newDiscPercent,
      weightReceived || quantityReceived,
    );
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(getSource('disc'), newDiscPercent.toFixed(2));
    changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
    changeValue(getSource('estimatedTotalPriceExVat'), estimatedTotalPriceExVat.toFixed(2));
  };

  handleChangeQuantity = (item, getSource) => (e, newQuantity) => {
    const { productItems, changeValue, vatCodes } = this.props;
    const { priceMethod, pricePerUnit, disc, productId } = item;
    const { purchasesVatcodeId } = productItems[productId];
    const vatCode = vatCodes[purchasesVatcodeId];

    const totalPriceExVat = getTotalPriceExVat(priceMethod, pricePerUnit, disc, newQuantity);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    changeValue(getSource('totalPriceExVat'), totalPriceExVat.toFixed(2));
    changeValue(getSource('estimatedTotalPriceExVat'), estimatedTotalPriceExVat.toFixed(2));
  };

  render() {
    const {
      classes,
      formProps,
      formProps: { record = {} },
      addItemValidation,
    } = this.props;

    return (
      <ArrayInput source="purchaseOrderItems" label="">
        <TableFormIterator
          disableAdd={record.purchased}
          disableRemove={record.purchased}
          addItemValidation={addItemValidation}
        >
          <FormDataConsumer label="Product Code">
            {({ formData, scopedFormData, getSource }) => (
              <ReferenceInput
                label=""
                source={getSource('productId')}
                reference={resources.PRODUCT}
                {...formProps}
                onChange={this.handleChangeProductId(scopedFormData, getSource)}
                sort={{ field: 'productCode', order: 'ASC' }}
                validate={required()}
                allowEmpty
              >
                {formData.purchased ? (
                  <SelectInput optionText="productCode" disabled />
                ) : (
                  <AutocompleteInput optionText={item => (item.productCode && item.productCode.trim()) || ''} />
                )}
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Product">
            {({ scopedFormData }) =>
              scopedFormData.productId && (
                <Button
                  component={Link}
                  to={`${PRODUCTS_ROUTE}/${scopedFormData.productId}`}
                  label={scopedFormData.productDescription}
                  className={classes.linkButton}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer label="Quantity">
            {({ formData, scopedFormData, getSource }) => (
              <NumberInput
                label=""
                source={getSource('quantityReceived')}
                disabled={
                  !!formData.intakeDocketId ||
                  !scopedFormData.productId ||
                  scopedFormData.priceMethod === ordersConstants.weightPriceMethod ||
                  scopedFormData.priceMethod === ordersConstants.typicalPriceWeightMethod
                }
                onChange={this.handleChangeQuantity(scopedFormData, getSource)}
                validate={validators.INTEGER}
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Weight">
            {({ formData, scopedFormData, getSource }) => (
              <NumberInput
                label=""
                source={getSource('weightReceived')}
                disabled={
                  formData.intakeDocketId ||
                  !scopedFormData.productId ||
                  scopedFormData.priceMethod === ordersConstants.quantityPriceMethod
                }
                onChange={this.handleChangeQuantity(scopedFormData, getSource)}
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer label={<MoneyTypography>Base Price</MoneyTypography>}>
            {({ formData, getSource }) => (
              <NumberField source={getSource('pricePerUnit')} record={formData} options={numberDigits} />
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Units">
            {({ formData, getSource }) => (
              <ReferenceInput
                label=""
                source={getSource('packTypeId')}
                reference={resources.PRODUCT_PACK_TYPE}
                sort={{ field: 'description', order: 'ASC' }}
                allowEmpty
                {...formProps}
              >
                <SelectInput optionText="description" disabled={!!formData.intakeDocketId} />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <FormDataConsumer label="Disc %">
            {({ formData, scopedFormData, getSource }) => (
              <NumberInput
                label=""
                source={getSource('disc')}
                disabled={!!formData.intakeDocketId || !scopedFormData.productId}
                onChange={this.handleChangeDiscountPercent(scopedFormData, getSource, 'disc')}
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer label={<MoneyTypography>Disc. Price</MoneyTypography>}>
            {({ formData, scopedFormData, getSource }) => (
              <NumberInput
                label=""
                source={getSource('numberForFreezer')}
                disabled={!!formData.intakeDocketId || !scopedFormData.productId}
                onChange={this.handleChangeDiscountValue(scopedFormData, getSource, 'discPrice')}
              />
            )}
          </FormDataConsumer>

          <FormDataConsumer label={<MoneyTypography>Total Ex Vat</MoneyTypography>}>
            {({ formData, getSource }) => (
              <NumberField source={getSource('totalPriceExVat')} record={formData} options={numberDigits} />
            )}
          </FormDataConsumer>
          <FormDataConsumer label={<MoneyTypography>Estimated Total Ex</MoneyTypography>}>
            {({ formData, getSource }) => (
              <NumberField source={getSource('estimatedTotalPriceExVat')} record={formData} options={numberDigits} />
            )}
          </FormDataConsumer>
        </TableFormIterator>
      </ArrayInput>
    );
  }
}

CurrentOrderItems.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  productItems: PropTypes.objectOf(PropTypes.object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  formProps: PropTypes.instanceOf(Object),
  formData: PropTypes.instanceOf(Object),
  changeValue: PropTypes.func,
  clearFields: PropTypes.func,
  isEdit: PropTypes.bool,
  addItemValidation: PropTypes.func,
};

const mapStateToProps = state => ({
  formData: getFormValues('record-form')(state),
});

export default compose(
  connect(
    mapStateToProps,
    {
      clearFields: fields => clearFieldsAction(REDUX_FORM_NAME, false, false, ...fields),
    },
  ),
  withStyles(styles),
)(CurrentOrderItems);
