import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextField, Datagrid, List, SaveButton, showNotification, withDataProvider, UPDATE } from 'react-admin';
import { reduxForm, formPropTypes } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { isEqual, get } from 'lodash';

import useCustomFilter from '../useCustomFilter';
import Filter from '../components/Filter';
import resources from '../../../constants/resources';
import EditableNumberField from '../components/EditableNumberField';
import EditableSelectField from '../components/EditableSelectField';
import EnhanceReferenceInput from '../components/EnhanceReferenceInput';

const reactAdminProps = {
  hasCreate: false,
  hasList: true,
  hasShow: false,
  hasEdit: true,
  basePath: '/',
  bulkActionButtons: false,
  exporter: false,
};

const Button = ({ handleSubmit }) => <SaveButton type="submit" onClick={handleSubmit} />;
Button.propTypes = { handleSubmit: PropTypes.func.isRequired };

const keyForHolderValueForDispatch = 'storedBasePriceFilter';

const BasePriceFormTable = ({
  handleSubmit,
  location,
  match,
  dirty,
  blur,
  dispatch,
  change,
  productListParams,
  storedBasePriceFilter,
}) => {
  useCustomFilter({
    key: keyForHolderValueForDispatch,
    resource: resources.PRODUCT,
    dispatch,
    rafilterParams: productListParams,
    customRoutesFilterParams: storedBasePriceFilter,
  });

  return (
    <Fragment>
      <Prompt message="You should save all changes before you move to the next page. Move anyway?" when={dirty} />
      <form>
        <List
          sort={{ field: 'productCode', order: 'ASC' }}
          filters={<Filter searchLabel="Search" source="q" />}
          actions={<Button handleSubmit={handleSubmit} />}
          title="Current Active Prices"
          resource={resources.PRODUCT}
          location={location}
          match={match}
          {...reactAdminProps}
        >
          <Datagrid>
            <TextField source="productCode" label="Product Code" />
            <TextField source="description" />
            <EnhanceReferenceInput
              source="priceMethod"
              label="Price Method"
              reference={resources.PRICE_METHOD}
              sort={{ field: 'description', order: 'ASC' }}
            >
              <EditableSelectField />
            </EnhanceReferenceInput>
            <EditableNumberField label="Price" source="pricePerUnit" blur={blur} change={change} />
            <EditableNumberField label="Cost " source="costPricePerUnit" blur={blur} change={change} />
            <EnhanceReferenceInput
              source="costPriceMethod"
              label="Cost Method"
              reference={resources.PRICE_METHOD}
              sort={{ field: 'description', order: 'ASC' }}
            >
              <EditableSelectField />
            </EnhanceReferenceInput>
          </Datagrid>
        </List>
      </form>
    </Fragment>
  );
};

BasePriceFormTable.propTypes = {
  match: PropTypes.instanceOf(Object),
  location: PropTypes.objectOf(PropTypes.string),
  sliceOfRecords: PropTypes.arrayOf(PropTypes.object),
  ...formPropTypes,
};

const setValue = val => (isNaN(parseFloat(val)) ? '' : val.toFixed(2));

const setInitialValues = (data, ids) =>
  ids.reduce(
    (p, id) => ({
      ...p,
      [`price-${id}`]: {
        id,
        priceMethod: get(data, [id, 'priceMethod']),
        pricePerUnit: setValue(get(data, [id, 'pricePerUnit'])),
        costPricePerUnit: setValue(get(data, [id, 'costPricePerUnit'])),
        costPriceMethod: get(data, [id, 'costPriceMethod']),
      },
    }),
    {},
  );

const getItemId = item => `price-${item.id}`;

const onSubmit = (values, dispatch, { productsData: { ids, data }, initialValues, dataProvider }) => {
  const promises = ids
    .map(id => data[id])
    .reduce((p, item) => {
      const itemId = getItemId(item);
      const equal = isEqual(initialValues[itemId], values[itemId]);
      if (equal) return p;
      return [...p, dataProvider(UPDATE, resources.PRODUCT, { id: itemId, data: { ...item, ...values[itemId] } })];
    }, []);
  Promise.all(promises)
    .then(() => dispatch(showNotification('Record updated')))
    .catch(() => dispatch(showNotification('Some record may not updated, Please try again', 'warning')));
};

const mapStateToProps = state => {
  const {
    admin: {
      resources: {
        product: {
          data,
          list: { ids, params },
        },
      },
    },
    customReducers: {
      customRoutesFilter: { storedBasePriceFilter },
    },
  } = state;
  return {
    initialValues: setInitialValues(data, ids),
    productsData: { ids, data },
    productListParams: params,
    storedBasePriceFilter,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withDataProvider,
  reduxForm({
    form: 'base-prices',
    onSubmit,
    enableReinitialize: true,
  }),
);

export default enhance(BasePriceFormTable);
