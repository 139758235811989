import React from 'react';
import { List, Datagrid, TextField, ReferenceField, EditButton, Filter, TextInput } from 'react-admin';

import ListActionBar from '../common/ui/ListActionBar';
import exporter from './exporter';
import resources from '../../constants/resources';

const SupplierFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

const SupplierList = props => (
  <List
    {...props}
    bulkActionButtons={null}
    filters={<SupplierFilter />}
    actions={<ListActionBar />}
    exporter={exporter}
  >
    <Datagrid rowClick="edit">
      <TextField label="Supplier Code" source="supplierCode" />
      <TextField label="Name" source="companyName" />
      <TextField label="Herd" source="herd" />
      <ReferenceField label="Agent" source="agentId" reference={resources.AGENT} linkType={false} allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Haulier" source="haulierId" reference={resources.HAULIER} linkType={false} allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="VAT Number" source="vatNumber" />
      <TextField label="Address" source="address" />
      <EditButton />
    </Datagrid>
  </List>
);

export default SupplierList;
