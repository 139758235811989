import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setSidebarVisibility } from 'react-admin';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { getCustomerId, getDBId, getRole } from '../../provider/tokenUtils';
import { ToggleIcon } from './icons/Toggle';
import resources from '../../constants/resources';
import { PROFILE } from '../../constants/routes';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '64px 24px',
    fontFamily: 'Public Sans',
    height: '100vh',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    boxShadow: '0px -4px 21px rgba(0, 0, 0, 0.06)',
    overflowY: 'auto',
    position: 'fixed',
    width: 'inherit',

    '&.collapsed': {
      '& $toggleMenu': {
        marginRight: 'auto',
      },

      '& $userWrapper': {
        padding: '16px 0',
        backgroundColor: 'transparent',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 44,
      },
    },

    '& *': {
      boxSizing: 'border-box',
      flexShrink: 0,
    },
  },

  logo: {
    alignSelf: 'flex-start',
    marginBottom: 32,
    maxWidth: '100%',
    maxHeight: 54,
  },
  userWrapper: {
    backgroundColor: '#F6F7F9',
    borderRadius: 8,
    padding: 16,
    display: 'grid',
    gridTemplateColumns: '44px auto',
    columnGap: 16,
    minHeight: 76,
    marginBottom: 8,
    textDecoration: 'none',

    '&:hover': {
      backgroundColor: '#EAECF1',
    },
  },
  userLogo: {
    width: 44,
    height: 44,
    borderRadius: 22,
    backgroundColor: '#ECA33C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    gridRow: 'span 2',
    textTransform: 'uppercase',
  },
  userName: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: '#383E4A',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  userRole: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    color: '#818B9E',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },

  toggleMenu: {
    marginTop: 24,
    marginLeft: 'auto',
    color: '#D2DAE0',
    width: 32,
    height: 32,
    cursor: 'pointer',

    '&.rotate': {
      transform: 'rotate(180deg)',
    },
  },
});

const Component = ({ classes, children, logo, userName, setSidebarVisibility, open, isCustomer }) => (
  <div className={classNames(classes.root, { collapsed: !open })}>
    <img className={classes.logo} src={logo} alt="" />

    <Link className={classes.userWrapper} to={PROFILE}>
      <div className={classes.userLogo}>{userName.slice(0, 2)}</div>
      {open && (
        <>
          <div className={classes.userName}>{userName}</div>
          <div className={classes.userRole}>{getRole()}</div>
        </>
      )}
    </Link>

    {children}

    <ToggleIcon
      className={classNames(classes.toggleMenu, { rotate: !open })}
      onClick={() => setSidebarVisibility(!open)}
    />
  </div>
);

const mapStateToProps = state => {
  const customer = state.admin.resources[resources.CUSTOMER].data[getCustomerId()] || {};
  const suppliers = state.admin.resources[resources.SUPPLIER_SELECTION].data || {};
  const supplier = Object.values(suppliers).find(item => item.uuid === getDBId()) || {};

  return {
    logo: supplier.logoImage,
    userName: customer.contactName || customer.b2BEmail || localStorage.getItem('username') || '',
    open: state.admin.ui.sidebarOpen,
  };
};

export const NewDesignMenu = compose(
  connect(
    mapStateToProps,
    { setSidebarVisibility },
  ),
  withStyles(styles),
)(Component);
