import React from 'react';
import { Datagrid, TextField, DateField, NumberField } from 'react-admin';
import { Paper, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import formats from '../../constants/formats';
import numberDigits from '../../constants/numberFormat';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

const StocksRealtimeTable = ({ classes, ...rest }) => (
  <Paper className={classes.paper}>
    <Typography variant="headline" gutterBottom>
      Stocks Realtime
    </Typography>
    <Datagrid {...rest}>
      <TextField label="Product Code" source="productCode" />
      <TextField label="Product Name" source="name" />
      <NumberField source="quantity" textAlign="left" options={numberDigits} />
      <NumberField label="Batch" source="batchCode" textAlign="left" options={numberDigits} />
      <DateField label="Best Before Date" textAlign="right" source="bestBeforeDate" locales={formats.LOCALES} />
    </Datagrid>
  </Paper>
);

StocksRealtimeTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(StocksRealtimeTable);
