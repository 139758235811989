import React, { Fragment, useEffect } from 'react';
import {
  Show,
  Datagrid,
  TextField,
  NumberField,
  ReferenceManyField,
  ReferenceField,
  Pagination,
  fetchStart,
  fetchEnd,
  crudGetList,
  showNotification,
  SimpleShowLayout,
  UPDATE,
} from 'react-admin';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, propTypes } from 'redux-form';
import { isEqual } from 'lodash';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';

import numberDigits from '../../../constants/numberFormat';
import resources from '../../../constants/resources';
import EnhancedReferenceInput from '../components/EnhanceReferenceInput';
import EditableSelectField from '../components/EditableSelectField';
import EditableNumberField from '../components/EditableNumberField';
import CardActionEdit from './components/CardActionEdit';
import CheckboxField from './components/CheckboxField';
import SubReferenceField from '../../common/form/SubReferenceField';
import dataProvider from '../../../provider/dataProvider';
import { NAME_REQUIRED, MAX_CHARACTERS_50 } from '../../../constants/errorMessages';

const form = 'listPrices';

const styles = {
  container: {
    '& > div:first-child': {
      width: '100%',
    },
  },
};

const PriceListTable = ({
  basePath,
  hasList,
  hasCreate,
  hasEdit,
  hasShow,
  resource,
  initialValues,
  handleSubmit,
  dirty,
  dispatch,
  change,
  itemIdsForDelete,
  blur,
  classes,
  selectedId,
  reset,
  ...props
}) => {
  const actions = (
    <CardActionEdit
      handleSubmit={handleSubmit}
      selectedListId={selectedId}
      dispatch={dispatch}
      change={change}
      form={form}
      itemIdsForDelete={itemIdsForDelete}
      reset={reset}
    />
  );
  useEffect(() => {
    return reset;
  }, [selectedId]);
  return selectedId ? (
    <Fragment>
      <Prompt message="You should save all changes before you move to the next page. Move anyway?" when={dirty} />
      <form>
        <Show
          title=" "
          id={selectedId}
          basePath={basePath}
          hasList
          hasCreate={hasCreate}
          hasEdit={hasEdit}
          hasShow={hasShow}
          resource={resource}
          actions={actions}
        >
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <ReferenceManyField
              sort={{ field: 'product.productCode', order: 'ASC' }}
              label=""
              reference={resources.PRICE_LIST_ITEM}
              target="priceListId"
              pagination={<Pagination />}
              perPage={10}
              {...props}
              className={classes.container}
            >
              <Datagrid>
                <CheckboxField />
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Product Code"
                  allowEmpty
                  sortBy="product.productCode"
                >
                  <TextField source="productCode" />
                </ReferenceField>
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Description"
                  allowEmpty
                  sortBy="product.description"
                >
                  <TextField source="description" />
                </ReferenceField>
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Price Method"
                  sortBy="product.priceMethod"
                >
                  <SubReferenceField reference={resources.PRICE_METHOD} source="priceMethod">
                    <TextField source="description" />
                  </SubReferenceField>
                </ReferenceField>
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Price"
                  allowEmpty
                  sortBy="product.price"
                >
                  <NumberField source="pricePerUnit" options={numberDigits} />
                </ReferenceField>
                <EnhancedReferenceInput
                  reference={resources.PRICE_METHOD}
                  linkType={false}
                  source="priceMethod"
                  label="Price List Price Method"
                  allowEmpty
                >
                  <EditableSelectField />
                </EnhancedReferenceInput>
                <EnhancedReferenceInput
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="pricePerUnit"
                  label="Price List Price"
                  sortBy="pricePerUnit"
                >
                  <EditableNumberField blur={blur} change={change} />
                </EnhancedReferenceInput>
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </Show>
      </form>
    </Fragment>
  ) : (
    <div />
  );
};

PriceListTable.propTypes = {
  selectFromUrl: PropTypes.number,
  basePath: PropTypes.string.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  resource: PropTypes.string,
  itemIdsForDelete: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  pricesPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pricesPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...propTypes,
};

const getItemId = item => `price-${item.id}`;
const onSubmit = (values, dispatch, { sliceOfRecords, initialValues, selectedId, pricesPage, pricesPerPage }) => {
  const { description } = values;

  // api call for the price list description
  if (description !== initialValues.description) {
    dispatch(fetchStart());

    dataProvider(UPDATE, resources.PRICES, { id: selectedId, data: { description, id: selectedId } })
      .then(() => {
        dispatch(
          crudGetList(
            resources.PRICES,
            { page: pricesPage, perPage: pricesPerPage || 10 },
            { field: 'id', order: 'DESC' },
            {},
          ),
        );
      })
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  }
  const promises = sliceOfRecords.reduce((p, item) => {
    const itemId = getItemId(item);
    const equal = isEqual(initialValues[itemId], values[itemId]);
    if (equal) return p;
    return [
      ...p,
      dataProvider(UPDATE, resources.PRICE_LIST_ITEM, { id: itemId, data: { ...item, ...values[itemId] } }),
    ];
  }, []);
  Promise.all(promises)
    .then(() => {
      dispatch(showNotification('Record updated'));
      dispatch({ type: 'RA/REFRESH_VIEW' });
    })
    .catch(() => dispatch(showNotification('Some record may not updated, Please try again', 'warning')));
};

const setValue = val => (isNaN(parseFloat(val)) ? '' : val.toFixed(2));
const setInitialValues = priceListProducts =>
  Object.keys(priceListProducts)
    .map(product => priceListProducts[product].id)
    .reduce(
      (p, id) => ({
        ...p,
        [`shouldDelete-${id}`]: false,
        [`price-${id}`]: {
          id,
          priceMethod: priceListProducts[id].priceMethod,
          pricePerUnit: setValue(priceListProducts[id].pricePerUnit),
        },
      }),
      {},
    );

const fieldPrefix = 'shouldDelete-';
const getCheckedIds = formState =>
  Object.keys((formState || {}).values || {}).reduce(
    (p, key) => (key.startsWith(fieldPrefix) && formState.values[key] ? [...p, key.slice(fieldPrefix.length)] : p),
    [],
  );

const mapStateToProps = (
  {
    admin: {
      resources: {
        prices,
        priceListItem: { data: priceListData },
      },
    },
    form: { listPrices },
  },
  { selectedId },
) => {
  const description = get(prices, ['data', selectedId, 'description'], '');
  const pricesPage = prices.list.params.page;
  const pricesPerPage = prices.list.params.perPage;
  return {
    initialValues: {
      ...setInitialValues(priceListData),
      description,
    },
    sliceOfRecords: Object.keys(priceListData).map(id => priceListData[id]),
    itemIdsForDelete: getCheckedIds(listPrices),
    pricesPage,
    pricesPerPage,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
  reduxForm({
    form,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: ({ description }) => {
      const errors = {};
      if (!description) {
        errors.description = NAME_REQUIRED;
      } else if (description.length > 50) {
        errors.description = MAX_CHARACTERS_50;
      }

      return errors;
    },
  }),
);

export default enhance(PriceListTable);
