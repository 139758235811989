import React, { useEffect } from 'react';
import { setSidebarVisibility } from 'ra-core';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withShowSidebar } from '../hoc/WithShowSidebar';
import classnames from 'classnames';

const getWidth = columns => {
  const GAP = '24px';

  const noGapsWidth = `(100vw - 13 * ${GAP})`;
  const oneColumnWidth = `((${noGapsWidth} / 12) + ${GAP})`;

  return `calc(${oneColumnWidth} * ${columns})`;
};

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'transparent',
    height: '100%',
    marginTop: 0,
    border: 'none',
    width: getWidth(2),

    '@media (max-width: 1599px)': {
      width: getWidth(3),
    },

    '&.short': {
      width: 104,
    },
  },
});

export const Component = ({ showSidebar, classes, setSidebarVisibility, children, open }) => {
  const handleClose = () => setSidebarVisibility(false);

  const toggleSidebar = () => setSidebarVisibility(!open);

  useEffect(() => {
    setSidebarVisibility(true);
  }, []);

  if (!showSidebar) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        className: classnames(classes.drawerPaper, { short: !open }),
      }}
      onClose={toggleSidebar}
    >
      {React.cloneElement(children, {
        dense: true,
        onMenuClick: handleClose,
      })}
    </Drawer>
  );
};

export const CustomSidebar = compose(
  connect(
    state => ({ open: state.admin.ui.sidebarOpen }),
    { setSidebarVisibility },
  ),
  withShowSidebar,
  withStyles(styles),
)(Component);
