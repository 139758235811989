import * as moment from 'moment';
import { getDiscountPercent } from '../../../../services/calcOrdersFunc';
import { roles } from '../../../../constants/roles';

export const calculateDiscountPercent = ({ record, isEdit, permissions }) => {
  const saleOrderItems = (record.saleOrderItems || []).map(item => {
    const { pricePerUnit, discPricePerUnit } = item;
    const discount = getDiscountPercent(pricePerUnit, discPricePerUnit);

    return {
      ...item,
      disc: (discount || 0).toFixed(2),
      discPricePerUnit: discPricePerUnit ? (+discPricePerUnit).toFixed(2) : discPricePerUnit,
    };
  });

  const isCustomer = permissions === roles.CUSTOMER_ROLE;
  const minDeliveryDay = isCustomer ? moment().add(1, 'days') : moment();
  return {
    transformedInitialValues: {
      ...record,
      saleOrderItems,
      deliveryDate: isEdit ? record.deliveryDate : minDeliveryDay,
      timeDate: isEdit ? record.timeDate : moment(),
    },
  };
};
