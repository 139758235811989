import React from 'react';

export const CopyIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M12.9166 1.6665H7.16659C6.83325 1.6665 6.49992 1.83317 6.24992 2.08317C5.99992 2.33317 5.83325 2.6665 5.83325 2.99984V13.6665C5.83325 13.9998 5.99992 14.3332 6.24992 14.5832C6.49992 14.8332 6.83325 14.9998 7.16659 14.9998H15.3333C15.6666 14.9998 15.9999 14.8332 16.2499 14.5832C16.4999 14.3332 16.6666 13.9998 16.6666 13.6665V5.4165L12.9166 1.6665Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_2"
      d="M2.5 6.33317V16.9998C2.5 17.3332 2.66667 17.6665 2.91667 17.9165C3.16667 18.1665 3.5 18.3332 3.83333 18.3332H12M12.5 1.6665V5.83317H16.6667"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
