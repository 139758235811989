import React from 'react';
import { Edit, SaveButton, ReferenceInput, Toolbar, SelectInput, DisabledInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import moment from 'moment';

import { DOForm, Content, FormTab } from '../common/ui/DOForm';
import { convertUtcToLocal } from '../../services/date';
import resources from '../../constants/resources';
import FormWrapper from '../common/ui/FormWrapper';
import IntakeDocketItemTabs from './IntakeDocketItemTabs';
import formats from '../../constants/formats';

const minimumIntegerDigits = (value = 0) =>
  new Intl.NumberFormat(formats.LOCALES, { minimumIntegerDigits: 6 }).format(value).replace(',', '');

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter />
  </Toolbar>
);

export default props => (
  <FormWrapper>
    <Edit {...props}>
      <DOForm toolbar={<EditToolbar />}>
        <Content position="main">
          <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Supplier Code">
            <SelectInput disabled optionText="supplierCode" />
          </ReferenceInput>
          <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Supplier Name">
            <SelectInput disabled optionText="companyName" />
          </ReferenceInput>
          <DisabledInput source="purchaseOrderId" label="PO Number" />
        </Content>

        <Content position="side">
          <FormTab label="Details" tabIndex={-1}>
            <ReferenceInput source="purchaseOrderId" reference={resources.PURCHASE_ORDER} label="Order No">
              <SelectInput disabled optionText="id" />
            </ReferenceInput>
            <ReferenceInput source="purchaseOrderId" reference={resources.PURCHASE_ORDER} label="Order Date">
              <SelectInput
                disabled
                optionText={item => item.timeDate && moment(item.timeDate).format(formats.MOMENT)}
              />
            </ReferenceInput>

            <ReferenceInput source="purchaseOrderId" reference={resources.PURCHASE_ORDER} label="Purchaser">
              <SelectInput disabled optionText="user.fullName" />
            </ReferenceInput>
            <ReferenceInput source="purchaseOrderId" reference={resources.PURCHASE_ORDER} label="Currency">
              <SelectInput disabled optionText="currency.description" />
            </ReferenceInput>
            <DisabledInput source="id" label="Intake No" format={minimumIntegerDigits} />
            <DateInput
              source="receivedDate"
              label="Intake Date"
              format={convertUtcToLocal}
              options={{ format: formats.DATE, disabled: true }}
            />
            <ReferenceInput source="userId" reference={resources.USER} label="Intake User">
              <SelectInput disabled optionText="fullName" />
            </ReferenceInput>
          </FormTab>

          <FormTab label="Notes" tabIndex={-1}>
            <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Notes" xs={12}>
              <SelectInput disabled optionText="notes" multiline />
            </ReferenceInput>
          </FormTab>

          <FormTab label="Intake Notes" tabIndex={-1}>
            <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Contact">
              <SelectInput disabled optionText="contactName" />
            </ReferenceInput>
            <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Phone">
              <SelectInput disabled optionText="phone" />
            </ReferenceInput>
            <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Phone Other">
              <SelectInput disabled optionText="phoneOther" />
            </ReferenceInput>
            <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Fax">
              <SelectInput disabled optionText="fax" />
            </ReferenceInput>
            <ReferenceInput source="supplierId" reference={resources.SUPPLIER} label="Email">
              <SelectInput disabled optionText="email" />
            </ReferenceInput>

            {/* customer comment: for  IntakeDocket I didn't find a match against suppliers, so just ignore it at the moment. */}
            <DisabledInput source="accountContact" label="Account Contact" />
          </FormTab>
        </Content>

        <Content position="bottom">
          <IntakeDocketItemTabs />
        </Content>
      </DOForm>
    </Edit>
  </FormWrapper>
);
