export default {
  PRODUCT: 'product',
  SUPPLIER: 'supplier',
  CUSTOMER: 'customer',
  PURCHASE_ORDER: 'purchaseOrder',
  PRODUCTION_ORDER: 'productionOrder',
  PRODUCTION_TEMPLATE: 'productionTemplate',
  INTAKE_DOCKET: 'IntakeDocket',
  SALE_ORDER: 'SaleOrder',
  DISPATCH_DOCKET: 'DispatchDocket',
  INVOICE: 'invoice',
  CREDIT_NOTE: 'creditNote',
  CREDIT_NOTE_ITEM: 'creditNoteItem',
  VAN_SALES: 'users',
  USER_COORDENATES: 'userCoordenates',
  REPORT_DATA: 'reportData',
  REPORT: 'report',
  SUPPLIER_GROUP: 'supplierGroup',
  AGENT: 'agent',
  CURRENCY: 'currency',
  CUSTOMER_GROUP: 'customerGroup',
  COUNTRY: 'country',
  PRODUCT_GROUP: 'productGroup',
  PRODUCT_PACK_TYPE: 'productPackType',
  PRODUCT_STATE: 'productState',
  ROUTE: 'route',
  VATCODE: 'vatCode',
  WEIGHT_BAND: 'weightBand',
  DEPARTMENT: 'department',
  GRADE_GROUP: 'gradeGroup',
  PRICES: 'prices',
  PRICE_LIST_ITEM: 'priceListItem',
  ADDRESSES: 'addresses',
  HAULIER: 'haulier',
  MENU_NOTIFICATION: 'menuNotification',
  PURCHASE_ORDER_ITEM: 'purchaseOrderItem',
  PRODUCTION_ORDER_ITEM: 'productionOrderItem',
  PRODUCTION_TEMPLATE_ITEM: 'productionTemplateItem',
  SALES_ORDER_ITEM: 'saleOrderItem',
  INTAKE_DOCKET_ITEM: 'intakeDocketItem',
  DISPATCH_ITEM: 'dispatchItem',
  BEST_PRODUCT_SALES_STATISTICS: 'bestProductSalesStatistics',
  SALES_PURCHASE_STATISTICS: 'salesPurchaseStatistics',
  STOCKS_REALTIME_STATISTICS: 'stocksRealtimeStatistics',
  SPECIAL_OFFERS_STATISTICS: 'specialOffersStatistics',
  DASHBOARD_STATISTICS: 'dashboardStatistics',
  PRODUCT_SPECIFICATION_IMAGE: 'productSpecificationImage',
  CUTTING_SPECIFICATION: 'cuttingSpecification',
  PACKING_SPECIFICATION: 'packingSpecification',
  PRODUCT_SPECIFICATION: 'productSpecification',
  USER: 'user',
  REPORT_CRITERIA: 'reportCriteria',
  CRITERIA_CHOICES: 'criteriaChoices',
  RETURN_ITEM: 'returnItem',
  SALE_ORDER_ITEM: 'saleOrderItem',
  SPIECES: 'spiecesType',
  BOX: 'box',
  PRICE_METHOD: 'priceMethod',
  OTHER_METHOD: 'orderMethod',
  TARE_METHOD: 'tareMethod',
  DATE_FORWARD_METHOD: 'dateForwardMethod',
  STOCK_TYPE: 'stockType',
  DISPATCH_METHOD: 'dispatchMethod',
  BARCODE_TYPE: 'barcodeType',
  HEAD_ON_OPTION: 'HeadOnOption',
  INNER_LABEL_METHOD: 'innerLabelMethod',
  PCL_MODE: 'plcMode',
  ALLERGEN: 'allergen',
  WARNING: 'warning',
  BATCH: 'batch',
  PRODUCT_PRICE: 'productPrice',
  B2B_CUSTOMER_CATALOG: 'b2bCustomerCatalog',
  CHANGE_PASSWORD: 'changePassword',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  SALE_ORDER_REPORT: 'saleOrderReport',
  SEND_SALE_ORDER_REPORTS: 'sendSaleOrderReports',
  DISPATCH_DOCKET_REPORT: 'dispatchDocketReport',
  SEND_DISPATCH_DOCKET_REPORTS: 'sendDispatchDocketReports',
  SUPPLIER_SELECTION: 'AuthenticationSupplier',
};
