import React from 'react';

export const DashboardIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 3H10V12H3V3ZM14 3H21V8H14V3ZM14 12H21V21H14V12ZM3 16H10V21H3V16Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
