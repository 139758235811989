import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  DisabledInput,
  ReferenceInput,
  Edit,
  Create,
  required,
  maxLength,
} from 'react-admin';
import resources from '../../constants/resources';
import CustomerSpecifications from './CustomerSpecifications';

import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import FormWrapper from '../common/ui/FormWrapper';
import EditToolbar from '../common/ui/EditToolbar';
import validators from '../../validators/index';

const Form = props => {
  const {
    record: { productSpecifications },
  } = props;
  return (
    <DOForm {...props}>
      <Content position="main">
        <TextInput source="productCode" label="Product Code" validate={[required(), maxLength(30)]} />
        <TextInput source="description" validate={[required(), maxLength(70)]} />
        <ReferenceInput
          source="groupId"
          label="Product Group"
          reference={resources.PRODUCT_GROUP}
          sort={{ field: 'groupDescription', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="groupDescription" />
        </ReferenceInput>
        <ReferenceInput
          source="departmentId"
          label="Department"
          reference={resources.DEPARTMENT}
          sort={{ field: 'description', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
        <NumberInput source="typicalPieces" label="Typical Pieces" validate={validators.INTEGER} />
        <ReferenceInput
          source="metricId"
          label="Product Pack Type"
          reference={resources.PRODUCT_PACK_TYPE}
          sort={{ field: 'description', order: 'ASC' }}
          validate={required()}
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
        <TextInput label="Other" source="other" validate={maxLength(50)} />
      </Content>
      <Content position="bottom">
        <FormTab label="Pricing">
          <NumberInput source="pricePerUnit" label="Sale Price Per Unit" validate={[...validators.MONEY, required()]} />
          <ReferenceInput
            source="priceMethod"
            label="Sales Price Method"
            reference={resources.PRICE_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput source="margin" validate={[...validators.MONEY, required()]} />
          <NumberInput
            source="stockPricePerUnit"
            label="Stock Price Per Unit"
            validate={[...validators.MONEY, required()]}
          />
          <ReferenceInput
            source="stockPriceMethod"
            label="Stock Price Method"
            reference={resources.PRICE_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput
            source="costPricePerUnit"
            label="Cost Price Per Unit"
            validate={[...validators.MONEY, required()]}
          />
          <ReferenceInput
            source="costPriceMethod"
            label="Cost Price Method"
            reference={resources.PRICE_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput
            source="killPricePerUnit"
            label="Kill Price Per Unit"
            validate={[...validators.MONEY, required()]}
          />
          <ReferenceInput
            source="orderMethod"
            label="Order Method"
            reference={resources.OTHER_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="vatcodeId"
            label="Vat Code"
            reference={resources.VATCODE}
            validate={required()}
            sort={{ field: 'description', order: 'ASC' }}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="purchasesVatcodeId"
            label="Purchase Vat Code"
            reference={resources.VATCODE}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
        </FormTab>

        <FormTab label="Weights">
          <ReferenceInput
            source="weightBandId"
            label="Weight Band"
            reference={resources.WEIGHT_BAND}
            sort={{ field: 'name', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput
            source="typicalWeight"
            label="Typical/Target Weight"
            validate={[...validators.MONEY, required()]}
          />
          <NumberInput
            source="preProductionTargetWeight"
            label="PreProduction Target Weight"
            validate={[...validators.MONEY, required()]}
          />
          <NumberInput source="minimumWeight" label="Min Weight" validate={[...validators.MONEY, required()]} />
          <NumberInput source="maximumWeight" label="Max Weight" validate={[...validators.MONEY, required()]} />
          <NumberInput source="tarePerUnit" label="Tare Per Unit" validate={[...validators.MONEY, required()]} />
          <ReferenceInput
            source="tareMethod"
            label="Tare Method"
            reference={resources.TARE_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <DisabledInput source="tneweight" label="TNE Weight" />
          <DisabledInput source="t1weight" label="T1 Weight" />
          <DisabledInput source="t2weight" label="T2 Weight" />
        </FormTab>

        <FormTab label="Dates">
          <NumberInput
            source="bestBeforeDays"
            label="Best Before Days"
            validate={[...validators.INTEGER, required()]}
          />
          <ReferenceInput
            source="bestBeforeMethod"
            label="Best Before Method"
            reference={resources.DATE_FORWARD_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput source="sellByDays" label="Sell By Days" validate={[...validators.INTEGER, required()]} />
          <ReferenceInput
            source="sellByMethod"
            label="Sell By Method"
            reference={resources.DATE_FORWARD_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput source="killDaysBack" label="Kill Days Back" validate={[...validators.INTEGER, required()]} />
          <NumberInput source="shelfLifeDays" label="Shelf Life Days" validate={validators.INTEGER} />
        </FormTab>

        <FormTab label="Label">
          <TextInput source="labelDescription" label="Description On Label" validate={maxLength(100)} />
          <TextInput source="barcode" label="Unit Barcode" validate={maxLength(30)} />
          <TextInput source="outterBarcode" label="Outter Barcode" validate={maxLength(30)} />
          <TextInput source="labelField1" label="Label Field 1" validate={maxLength(254)} />
          <TextInput source="labelField2" label="Label Field 2" validate={maxLength(254)} />
          <TextInput source="labelField3" label="Label Field 3" validate={maxLength(254)} />
          <TextInput source="labelField4" label="Label Field 4" validate={maxLength(254)} />
          <TextInput source="labelField5" label="Label Field 5" validate={maxLength(254)} />
        </FormTab>

        <FormTab label="Label Extra">
          <TextInput source="labelField6" label="Label Field 6" validate={maxLength(254)} />
          <TextInput source="labelField7" label="Label Field 7" validate={maxLength(254)} />
          <TextInput source="labelField8" label="Label Field 8" validate={maxLength(254)} />
          <TextInput source="labelField9" label="Label Field 9" validate={maxLength(254)} />
          <TextInput source="labelField10" label="Label Field 10" validate={maxLength(254)} />
          <BooleanInput source="isAllergen" label="Allergen" />
          <ReferenceInput
            source="allergenId"
            label="Allergen Info"
            reference={resources.ALLERGEN}
            sort={{ field: 'code', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="code" />
          </ReferenceInput>
          <ReferenceInput
            source="warningId"
            label="Warning Info"
            reference={resources.WARNING}
            sort={{ field: 'code', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="code" />
          </ReferenceInput>
        </FormTab>

        <FormTab label="Stock">
          <ReferenceInput
            source="stockType"
            label="Stock Type"
            reference={resources.STOCK_TYPE}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="dispatchMethod"
            label="Dispatch Method"
            reference={resources.DISPATCH_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
            allowEmpty
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput source="weightStockLevel" label="Weight Stock Level" validate={validators.MONEY} />
          <NumberInput source="quantityStockLevel" label="Quantity Stock Level" validate={validators.MONEY} />
          <NumberInput source="weightAllocated" label="Weight Allocated" validate={validators.MONEY} />
          <NumberInput source="quantityAllocated" label="Quantity Allocated" validate={validators.MONEY} />
          <DisabledInput source="weightOrdered" label="Weight Ordered" />
          <DisabledInput source="quantityOrdered" label="Quantity Ordered" />
          <NumberInput source="itemsPerBox" label="Items Per Box" validate={[...validators.INTEGER, required()]} />
          <NumberInput
            source="boxesPerPallet"
            label="Boxes Per Pallet"
            validate={[...validators.INTEGER, required()]}
          />
        </FormTab>

        <FormTab label="Accounts">
          <TextInput source="nominalCode" label="Sales Nominal Code" validate={maxLength(20)} />
          <TextInput source="nominalDeptCode" label="Sales Nominal Dept Code" validate={maxLength(10)} />
          <TextInput source="purchaseNominalCode" label="Purchase Nominal Code" validate={maxLength(20)} />
          <TextInput source="purchaseNominalDeptCode" label="Purchase Nominal Dept Code" validate={maxLength(10)} />
        </FormTab>

        <FormTab label="Type">
          <BooleanInput source="isContainer" label="Is Container" />
          <BooleanInput source="isChargeableContainer" label="Chargeable Container" />
          <BooleanInput source="intake" label="Intake Product" />
          <BooleanInput source="production" label="Production Product" />
          <BooleanInput source="dispatch" label="Dispatch Product" />
          <BooleanInput source="isMinceProduct" label="Is Mince" />
          <BooleanInput source="webProduct" label="Web Product" />
          <BooleanInput source="disableLabelPrint" label="Disable Label Print" />
          <BooleanInput source="manualBatchRequired" label="Manual Batch Required" />
          <NumberInput source="productionWeightLoss" label="Production WeightLoss %" validate={validators.MONEY} />
          <NumberInput source="productionWeightGain" label="Production WeightGain %" validate={validators.MONEY} />
        </FormTab>

        <FormTab label="Other">
          <ReferenceInput
            source="barcodeType"
            label="Barcode Type"
            reference={resources.BARCODE_TYPE}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            label="Spieces"
            source="spiecesId"
            reference={resources.SPIECES}
            sort={{ field: 'description', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="description" optionValue="id" />
          </ReferenceInput>
          <ReferenceInput
            source="productStateId"
            label="Product State"
            reference={resources.PRODUCT_STATE}
            sort={{ field: 'description', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="gradeGroupId"
            label="Grade Group"
            reference={resources.GRADE_GROUP}
            sort={{ field: 'description', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="headOnStatusId"
            label="Default Head On Status"
            reference={resources.HEAD_ON_OPTION}
            sort={{ field: 'description', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="innerLabelMethod"
            label="Inner Label Method"
            reference={resources.INNER_LABEL_METHOD}
            sort={{ field: 'description', order: 'ASC' }}
            validate={required()}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <NumberInput source="orderindex" label="Order Index" validate={validators.INTEGER} />
          <NumberInput
            source="productLengthMilliSeconds"
            label="PLC Product Length(MS)"
            validate={[...validators.INTEGER, required()]}
          />
          <ReferenceInput
            source="plcmode"
            label="PLC Mode"
            reference={resources.PCL_MODE}
            sort={{ field: 'description', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            label="Box ID"
            source="boxId"
            reference={resources.BOX}
            sort={{ field: 'name', order: 'ASC' }}
            allowEmpty
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="productIndex" label="Product Index" validate={validators.INTEGER} />
        </FormTab>

        <FormTab label="Quality">
          {/* customer comment: ignore it at the moment */}
          <SelectInput source="qcquestion1" label="QCQuestion1" allowEmpty choices={[]} />
          <SelectInput source="qcquestion2" label="QCQuestion2" allowEmpty choices={[]} />
          <SelectInput source="qcquestion3" label="QCQuestion3" allowEmpty choices={[]} />
          <SelectInput source="qcquestion4" label="QCQuestion4" allowEmpty choices={[]} />
          <SelectInput source="qcquestion5" label="QCQuestion5" allowEmpty choices={[]} />
          <SelectInput source="qcquestion6" label="QCQuestion6" allowEmpty choices={[]} />
          <SelectInput source="qcquestion7" label="QCQuestion7" allowEmpty choices={[]} />
          <SelectInput source="qcquestion8" label="QCQuestion8" allowEmpty choices={[]} />
        </FormTab>

        <FormTab label="Filling/Check/Weight">
          <NumberInput source="vesselWeight" label="Vessel Weight" validate={validators.MONEY} />
          <NumberInput source="batchingWeight" label="Batching Weight" validate={validators.MONEY} />
          <NumberInput source="plusTolerance" label="Plus Tolerance" validate={validators.MONEY} />
          <NumberInput source="minusTolerance" label="Minus Tolerance" validate={validators.MONEY} />
          <NumberInput source="sampleCount" label="Sample Count" validate={validators.INTEGER} />
          <NumberInput source="samplePeriodSeconds" label="Sample Period (Sec)" validate={validators.INTEGER} />
        </FormTab>
        <FormTab label="Customer Specifications">
          {!!(productSpecifications || []).length && <CustomerSpecifications {...props} />}
        </FormTab>

        <FormTab label="B2B">
          <TextInput source="b2BNotes" label="B2B Notes" multiline rows={5} />
        </FormTab>
      </Content>
    </DOForm>
  );
};

Form.propTypes = {
  record: PropTypes.instanceOf(Object),
};

export const ProductEdit = props => (
  <FormWrapper>
    <Edit {...props}>
      <Form toolbar={<EditToolbar />} />
    </Edit>
  </FormWrapper>
);

export const ProductCreate = props => (
  <FormWrapper>
    <Create {...props}>
      <Form redirect="list" />
    </Create>
  </FormWrapper>
);
