import React, { useState } from 'react';
import { instanceOf, func, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { ContentCopy } from '@material-ui/icons';
import { sendReports as sendReportsAction } from '../services/sendReports';
import toolbar from '../../salesOrders/form/styles/toolbar';

const mapDispatchToProps = {
  showNotificationAction: showNotification,
};

const SendReportsComponent = ({ classes, url, selectedIds, showNotificationAction }) => {
  const [loading, setLoading] = useState(false);
  const onClick = () => sendReportsAction(selectedIds, url, showNotificationAction, setLoading);
  return (
    <Button disabled={loading} className={classes.button} variant="raised" color="secondary" onClick={onClick}>
      {loading ? (
        <CircularProgress size={25} thickness={5} />
      ) : (
        <>
          <ContentCopy className={classes.icon} />
          <span>Send Reports</span>
        </>
      )}
    </Button>
  );
};

export const SendReports = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(toolbar),
)(SendReportsComponent);

SendReportsComponent.propTypes = {
  url: string.isRequired,
  classes: instanceOf(Object).isRequired,
  selectedIds: instanceOf(Array).isRequired,
  showNotificationAction: func.isRequired,
};
