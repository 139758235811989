import React from 'react';

export const EyeOffIcon = ({ className }) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M2.71387 10C2.71387 10 4.85672 5 9.85672 5C14.8567 5 16.9996 10 16.9996 10C16.9996 10 14.8567 15 9.85672 15C4.85672 15 2.71387 10 2.71387 10Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85672 12.1426C11.0402 12.1426 11.9996 11.1833 11.9996 9.99979C11.9996 8.81632 11.0402 7.85693 9.85672 7.85693C8.67326 7.85693 7.71387 8.81632 7.71387 9.99979C7.71387 11.1833 8.67326 12.1426 9.85672 12.1426Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
