import React from 'react';
import { NumberInput } from 'react-admin';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { UiBaseInput } from './BaseInput';

const styles = () => ({
  root: {
    /* remove number input controls */

    '& input': {
      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  },
});

export const UiNumberInput = withStyles(styles)(({ classes, className, ...props }) => (
  <UiBaseInput component={NumberInput} className={classnames(className, classes.root)} {...props} />
));
