import React from 'react';
import * as PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import { ListController, Title, WithPermissions } from 'react-admin';
import { Redirect } from 'react-router-dom';

import resources from '../../constants/resources';
import dashboard from '../../constants/dashboard';
import { roles } from '../../constants/roles';
import ComparableCard from './ComparableCard';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
import StocksRealtimeTable from './StocksRealtimeTable';

import DefaultTitle from '../common/ui/DefaultTitle';
import historyShape from '../common/ui/historyShape';
import { SALES_ORDER_ROUTE } from '../../constants/routes';

const styles = {
  root: { flexGrow: 1 },
};

const Dashboard = ({ classes, match: { url }, history: { push }, permissions, ...rest }) => {
  if (!permissions) {
    return null;
  }
  if (permissions === roles.CUSTOMER_ROLE || permissions === roles.STAFF_USER_ROLE) {
    return <Redirect to={SALES_ORDER_ROUTE} />;
  }

  return (
    <div className={classes.root}>
      <Title title={<DefaultTitle />} />
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <ListController resource={resources.DASHBOARD_STATISTICS} basePath={url} {...rest}>
            {({ data }) => (
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  {!!data[dashboard.PURCHASE_ORDERS] && !!data[dashboard.GOOD_RECEIPTS] && (
                    <ComparableCard
                      leftValue={data[dashboard.PURCHASE_ORDERS].value}
                      rightValue={data[dashboard.GOOD_RECEIPTS].value}
                      leftText="Purchase orders"
                      rightText="Good receipts"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!!data[dashboard.SALE_ORDERS] && !!data[dashboard.DELIVERY_DOCKETS] && (
                    <ComparableCard
                      leftValue={data[dashboard.SALE_ORDERS].value}
                      rightValue={data[dashboard.DELIVERY_DOCKETS].value}
                      leftText="Sales orders"
                      rightText="Delivery dockets"
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </ListController>
        </Grid>
        <Grid item xs={6}>
          <ListController resource={resources.BEST_PRODUCT_SALES_STATISTICS} basePath={url} {...rest}>
            {({ data, ids }) => <DoughnutChart data={data} ids={ids} />}
          </ListController>
        </Grid>
        <Grid item xs={6}>
          <ListController resource={resources.SALES_PURCHASE_STATISTICS} basePath={url} {...rest}>
            {({ data, ids }) => <BarChart data={data} ids={ids} />}
          </ListController>
        </Grid>
        <Grid item xs={12}>
          <ListController resource={resources.STOCKS_REALTIME_STATISTICS} basePath={url} {...rest}>
            {props => <StocksRealtimeTable {...props} />}
          </ListController>
        </Grid>
      </Grid>
    </div>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  permissions: PropTypes.string,
  history: historyShape,
};

const DashboardWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <Dashboard permissions={permissions} {...props} />} />
);

export default withStyles(styles)(DashboardWithPermissions);
