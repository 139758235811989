import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import resources from '../../constants/resources';
import formats from '../../constants/formats';

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'customerId', resources.CUSTOMER).then(customers => {
    const data = records.map(({ id, customerId, netTotal, printed, posted, invoiceDate }) => ({
      ID: id,
      'Invoice Number': id,
      Customer: ((customers[customerId] || {}).companyName || '').trim(),
      'Net Total': netTotal,
      Printed: printed,
      Posted: posted,
      'Invoice Date': moment(invoiceDate).format(formats.DATE_TIME_EXPORT),
    }));
    const csv = convertToCSV({
      data,
    });
    downloadCSV(csv, `invoices_${moment().format(formats.MOMENT)}`);
  });
};

export default exporter;
