import { create } from 'zustand';
import { getCustomerId, getHeaders } from '../provider/tokenUtils';
import { httpGet, httpPost } from '../api/http';
import { HubConnectionBuilder } from '@microsoft/signalr';

export const useNotificationsStore = create((set, get) => ({
  notifications: [],
  visibleNotifications: false,
  hasUnread: false,
  isLoading: true,
  connect: undefined,

  getNotifications: async () => {
    const { canReceiveNotification } = await httpGet('token');

    if (canReceiveNotification) {
      set(state => ({ ...state, visibleNotifications: true }));

      httpGet('Notifications', { customerId: getCustomerId() }).then(data => {
        set(state => ({
          ...state,
          notifications: data.notificationDtoList,
          hasUnread: !!data.unviewedNotificationsCount,
          isLoading: false,
        }));
      });

      get().connectSocket();
    }
  },
  addNotification: data => set(state => ({ ...state, notifications: [data, ...state.notifications], hasUnread: true })),
  markAsRead: () => {
    const ids = get()
      .notifications.filter(item => item.seen === null)
      .map(item => item.id);

    if (ids.length) {
      httpPost('Notifications/MarkAsRead', {
        notificationIdList: ids,
      }).then(() => {
        set(state => {
          const newNotifications = state.notifications.map(item => ({ ...item, seen: '' }));

          return {
            ...state,
            hasUnread: newNotifications.some(item => item.seen === null),
            notifications: newNotifications,
          };
        });
      });
    }
  },
  connectSocket: () => {
    const connect = new HubConnectionBuilder()
      .withUrl('/hubs/notifications', {
        headers: { Authorization: getHeaders(true).get('Authorization') },
      })
      .withAutomaticReconnect()
      .configureLogging(0)
      .build();

    if (connect) {
      connect.start();

      connect.on('NotifyNotificationReceived', value => {
        get().addNotification(value.notificationDto);
      });

      set(state => ({ ...state, connect }));
    }
  },
  disconnectSocket: () => {
    const connect = get().connect;

    if (connect) {
      connect.stop();
    }
  },
}));
