import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import resources from '../../constants/resources';
import formats from '../../constants/formats';

const exporter = (records, fetchRelatedRecords) => {
  Promise.all([
    fetchRelatedRecords(records, 'groupId', resources.PRODUCT_GROUP),
    fetchRelatedRecords(records, 'departmentId', resources.DEPARTMENT),
    fetchRelatedRecords(records, 'priceMethod', resources.PRICE_METHOD),
  ]).then(([productGroups, departments, priceMethods]) => {
    const data = records.map(
      ({ productCode, description, groupId, departmentId, typicalPieces, pricePerUnit, priceMethod }) => {
        const { groupDescription } = productGroups[groupId] || {};
        const { description: departmentDescription } = departments[departmentId] || {};
        const { description: priceMethodDescription } = priceMethods[priceMethod] || {};

        return {
          'Product Code': productCode,
          Description: description,
          'Product Group': groupDescription,
          Department: departmentDescription,
          'Typical Pieces': typicalPieces,
          'Sale Price Per Unit': pricePerUnit,
          'Sale Price Method': priceMethodDescription,
        };
      },
    );
    const csv = convertToCSV({
      data,
    });
    downloadCSV(csv, `products_${moment().format(formats.MOMENT)}`);
  });
};

export default exporter;
