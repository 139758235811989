import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { arrayPush as arrayPushAction } from 'redux-form';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import { getEstimatedTotalPriceExVat, getTotalPriceExVat } from '../../../services/calcOrdersFunc';
import resources from '../../../constants/resources';
import { ordersConstants } from '../../../constants/ordersConstants';

class AddOrderButton extends Component {
  addRecentItem = () => {
    const {
      arrayPush,
      record: { productId },
      products,
      vatCodes,
    } = this.props;
    let {
      record: { quantityReceived, weightReceived },
    } = this.props;

    const { costPriceMethod = 0, vatcodeId, metricId, purchasesVatcodeId, description } = products[productId];
    let { costPricePerUnit } = products[productId];
    costPricePerUnit = costPricePerUnit || 0;

    switch (costPriceMethod) {
      case ordersConstants.quantityPriceMethod:
        weightReceived = null;
        break;
      case ordersConstants.weightPriceMethod:
        quantityReceived = null;
        break;
      default:
        quantityReceived = null;
        weightReceived = null;
    }

    const vatCode = vatCodes[vatcodeId];
    const totalPriceExVat = getTotalPriceExVat(
      costPriceMethod,
      costPricePerUnit,
      0,
      quantityReceived || weightReceived,
    );
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    arrayPush(REDUX_FORM_NAME, 'purchaseOrderItems', {
      productId,
      productDescription: description,
      quantityReceived,
      weightReceived,
      priceMethod: costPriceMethod,
      deliveryDate: moment(),
      purchasesVatcodeId,
      pricePerUnit: costPricePerUnit.toFixed(2),
      numberForFreezer: costPricePerUnit.toFixed(2),
      disc: (0).toFixed(2),
      totalPriceExVat: totalPriceExVat.toFixed(2),
      estimatedTotalPriceExVat: estimatedTotalPriceExVat.toFixed(2),
      packTypeId: metricId || 0,
    });
  };

  render() {
    return (
      <FormDataConsumer>
        {({ formData }) =>
          !formData.purchased && (
            <Button size="small" onClick={this.addRecentItem}>
              <AddIcon />
            </Button>
          )
        }
      </FormDataConsumer>
    );
  }
}

AddOrderButton.propTypes = {
  record: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  arrayPush: PropTypes.func,
  products: PropTypes.instanceOf(Object),
  vatCodes: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  products: state.admin.resources[resources.PRODUCT].data,
  vatCodes: state.admin.resources[resources.VATCODE].data,
});

export default connect(
  mapStateToProps,
  {
    arrayPush: arrayPushAction,
  },
)(AddOrderButton);
