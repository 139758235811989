import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { CloseIcon } from '../../../../../../common/icons/Close';

const styles = withStyles({
  root: {
    maxWidth: 640,
    width: '100%',
    height: 320,
    borderRadius: 8,
    fontFamily: 'Public Sans',
  },

  title: {
    padding: '12px 20px',
    backgroundColor: '#F6F7F9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'inherit',
    color: '#000000',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
  },

  closeButton: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    width: 20,
    height: 20,
    color: '#525252',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },

  close: {
    width: 'inherit',
    height: 'inherit',
  },

  content: {
    padding: '24px 6px 24px 20px',
      fontFamily: 'inherit',
      color: '#101010',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '24px'
  },
});

export const MoreModal = styles(({ open, onClose, title, children, classes }) => (
  <Dialog
    open={open}
    onClose={onClose}
    classes={{
      paper: classes.root,
    }}
  >
    <div className={classes.title}>
      <span>{title}</span>
      <button type="button" className={classes.closeButton} onClick={onClose}>
        <CloseIcon className={classes.close} />
      </button>
    </div>
    <DialogContent
      classes={{
        root: classes.content,
      }}
    >
      {children}
    </DialogContent>
  </Dialog>
));
