import { API_AUTHORIZATION_URL } from '../constants/urls';

const TOKEN_ROLE_PROP = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const getValueFromToken = (accessToken, key) => {
  if (accessToken) {
    const parsedToken = JSON.parse(window.atob(accessToken.split('.')[1]));
    return parsedToken[key];
  }
  return null;
};

/* current supplier */
const DBIdKey = 'DBId';

export const saveDBId = id => localStorage.setItem(DBIdKey, id);
export const getDBId = () => localStorage.getItem(DBIdKey);
export const clearDBId = () => localStorage.removeItem(DBIdKey);
/* end current supplier */

/* customerId */
const customerIdKey = 'customerId';

export const saveCustomerId = id => localStorage.setItem(customerIdKey, id);
export const getCustomerId = () => Number(localStorage.getItem(customerIdKey));
export const clearCustomerId = () => localStorage.removeItem(customerIdKey);
/* end customerId */

export const clearTokens = () => {
  delete localStorage.accessToken;
  delete localStorage.refreshToken;
  delete localStorage.userId;
  delete localStorage.username;
  delete localStorage.fullName;
  delete localStorage.role;

  clearDBId();
  clearCustomerId();
};

export const saveTokens = data => {
  const token = data.accessToken || data.token;
  localStorage.accessToken = token;
  localStorage.refreshToken = data.refreshToken;
  localStorage.role = getValueFromToken(token, TOKEN_ROLE_PROP);

  if (data.userId) localStorage.userId = data.userId;
  if (data.username) localStorage.username = data.username;
  if (data.fullName) localStorage.fullName = data.fullName;
};

export const hasTokens = () => !!localStorage.accessToken && !!localStorage.username;

export const getRole = () => localStorage.getItem('role');

export const getHeaders = authenticated => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (authenticated) {
    headers.set('Authorization', `Bearer ${localStorage.accessToken}`);

    const dbName = getDBId();
    if (dbName) {
      headers.set('TENANT-NAME', dbName);
    }
  }

  return headers;
};

let newTokenRequest;

export const reauthenticateIfNeeded = async status => {
  if (status !== 401 || !localStorage.accessToken) return false;
  if (newTokenRequest) {
    return newTokenRequest;
  }

  newTokenRequest = new Promise(async resolve => {
    const { refreshToken, accessToken } = localStorage;
    const request = new Request(API_AUTHORIZATION_URL, {
      method: 'PUT',
      body: JSON.stringify({ refreshToken, accessToken }),
      headers: getHeaders(),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      clearTokens();

      window.location.href = '/#/login';

      resolve(false);
      newTokenRequest = null;
      return;
    }

    const data = await response.json();
    saveTokens(data);

    resolve(true);
    newTokenRequest = null;
  });

  return newTokenRequest;
};
