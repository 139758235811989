import React from 'react';
import { Show, ReferenceInput, DisabledInput, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateInput } from 'react-admin-date-inputs';
import { connect } from 'react-redux';

import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import { convertUtcToLocal } from '../../services/date';
import resources from '../../constants/resources';
import FormWrapper from '../common/ui/FormWrapper';
import CreditNoteItem from './CreditNoteItem';
import Toolbar from './Toolbar';
import formats from '../../constants/formats';

const AddressInput = ({ record }) => <span>{record.deliveryAddress ? 'Default address' : ''}</span>;

AddressInput.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

const DisabledCurrencyInput = ({ record, source, label, currency }) => {
  const currencySymbol = (record[source] || '').trim();
  const { description } = Object.values(currency).find(item => item.symbol === currencySymbol) || {};

  return (
    <DisabledInput
      label={label}
      InputProps={{ inputProps: { value: description } }}
      InputLabelProps={{ shrink: true }}
    />
  );
};

DisabledCurrencyInput.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string,
  label: PropTypes.string,
  currency: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  currency: state.admin.resources[resources.CURRENCY].data,
});

const CurrencyInput = connect(mapStateToProps)(DisabledCurrencyInput);

const Form = ({ isCreate, ...rest }) => (
  <DOForm {...rest} toolbar={<Toolbar isDisabled={!isCreate} />}>
    <Content position="main">
      <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Customer Code">
        <SelectInput disabled optionText="customerCode" />
      </ReferenceInput>
      <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Customer Name">
        <SelectInput disabled optionText="companyName" />
      </ReferenceInput>
      <DisabledInput source="purchaseOrderNumber" label="PO Number" />
      <DisabledInput source="invoiceId" label="Invoice No" />
      <DisabledInput source="reference" label="Reference" />
    </Content>

    <Content position="side">
      <FormTab label="Address" tabIndex={-1}>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Select address">
          <SelectInput disabled source="deliveryAddress" optionText={<AddressInput />} />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Address">
          <SelectInput disabled optionText="deliveryAddress" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Route">
          <SelectInput disabled optionText="routeId" />
        </ReferenceInput>

        {/* customer comment: you can ignore this field at the moment. Do not populate, but don't delete it.  */}
        <DisabledInput label="Haulier" source="haulier" />
      </FormTab>

      <FormTab label="Sale Details" tabIndex={-1}>
        <ReferenceInput source="invoiceId" reference={resources.INVOICE} label="Order No">
          <SelectInput disabled optionText="saleOrderId" />
        </ReferenceInput>
        <ReferenceInput source="invoiceId" reference={resources.INVOICE} label="Sale Date">
          <SelectInput disabled optionText={item => item.timeDate && moment(item.timeDate).format(formats.MOMENT)} />
        </ReferenceInput>
        <ReferenceInput source="invoiceId" reference={resources.INVOICE} label="Sale Person">
          <SelectInput disabled optionText="saleOrderUserName" />
        </ReferenceInput>
        <CurrencyInput label="Currency" source="currencySymbol" />

        <ReferenceInput source="returnItemId" reference={resources.RETURN_ITEM} label="Return No">
          <SelectInput
            disabled
            optionText={item =>
              item.returnId &&
              new Intl.NumberFormat(formats.LOCALES, { minimumIntegerDigits: 6 }).format(item.returnId).replace(',', '')
            }
          />
        </ReferenceInput>

        <DateInput
          source="creditNoteDate"
          label="Dispatch Date"
          format={convertUtcToLocal}
          options={{ format: formats.DATE, disabled: true }}
        />
        <ReferenceInput source="userId" reference={resources.USER} label="Dispatcher">
          <SelectInput disabled optionText="fullName" />
        </ReferenceInput>
      </FormTab>

      <FormTab label="Dispatch Notes" tabIndex={-1}>
        <ReferenceInput source="invoiceId" reference={resources.INVOICE} label="Dispatch Notes">
          <SelectInput disabled optionText="notes" multiline />
        </ReferenceInput>
        <DisabledInput label="Credit Note Notes" source="creditNotes" multiline rows="5" />
      </FormTab>

      <FormTab label="Popup Notes" tabIndex={-1}>
        <DisabledInput label="Popup Notes" source="popupNotes" multiline rows="5" xs={12} />
      </FormTab>

      <FormTab label="Contact Details" tabIndex={-1}>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Contact">
          <SelectInput disabled optionText="contactName" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Phone">
          <SelectInput disabled optionText="phone" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Phone Other">
          <SelectInput disabled optionText="phoneOther" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Fax">
          <SelectInput disabled optionText="fax" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Email">
          <SelectInput disabled optionText="email" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Accounts Contact">
          <SelectInput disabled optionText="secondaryContact" />
        </ReferenceInput>
      </FormTab>
    </Content>

    <Content position="bottom">
      <CreditNoteItem isCreate={isCreate} {...rest} />
    </Content>
  </DOForm>
);

Form.propTypes = {
  isCreate: PropTypes.bool,
};

export default Form;

export const CreditNoteShow = props => (
  <FormWrapper>
    <Show {...props}>
      <Form isCreate={false} />
    </Show>
  </FormWrapper>
);
