const styles = theme => ({
  card: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  fieldWrap: {
    maxWidth: theme.spacing.unit * 23.875,
    marginRight: theme.spacing.unit * 2,
  },
  fieldWrapMargin: {
    maxWidth: theme.spacing.unit * 23.875,
    marginRight: 'auto',
  },
  buttonWrap: {
    marginLeft: theme.spacing.unit * 2,
  },
  simpleShowLayout: {
    minHeight: '50vh',
  },
});

export default styles;
