import React from 'react';

export const PrintIcon = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.39999 8.19998V4H13.6V8.19998M6.39999 13.6H5.2C4.88174 13.6 4.57651 13.4735 4.35147 13.2485C4.12643 13.0234 4 12.7182 4 12.4V9.39998C4 9.08172 4.12643 8.7765 4.35147 8.55145C4.57651 8.32641 4.88174 8.19998 5.2 8.19998H14.8C15.1182 8.19998 15.4234 8.32641 15.6485 8.55145C15.8735 8.7765 16 9.08172 16 9.39998V12.4C16 12.7182 15.8735 13.0234 15.6485 13.2485C15.4234 13.4735 15.1182 13.6 14.8 13.6H13.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.40015 11.2002H13.6001V16.0002H6.40015V11.2002Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
