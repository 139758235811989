import { MAX_SAFE_MONEY } from './common';

export const USERNAME_REQUIRED = 'Username is required';
export const PASSWORD_REQUIRED = 'Password is required';
export const NEW_PASSWORD_REQUIRED = 'New password is required';
export const NAME_REQUIRED = 'Name is required';
export const MAX_CHARACTERS_50 = 'Must be 50 characters or less';
export const PASSWORD_REGEX =
  'Password should contain at least one uppercase, one lowercase, one number, one special character';
export const PASSWORD_LENGTH = 'Password should be at least 8 characters long';
export const PASSWORD_CONFIRM = 'Must be the same as the new password';
export const PASSWORD_CHANGED = 'Your Password has been changed!';
export const INTEGER_NUMBER = 'The number has to be integer';
export const EMAIL_REQUIRED = 'Email is required';
export const EMAIL_INVALID = 'Enter valid email';
export const MAX_NUMBER = `Must be ${MAX_SAFE_MONEY} or less`;
export const DELIVERY_DATE = 'Set Delivery Date tomorrow or later.';
