import React from 'react';

export const CheckedIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
      <path d="M7 11.7L10.5484 15L17 9" stroke="#4491CF" strokeWidth="2" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#A9AFBC" />
    </svg>
  );
};
