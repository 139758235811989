import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formPropTypes, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core/';
import { PASSWORD_REQUIRED, USERNAME_REQUIRED } from '../../constants/errorMessages';
import styles from './styles';
import { UiButton } from '../common/ui/NewDesign/Button';
import { EyeOnIcon } from '../common/icons/EyeOn';
import { EyeOffIcon } from '../common/icons/EyeOff';
import { AuthField } from './Field';
import classnames from 'classnames';

const form = 'singIn';

class LoginForm extends Component {
  state = {
    showPassword: false,
    readOnly: true,
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleFocus = () => {
    this.setState(prevState => ({ ...prevState, readOnly: false }));
  };

  render() {
    const {
      classes,
      isLoading,
      isInactiveScreenLoading,
      handleSubmit,
      login,
      showForgetPasswordForm,
      fromPortal,
    } = this.props;
    const { showPassword } = this.state;

    const disabled = isLoading || isInactiveScreenLoading;

    return (
      <form onSubmit={handleSubmit(login)} noValidate className={classes.form}>
        <div className={classes.inputs}>
          <AuthField
            name="username"
            label="Username"
            disabled={disabled}
            InputProps={{
              readOnly: this.state.readOnly,
            }}
            onFocus={this.handleFocus}
          />

          <AuthField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            disabled={disabled}
            onFocus={this.handleFocus}
            InputProps={{
              readOnly: this.state.readOnly,
              endAdornment: (
                <div
                  className={classnames(classes.adornment, { disabled })}
                  onClick={disabled ? undefined : this.handleClickShowPassword}
                >
                  {showPassword ? (
                    <EyeOnIcon className={classes.inputIcon} />
                  ) : (
                    <EyeOffIcon className={classes.inputIcon} />
                  )}
                </div>
              ),
            }}
          />
        </div>
        <div className={classes.actions}>
          <UiButton component="button" className={classes.button} type="submit" disabled={disabled}>
            {disabled ? <CircularProgress size={25} thickness={2} /> : 'sign in'}
          </UiButton>

          {!fromPortal && (
            <UiButton
              bordered
              component="button"
              className={classes.button}
              onClick={showForgetPasswordForm}
              disabled={disabled}
              type="button"
            >
              Forgotten password
            </UiButton>
          )}
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  ...formPropTypes,
  classes: PropTypes.objectOf(PropTypes.string),
  isLoading: PropTypes.bool,
  isInactiveScreenLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  showForgetPasswordForm: PropTypes.func,
  fromPortal: PropTypes.bool, // for InactiveScreenPage
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({
    form,
    validate: ({ username, password }) => {
      const errors = {};
      if (!username) {
        errors.username = USERNAME_REQUIRED;
      }
      if (!password) {
        errors.password = PASSWORD_REQUIRED;
      }
      return errors;
    },
  }),
);

export default enhance(LoginForm);
