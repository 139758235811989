import React from 'react';

import { SUPPLIER_SELECTION_ROUTE } from '../constants/routes';
import { customHistory } from '../history';

export const withShowSidebar = Component => props => {
  const showSidebar = customHistory.location.pathname !== SUPPLIER_SELECTION_ROUTE;

  return <Component showSidebar={showSidebar} {...props} />;
};
