import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Create,
  DisabledInput,
  Edit,
  REDUX_FORM_NAME,
  ReferenceInput,
  SelectInput,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import resources from '../../constants/resources';
import FormWrapper from '../common/ui/FormWrapper';
import OrderItemsList from './OrderItemsList';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { change } from 'redux-form';

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const EditToolbar = connect(mapStateToProps)(({ record, isLoading, ...rest }) => (
  <Toolbar {...rest}>
    <Fragment />
  </Toolbar>
));

EditToolbar.propTypes = {
  record: PropTypes.instanceOf(Object),
};

const Form = props => (
  <DOForm {...props}>
    <Content position="main">
      <ReferenceInput source="bomID" label="Template Code" reference={resources.PRODUCTION_TEMPLATE}>
        <SelectInput optionText="productTemplate.product.productCode" />
      </ReferenceInput>
      <ReferenceInput source="bomID" label="Template Name" reference={resources.PRODUCTION_TEMPLATE}>
        <SelectInput optionText="description" />
      </ReferenceInput>
      <TextInput source="status" label="Status" />
      <TextInput source="plannedQty" label="Quantity" />
      <TextInput source="plannedWt" label="Weight" />
      <TextInput source="numOfMixes" label="Mixes" />
    </Content>

    <Content position="side">
      <FormTab label="Template" tabIndex={-1}>
        <DisabledInput disabled source="type" xs={12} />
        <DisabledInput disabled source="productionLine" xs={12} />
        <DisabledInput disabled source="dueDate" xs={12} />
      </FormTab>
      <FormTab label="Information" tabIndex={-1}>
        <DisabledInput disabled source="notes" multiline rows="1" xs={12} />
        <DisabledInput disabled source="specifications" multiline rows="1" xs={12} />
      </FormTab>
    </Content>
    <Content position="bottom">
      <OrderItemsList {...props} />
    </Content>
  </DOForm>
);

const FormContainer = compose(
  connect(
    mapStateToProps,
    {
      changeValue: (source, value) => change(REDUX_FORM_NAME, source, value),
    },
  ),
  withProps(),
)(Form);

const redirect = basePath => `${basePath}?submitted=true`;

export const ProductionOrderEdit = props => (
  <FormWrapper>
    <Edit {...props}>
      <FormContainer isEdit redirect={redirect} toolbar={<EditToolbar />} />
    </Edit>
  </FormWrapper>
);

export const ProductionOrderCreate = props => (
  <FormWrapper>
    <Create {...props}>
      <FormContainer redirect={redirect} />
    </Create>
  </FormWrapper>
);
