import { httpClient } from '../../../provider/dataProvider';
import { API_URL } from '../../../constants/urls';

const convertArrayToQuery = list =>
  list.reduce((prev, id, index) => `${prev}id=${id}${index !== list.length - 1 ? '&' : ''}`, '?');

export const sendReports = async (list, resource, showNotification, setLoading) => {
  setLoading(true);
  try {
    await httpClient(`${API_URL}/${resource}${convertArrayToQuery(list)}`);
    showNotification('You have successfully sent reports', 'info');
  } catch (e) {
    showNotification(e.message, 'warning');
  }
  setLoading(false);
};
