import React, { Children } from 'react';
import * as PropTypes from 'prop-types';
import {
  FormInput,
  BooleanInput,
  ReferenceField,
  TextField,
  FormDataConsumer,
  ReferenceFieldController,
} from 'react-admin';
import { Grid, withStyles } from '@material-ui/core';
import classnames from 'classnames';

import { positions } from './Content';
import DOTextField from '../DOTextField';

const componentsNotCapableOfFullWidth = [BooleanInput, ReferenceField, TextField, DOTextField];
const componentsAddLabel = [FormDataConsumer, DOTextField, ReferenceFieldController];

const styles = theme => ({
  checkbox: {
    marginTop: theme.spacing.unit * 3.5,
  },
  input: {
    minWidth: 0,
    width: '100%',
  },
});

const bottomGridSize = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 3,
};
const sideGridSize = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 4,
};

const SimpleInputs = ({ classes, basePath, children, record, resource, position, isNewDesign }) => (
  <Grid container spacing={isNewDesign ? 24 : 16}>
    {Children.toArray(children).filter(Boolean).map(input => {
      const { xs, sm, md, lg, xl } = input.props;

      const getGridSize = () => {
        if (isNewDesign && position === positions.MAIN) {
          return { xs: 12, lg: 4 };
        }

        if (xs || sm || md || lg || xl) {
          return { xs, sm, md, lg, xl };
        }

        return position === positions.MAIN || position === positions.SIDE ? sideGridSize : bottomGridSize;
      };

      return (
        <Grid item {...getGridSize()}>
          <FormInput
            basePath={basePath}
            input={React.cloneElement(input, {
              className: classnames(
                {
                  [classes.checkbox]: input.type === BooleanInput,
                },
                classes.input,
              ),
              ...(componentsNotCapableOfFullWidth.includes(input.type) ? {} : { fullWidth: true }),
              ...(componentsAddLabel.includes(input.type) && input.props.label ? { addLabel: true } : {}),
            })}
            record={record}
            resource={resource}
          />
        </Grid>
      );
    })}
  </Grid>
);

SimpleInputs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  basePath: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.node,
  record: PropTypes.instanceOf(Object),
  resource: PropTypes.string,
  isNewDesign: PropTypes.bool,
};

export default withStyles(styles)(SimpleInputs);
