const toolbar = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    minWidth: '163px',
  },
});

export default toolbar;
