import { fetchUtils } from 'react-admin';

import resources from '../constants/resources';
import * as urls from '../constants/urls';
import { flattenObject, httpClient } from '../provider/dataProvider';

const fetchReportData = ({ id, criteria, recordId, hasCriteria }) => {
  const queryParams = fetchUtils.queryParameters({
    ...flattenObject(criteria, 'criteria'),
    recordId,
    hasCriteria,
  });
  const url = `${urls.API_URL}/${resources.REPORT_DATA}/${id}?${queryParams}`;

  return httpClient(
    url,
    {
      responseType: 'blob',
    },
    false,
  );
};

const fetchReportCriteria = reportId => {
  const queryParams = fetchUtils.queryParameters({ reportId });
  const url = `${urls.API_URL}/${resources.REPORT_CRITERIA}?${queryParams}`;

  return httpClient(url);
};

export { fetchReportData, fetchReportCriteria };
