import { maxValue, minValue } from 'react-admin';
import lodashMemoize from 'lodash/memoize';
import emailValidator from 'email-validator';
import moment from 'moment';

import { MAX_SAFE_MONEY } from '../constants/common';
import {
  INTEGER_NUMBER,
  EMAIL_INVALID,
  PASSWORD_REQUIRED,
  PASSWORD_LENGTH,
  PASSWORD_REGEX,
  PASSWORD_CONFIRM,
  DELIVERY_DATE,
} from '../constants/errorMessages';

const isEmpty = value => typeof value === 'undefined' || value === null || value === '';

const regex = lodashMemoize((pattern, message) => value =>
  !isEmpty(value) && !pattern.test(value) ? message : undefined,
);

const VALIDATORS = {
  MONEY: [minValue(0), maxValue(MAX_SAFE_MONEY)],
  INTEGER: [minValue(0), maxValue(Number.MAX_SAFE_INTEGER), regex(/^[0-9\b]+$/, INTEGER_NUMBER)],
  INTEGER_POSITIVE: [minValue(1), maxValue(Number.MAX_SAFE_INTEGER), regex(/^[0-9\b]+$/, INTEGER_NUMBER)],
};

export const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

export const validEmail = email =>
  email && email.length && !emailValidator.validate(email) ? EMAIL_INVALID : undefined;

export const requiredPassword = value => (value ? undefined : PASSWORD_REQUIRED);

export const minLengthPassword = value => (value.length < 8 ? PASSWORD_LENGTH : undefined);

export const regexPassword = value => (passwordRegex.test(value) ? undefined : PASSWORD_REGEX);

export const confirmPassword = (value, { newPassword }) => (newPassword === value ? undefined : PASSWORD_CONFIRM);

export const minimalDate = lodashMemoize(minDeliveryDay => value => {
  const minDelivery = moment(minDeliveryDay).format('YYYY-MM-DD');
  const currentValue = moment(value).format('YYYY-MM-DD');
  const isBefore = moment(currentValue).isBefore(minDelivery);

  if (isBefore) {
    return DELIVERY_DATE;
  }
  return undefined;
});

export default VALIDATORS;
