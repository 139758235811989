import React from 'react';
import { Create, Edit } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {},
  card: {
    boxShadow: 'none',
    overflow: 'initial',
  },
});

export const UiCreateEditWrapper = withStyles(styles)(({ isEdit, children, ...props }) => {
  const Wrapper = isEdit ? Edit : Create;

  return <Wrapper {...props}>{children}</Wrapper>;
});
