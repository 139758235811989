import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import { UncheckedIcon } from './icons/Unchecked';
import { CheckedIcon } from './icons/Checked';

const styles = () => ({
  root: {
    width: 24,
    height: 24,
  },
});

const Component = ({ classes, ...props }) => (
  <Checkbox
    {...props}
    icon={<UncheckedIcon className={classes.root} />}
    checkedIcon={<CheckedIcon className={classes.root} />}
  />
);

export const UiTableCheckbox = withStyles(styles)(Component);
