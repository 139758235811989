import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { fetchEnd, fetchStart, REDUX_FORM_NAME, showNotification } from 'react-admin';
import { change, isDirty } from 'redux-form';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DOForm } from '../../../common/ui/DOForm';
import Toolbar from './Toolbar';
import OrderDetails from './orderDetails/OrderDetails';
import GeneralInfo from './GeneralInfo';
import DeliveryDetails from './DeliveryDetails/index';
import { calculateDiscountPercent } from '../services/calculateDiscountPercent';
import { removeEmptyKeys } from '../../../common/utils';
import resources from '../../../../constants/resources';
import { roles } from '../../../../constants/roles';
import { formatDate } from '../../../../services/date';
import formats from '../../../../constants/formats';

const Form = props => {
  const {
    isEdit,
    save,
    changeValue,
    customers,
    productItems,
    vatCodes,
    dirty,
    redirect,
    transformedInitialValues,
    permissions,
    showNotificationAction,
    ...rest
  } = props;
  const { record } = props;

  useEffect(() => {
    // @TODO ugly, must use redux-form initialProps for hiddenCustomerId
    const {
      record: { customerId },
    } = props;

    if (isEdit) {
      changeValue('hiddenCustomerId', customerId);
    }
  });

  const handleSave = data => {
    removeEmptyKeys(data);
    const deliveryDate = formatDate(data.deliveryDate, formats.DELIVERY_DATE);
    let values = { ...data, deliveryDate, Exported: 0 };

    data.saleOrderItems.forEach(item => removeEmptyKeys(item));
    if (permissions === roles.CUSTOMER_ROLE || permissions === roles.STAFF_USER_ROLE) 
      {
      /*
        order can be crated by user and edited by customer, etc
        need some clean-up
      */
      delete values.userId;
      delete values.hiddenCustomerId;
    } else {
      const userId = +localStorage.userId || null;
      values = { ...values, userId };
    }

    if (!values.deliveryAddressId) {
      delete values.deliveryAddressId;
    }

    save(values, redirect);
  };

  const isCustomer = permissions === roles.CUSTOMER_ROLE;

  return (
    <>
      <DOForm
        isNewDesign
        save={handleSave}
        transformedInitialValues={transformedInitialValues}
        redirect={redirect}
        toolbar={<Toolbar isCustomer={isCustomer} showNotificationAction={showNotificationAction} />}
        title={isCustomer ? 'Order' : 'Order'}
        {...rest}
      >
        <GeneralInfo {...props} position="main" />
        <DeliveryDetails {...props} isNewDesign position="side" tabbed />
        <OrderDetails {...props} isNewDesign position="bottom" />
      </DOForm>

      <Prompt
        when={dirty && !record.dispatched}
        message={location =>
          location.search === '?submitted=true' || 'There are unsaved changes. Are you sure you want to leave a page?'
        }
      />
    </>
  );
};

const mapStateToProps = (state, { permissions }) => {
  let fields = [
    ['purchaseOrderNumber'],
    ['deliveryDate'],
    ['otherRef'],
    ['deliveryAddress'],
    ['routeId'],
    ['haulierId'],
    ['timeDate'],
    ['notes'],
    ['saleOrderItems'],
  ];

  if (permissions === roles.USER_ROLE || permissions === roles.STAFF_USER_ROLE) {
    // customer role load this fields by default, they allways dirty
    fields = [...fields, ['customerId'], ['deliveryAddressId'], ['currencyId']];
  }

  return {
    customers: state.admin.resources[resources.CUSTOMER].data,
    productItems: state.admin.resources.product.data,
    vatCodes: state.admin.resources[resources.VATCODE].data,
    dirty: isDirty(REDUX_FORM_NAME)(state, ...fields),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeValue: (source, value) => change(REDUX_FORM_NAME, source, value),
      fetchEndAction: fetchEnd,
      fetchStartAction: fetchStart,
      showNotificationAction: showNotification,
    },
    dispatch,
  );

export default compose(
  withProps(calculateDiscountPercent),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Form);

Form.propTypes = {
  isEdit: PropTypes.bool,
  record: PropTypes.instanceOf(Object),
  transformedInitialValues: PropTypes.instanceOf(Object),
  save: PropTypes.func,
  showNotificationAction: PropTypes.func,
  redirect: PropTypes.func,
  changeValue: PropTypes.func,
  customers: PropTypes.objectOf(PropTypes.object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  productItems: PropTypes.objectOf(PropTypes.object),
  dirty: PropTypes.bool,
  permissions: PropTypes.string,
};
