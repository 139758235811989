import React, { Component, Fragment } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import PropTypes from 'prop-types';

import ReportCriteria from './ReportCriteria';
import { LinkDownloadPDF } from '../reportData';
import * as routes from '../../constants/routes';

const styles = {
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

class LinkReportData extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      record: { hasCriteria, hasGetRecordIdSql },
    } = this.props;
    const {
      record: { hasCriteria: newHasCriteria, hasGetRecordIdSql: newHasGetRecordIdSql },
    } = nextProps;
    return hasCriteria !== newHasCriteria || hasGetRecordIdSql !== newHasGetRecordIdSql;
  }

  render() {
    const {
      record: { hasCriteria, hasGetRecordIdSql, id },
      record,
      classes,
      search,
    } = this.props;

    if (hasCriteria) {
      return (
        <Fragment>
          <ReportCriteria reportId={id} hasGetRecordIdSql={hasGetRecordIdSql} />
        </Fragment>
      );
    }

    if (!hasCriteria && !hasGetRecordIdSql) {
      return <LinkDownloadPDF search={search} record={record} />;
    }

    if (!hasCriteria && hasGetRecordIdSql) {
      return (
        <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: routes.REPORT_DATA_ROUTE,
            search: stringify({
              filter: JSON.stringify({ reportType: record.id }),
            }),
          }}
          className={classes.link}
        >
          Open
        </Button>
      );
    }

    return null;
  }
}

LinkReportData.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  search: PropTypes.string,
  record: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(LinkReportData);
