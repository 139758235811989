import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
  },
  rootAbsolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
};

const FormWrapper = props => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <div className={classes.rootAbsolute}>{children}</div>
    </div>
  );
};

FormWrapper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(FormWrapper);
