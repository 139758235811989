import React from 'react';
import { TextField, ReferenceInput, SelectInput } from 'react-admin';

import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import resources from '../../constants/resources';

export default props => (
  <DOForm {...props}>
    <Content position="main">
      <TextField source="companyName" label="Company Name" />
    </Content>

    <Content position="bottom">
      <FormTab label="B2B">
        <TextField source="b2BEmail" label="B2B Email" />
        <ReferenceInput source="b2BCustomerCatalog" reference={resources.B2B_CUSTOMER_CATALOG} label="B2B Catalog">
          <SelectInput disabled optionText="description" />
        </ReferenceInput>
      </FormTab>
    </Content>
  </DOForm>
);
