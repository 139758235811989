import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Card, CardContent, Typography } from '@material-ui/core/';
import { RichTextField } from 'react-admin';
import resources from '../../../constants/resources';
import { API_URL } from '../../../constants/urls';

const styles = theme => ({
  card: {
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 1.5,
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: 'initial',
    minWidth: 'initial',
    maxWidth: '50em',
    maxHeight: '18em',
  },
  textField: {
    marginTop: theme.spacing.unit * 1.5,
    color: theme.palette.grey[500],
    fontSize: '1rem',
    borderBottom: `1px dashed ${theme.palette.grey[300]}`,
  },
});

const SpecificationDetail = ({ classes, activeCustomer }) => {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <img
          src={`${API_URL}/${resources.PRODUCT_SPECIFICATION_IMAGE}/${activeCustomer.id}`}
          className={classes.img}
          alt=""
        />
      </CardContent>
      <CardContent>
        <Typography>Cutting Specification:</Typography>
        <RichTextField record={activeCustomer} source="cuttingSpecification" className={classes.textField} />
      </CardContent>
      <CardContent>
        <Typography>Packing Specification:</Typography>
        <RichTextField record={activeCustomer} source="packingSpecification" className={classes.textField} />
      </CardContent>
    </Card>
  );
};

SpecificationDetail.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  activeCustomer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
    customerId: PropTypes.number.isRequired,
    cuttingSpecification: PropTypes.string.isRequired,
    packingSpecification: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles)(SpecificationDetail);
