import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  CREATE,
  crudCreate as crudCreateAction,
  crudUpdate as crudUpdateAction,
  SaveButton,
  showNotification as showNotificationAction,
  Toolbar,
  WithPermissions,
} from 'react-admin';
import { CircularProgress, withStyles } from '@material-ui/core';
import { ContentCopy, Link as LinkIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import resources from '../../constants/resources';
import { INVOICE_ROUTE } from '../../constants/routes';
import dataProvider from '../../provider/dataProvider';
import { getBasePrice } from '../../services/calcOrdersFunc';
import { roles } from '../../constants/roles';
import { openReport } from '../common/services/openReport';
import { UiButton } from '../common/ui/NewDesign/Button';
import { SaveIcon } from '../common/icons/Save';
import { CopyIcon } from '../common/icons/Copy';

const sanitizeRestProps = ({ basePath, dispatchItemData, crudCreate, crudUpdate, showNotification, ...props }) => props;

const styles = () => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
  },
  openReport: {
    minWidth: '163px',
    marginRight: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  invoiceButton: {
    '& > span > svg': {
      fill: 'currentColor',
    },
  },

  toolbarNew: {
    backgroundColor: 'transparent',
    marginTop: 32,
    marginBottom: 32,
    minHeight: 'auto',
    padding: 0,
    justifyContent: 'flex-end',
    gap: '16px',
  },
});

class EditToolbar extends Component {
  state = {
    invoiceId: null,
    loadingOpenReport: false,
  };

  componentDidUpdate(prevProps) {
    const { dispatchItemData, record } = this.props;
    if (prevProps.dispatchItemData.fetchedAt === dispatchItemData.fetchedAt) return;
    const invoice = Object.values(dispatchItemData).find(item => item.dispatchId === record.id);
    const { invoiceId } = invoice || {};
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ invoiceId });
  }

  copyToInvoice = () => {
    const { basePath, handleSubmit, record, dispatchItemData, crudUpdate, showNotification } = this.props;
    const dispatchItems = Object.values(dispatchItemData).filter(item => item.dispatchId === record.id);
    return handleSubmit(values => {
      if (!dispatchItems.length) {
        showNotification('This order has not been completed at the scales', 'info');
        return;
      }
      const data = {
        ...values,
        id: 0,
        invoiceDate: values.dispatchDate,
        saleOrderId: values.salesId,
        printed: values.printed,
        invoiceItems: dispatchItems.map(item => ({
          ...item,
          id: 0,
          quantity: item.dispatchQuantity,
          weight: item.dispatchWeight,
          dispatchItemId: item.id,
          saleOrderItemId: item.orderItemId,
          pricePostDiscount: item.pricePerUnit,
          totalExVat: getBasePrice(item.priceMethod, item.pricePerUnit, item.dispatchQuantity, item.dispatchWeight),
        })),
      };

      const netTotal = data.invoiceItems.reduce((p, c) => p + c.totalExVat, 0).toFixed(2);
      data.netTotal = +netTotal;

      dataProvider(CREATE, resources.INVOICE, { data })
        .then(res => {
          const requestDispatchItems = dispatchItems.map(item => ({
            ...item,
            invoiceId: res.data.id,
          }));
          crudUpdate(
            resources.DISPATCH_DOCKET,
            values.id,
            { ...values, dispatched: 1, dispatchItems: requestDispatchItems },
            record,
            basePath,
            'list',
          );
        })
        .catch(e => {
          showNotification(`Error: ${e.name} ${e.status}`, 'warning');
        });
    });
  };

  setOpenReportLoading = value => this.setState({ loadingOpenReport: value });

  render() {
    const { classes, handleSubmitWithRedirect, isLoading, permissions, ...rest } = this.props;
    const {
      record: { id },
      showNotification,
    } = this.props;
    const { invoiceId, loadingOpenReport } = this.state;

    return (
      <Toolbar className={classes.toolbarNew}>
        <UiButton
          component={SaveButton}
          label="Save"
          redirect="list"
          icon={<SaveIcon />}
          variant="text"
          submitOnEnter
        />
        <div className={classes.buttons}>
          {permissions && permissions === roles.USER_ROLE && (
            <Fragment>
              <UiButton
                disabled={loadingOpenReport}
                className={classes.openReport}
                variant="contained"
                color="secondary"
                onClick={() =>
                  openReport(id, resources.DISPATCH_DOCKET_REPORT, showNotification, this.setOpenReportLoading)
                }
              >
                {loadingOpenReport ? (
                  <CircularProgress size={25} thickness={5} />
                ) : (
                  <>
                    <CopyIcon />
                    Open Report
                  </>
                )}
              </UiButton>
              {invoiceId ? (
                <Fragment>
                  <UiButton
                    componentInner={Link}
                    variant="raised"
                    size="medium"
                    color="secondary"
                    disabled={isLoading}
                    label={isLoading ? 'loading...' : 'Open invoice'}
                    to={{
                      pathname: `${INVOICE_ROUTE}/${invoiceId}`,
                    }}
                    className={classes.invoiceButton}
                  >
                    {isLoading ? <CircularProgress size={25} thickness={2} color="secondary" /> : <LinkIcon />}
                  </UiButton>
                </Fragment>
              ) : (
                <UiButton
                  component={SaveButton}
                  redirect="list"
                  disabled={isLoading}
                  label={isLoading ? 'loading...' : 'Copy to invoice'}
                  variant="raised"
                  size="medium"
                  color="secondary"
                  icon={isLoading ? <CircularProgress size={25} thickness={2} color="secondary" /> : <ContentCopy />}
                  handleSubmitWithRedirect={this.copyToInvoice}
                  {...sanitizeRestProps(rest)}
                />
              )}
            </Fragment>
          )}
        </div>
      </Toolbar>
    );
  }
}
EditToolbar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  dispatchItemData: PropTypes.objectOf(PropTypes.object),
  handleSubmitWithRedirect: PropTypes.func,
  handleSubmit: PropTypes.func,
  basePath: PropTypes.string,
  crudCreate: PropTypes.func.isRequired,
  crudUpdate: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any),
  showNotification: PropTypes.func,
  isLoading: PropTypes.bool,
  permissions: PropTypes.string,
};

const mapStateToProps = state => ({
  dispatchItemData: state.admin.resources.dispatchItem.data,
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    { crudCreate: crudCreateAction, crudUpdate: crudUpdateAction, showNotification: showNotificationAction },
  ),
);

const EditToolbarWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <EditToolbar permissions={permissions} {...props} />} />
);

export default enhance(EditToolbarWithPermissions);
