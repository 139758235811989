import React, { useRef } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { UiBaseInput } from './BaseInput';
import { AutocompleteInput } from 'react-admin';
import { CheckIcon } from './icons/Check';

const StyledComponent = withStyles(() => ({
  suggestionsPaper: {
    maxHeight: '33.3vh',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 8px 0px rgba(178, 199, 224, 0.40)',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
}))(props => <AutocompleteInput {...props} />);

const styles = () => ({
  item: {
    boxSizing: 'border-box',
    position: 'relative',
    minHeight: 44,
    padding: 12,
    fontFamily: 'Public Sans',
    fontSize: 14,
    lineHeight: '20px',
    color: '#404040',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',

    '&.selected': {
      color: '#0F4686',
    },

    '&:hover': {
      backgroundColor: '#F0F3F5',
      color: '#404040',
    },

    '& span, & strong': {
      fontWeight: '400 !important',
    },
  },

  icon: {
    width: 20,
    height: 20,
    color: '#525252',
    flexShrink: 0,
  },
});

export const UiAutoCompleteInput = withStyles(styles)(({ classes, optionValue = 'id', ...props }) => {
  const ref = useRef();

  const getItemStyles = () => {
    if (ref.current && ref.current.inputRef) {
      return { width: ref.current.inputRef.clientWidth };
    }

    return undefined;
  };

  return (
    <UiBaseInput
      suggestionComponent={({ suggestion, query, isHighlighted, className, children, ...rest }) => {
        const isSelected = props.input ? props.input.value === suggestion[optionValue] : false;
        return (
          <div
            style={getItemStyles()}
            className={classnames(classes.item, {
              selected: isSelected,
            })}
            {...rest}
          >
            {children}
            {isSelected && <CheckIcon className={classes.icon} />}
          </div>
        );
      }}
      options={{
        InputProps: {
          innerRef: ref,
        },
      }}
      component={StyledComponent}
      optionValue={optionValue}
      {...props}
    />
  );
});
