import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import {
  crudGetAll as crudGetAllAction,
  FormDataConsumer,
  ReferenceManyField,
  showNotification as showNotificationAction,
} from 'react-admin';
import compose from 'recompose/compose';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import RecentOrderGrid from './RecentOrderGrid';
import CurrentOrderItems from './CurrentOrderItem';
import TotalSection from './TotalSection';
import resources from '../../../../../constants/resources';
import { Content, FormTab } from '../../../../common/ui/DOForm';
import { ordersConstants } from '../../../../../constants/ordersConstants';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  card: {
    marginTop: theme.spacing.unit * 2.5,
  },
  cardTotal: {
    padding: theme.spacing.unit * 2.5,
    marginTop: theme.spacing.unit * 2.5,
  },
  paragraphInfo: {
    padding: theme.spacing.unit * 2.5,
  },
});

class OrderDetails extends React.Component {
  state = {
    currentTab: 0,
    isShowPriceInfo: undefined,
  };

  componentDidMount() {
    this.fetchVatCode();
    this.checkLeastOneSaleMethodDifference();
  }

  fetchVatCode = () => {
    const { crudGetAll } = this.props;
    crudGetAll(resources.VATCODE, { field: 'id', order: 'ASC' }, {}, 100);
  };

  addItemValidation = () => {
    const { formValues, showNotification } = this.props;
    if (!formValues.customerId) {
      showNotification('You must select customer', 'warning');
      return false;
    }
    return true;
  };

  checkMethodDifference = item => {
    const { productItems } = this.props;

    if (productItems) {
      let orderMethod = item.orderMethod;
      let priceMethod = item.priceMethod;


      if ((orderMethod === undefined || orderMethod === null)  && productItems[item.productId]) {
        orderMethod = productItems[item.productId].orderMethod;
      }
      if ((priceMethod === undefined  || priceMethod === null) && productItems[item.productId]) {
        priceMethod = productItems[item.productId].priceMethod;
      }
      if ((orderMethod === ordersConstants.weightOrderMethod && priceMethod === ordersConstants.weightPriceMethod) ||
      (orderMethod === ordersConstants.quantityOrderMethod && priceMethod === ordersConstants.quantityPriceMethod)
      ) {
        return false;
      }else
      {
        return true;
      }  
    }

    return false;
  };

  checkLeastOneSaleMethodDifference = () => {
    const { formValues, productItems } = this.props;

    if (Array.isArray(formValues.saleOrderItems) && productItems) {
      const isShowPriceInfo = formValues.saleOrderItems.some(this.checkMethodDifference);

      this.setState(prev => ({
        ...prev,
        isShowPriceInfo,
      }));
    }
  };

  render() {
    const {
      classes,
      formValues = {},
      changeValue,
      productItems,
      isEdit,
      showNotification,
      vatCodes,
      crudGetAll,
      permissions,
      context,
      resource,
      hidden,
      onChange,
      value,
      position,
      ...formProps
    } = this.props;

    return (
      <Content
        isNewDesign
        position={position}
        consumer={
          <FormDataConsumer>
            {({ formData }) => (
              <TotalSection
                formData={formData}
                vatCodes={vatCodes}
                productItems={productItems}
                changeValue={changeValue}
                isShowPriceInfo={this.state.isShowPriceInfo}
                isCustomer
              />
            )}
          </FormDataConsumer>
        }
      >
        <FormTab
          label="Current Order"
          tabIndex={-1}
          context={context}
          resource={resource}
          hidden={hidden}
          onChange={onChange}
          value={value}
          position={position}
        >
          <CurrentOrderItems
            isNewDesign
            formProps={formProps}
            productItems={productItems}
            vatCodes={vatCodes}
            changeValue={changeValue}
            isEdit={isEdit}
            addItemValidation={this.addItemValidation}
            permissions={permissions}
            checkMethodDifference={this.checkMethodDifference}
          />
        </FormTab>
        <FormTab
          label="Recent Orders"
          tabIndex={-1}
          context={context}
          resource={resource}
          hidden={hidden}
          onChange={onChange}
          value={value}
          position={position}
        >
          <>
            {!isEdit && !formValues.customerId ? (
              <Typography color="inherit" variant="subheading" className={classes.paragraphInfo}>
                Please choose the customer
              </Typography>
            ) : (
              <ReferenceManyField
                label=""
                reference="saleOrderItem"
                target="items"
                // display only 10 recent order according to requirements
                perPage={10}
                filter={{ customerId: formValues.customerId }}
                {...formProps}
              >
                <RecentOrderGrid {...formProps} customerId={formValues.customerId} isNewDesign />
              </ReferenceManyField>
            )}
          </>
        </FormTab>
      </Content>
    );
  }
}

OrderDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  productItems: PropTypes.objectOf(PropTypes.object),
  formValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool, PropTypes.object]),
  ),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  changeValue: PropTypes.func,
  crudGetAll: PropTypes.func,
  isEdit: PropTypes.bool,
  showNotification: PropTypes.func,
  permissions: PropTypes.string,
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state),
  productItems: state.admin.resources[resources.PRODUCT].data,
  vatCodes: state.admin.resources[resources.VATCODE].data,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      crudGetAll: crudGetAllAction,
      showNotification: showNotificationAction,
    },
    null,
  ),
  withStyles(styles),
);

/*
// user permissions within this component for better performance
const OrderDetailsWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <OrderDetails permissions={permissions} {...props} />} />
);
*/

export default enhance(OrderDetails);
