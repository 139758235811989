import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    position: 'relative',
  },
  noDataLabel: {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
  },
});

const Chart = ({ data, title, component: Component, classes, isEmptyData = false }) => (
  <Paper className={classes.paper}>
    {!!title && (
      <Typography variant="headline" gutterBottom>
        {title}
      </Typography>
    )}
    {isEmptyData && (
      <Typography variant="body1" gutterBottom align="center" className={classes.noDataLabel}>
        No data
      </Typography>
    )}
    <Component data={data} />
  </Paper>
);

Chart.propTypes = {
  title: PropTypes.string,
  isEmptyData: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  ),
  component: PropTypes.func.isRequired,
};

export default withStyles(styles)(Chart);
