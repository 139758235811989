import React from 'react';
import { Admin } from 'react-admin';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import theme from './theme';
import dataProvider from './provider/dataProvider';
import authProvider from './provider/authProvider';
import i18nProvider from './provider/i18nProvider';
import sagas from './sagas';
import AppLayout from './layout/AppLayout';
import { AuthPage } from './modules/auth';
import Dashboard from './modules/dashboard';
import DefaultTitle from './modules/common/ui/DefaultTitle';
import customRoutes from './customRoutes/index';
import customReducers from './customReducers';
import resources from './resources';
import { roles } from './constants/roles';
import { customHistory } from './history';

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Admin
      title={<DefaultTitle />}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={AuthPage}
      dashboard={Dashboard}
      appLayout={AppLayout}
      customRoutes={customRoutes}
      customReducers={{ customReducers }}
      customSagas={sagas}
      theme={theme}
      history={customHistory}
    >
      {permissions => ((permissions === roles.USER_ROLE || permissions === roles.STAFF_USER_ROLE) ? resources.userResources : resources.customerResources)}
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
