import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import {
  crudGetAll as crudGetAllAction,
  crudGetOne as crudGetOneAction,
  getResources,
  translate as translateGlobal,
  WithPermissions,
} from 'react-admin';
import classNames from 'classnames';
import { Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import * as appMenu from '../../constants/appMenu';
import * as routes from '../../constants/routes';
import MENU_ITEMS, { CUSTOMER_MENU_ITEMS, STAFF_MENU_ITEMS } from '../../constants/sidebar';
import resources from '../../constants/resources';
import { roles } from '../../constants/roles';
import historyShape from '../../modules/common/ui/historyShape';
import { withShowSidebar } from '../../hoc/WithShowSidebar';
import { NewDesignMenu } from './NewDesignMenu';
import { NewDesignMenuItem } from './NewDesignMenuItem';
import { ExitIcon } from './icons/Exit';
import { getCustomerId } from '../../provider/tokenUtils';
import { useNotificationsStore } from '../../stores/notifications';
import { useCustomerAccountStore } from '../../stores/customerAccount';

const routesShouldUpdateNotifications = [routes.SALES_ORDER_ROUTE, routes.PURCHASE_ORDER_ROUTE];

const styles = () => ({
  collapseClosed: {
    display: 'none',
  },

  collapseNewDesign: {
    paddingLeft: 0,
  },
  collapseNewDesignInner: {
    marginTop: 4,
    padding: 16,
    borderRadius: 8,
    border: '1px solid #BED9EE',
  },

  buttonBadge: {
    minWidth: 20,
    minHeight: 15,
    padding: 3,
  },
  menuLinkText: {
    fontSize: '1rem',
  },

  separator: {
    flexGrow: 1,
  },
});

class AppMenu extends Component {
  state = {
    toggler: {
      [appMenu.PURCHASE]: false,
      [appMenu.REPORT]: false,
      [appMenu.SALE]: false,
      [appMenu.SETUP]: false,
      [appMenu.PRICING]: false,
      [appMenu.MORE]: false,
      [appMenu.PRODUCTION]: false,
    },
  };

  componentDidMount() {
    const { pathname, history, crudGetAll, crudGetOne } = this.props;
    const currentOpenList = this.checkCurrentRoute(pathname);
    this.handleClick(currentOpenList);
    this.fetchNotifications();

    crudGetAll(resources.CURRENCY, { field: 'id', order: 'ASC' }, {}, 100);
    crudGetAll(resources.SUPPLIER_SELECTION, { field: 'id', order: 'ASC' }, {}, 100);

    if (getCustomerId()) {
      crudGetOne(resources.CUSTOMER, getCustomerId());
      useNotificationsStore.getState().getNotifications();
      useCustomerAccountStore.getState().getCustomerAccount();
    }

    history.listen(this.handleChangeRoute);
  }

  componentWillUnmount() {
    useNotificationsStore.getState().disconnectSocket();
  }

  componentDidUpdate() {
    const { pathname, messageResources, messageResourcesUser, translate, permissions } = this.props;

    const isUser = permissions === roles.USER_ROLE;

    const getCurrentRoute = resources =>
      Object.keys(resources).find(resource => pathname.toLowerCase().includes(resource.toLowerCase()));

    let title = translate('titles.defaultTitle');
    let currentRoute;

    if (isUser) {
      currentRoute = getCurrentRoute(messageResourcesUser);
    }

    if (currentRoute) {
      title = translate(`resourcesUser.${currentRoute}.name`, { smart_count: 2 });
    } else {
      currentRoute = getCurrentRoute(messageResources);

      if (currentRoute) {
        title = translate(`resources.${currentRoute}.name`, { smart_count: 2 });
      }
    }

    document.title = title;
  }

  fetchNotifications = () => {
    const { crudGetAll } = this.props;
    crudGetAll(resources.MENU_NOTIFICATION, { field: 'id', order: 'ASC' }, {}, 100);
  };

  handleChangeRoute = location => {
    if (routesShouldUpdateNotifications.includes(location.pathname)) {
      this.fetchNotifications();
    }
  };

  handleClick = value => {
    if (!value) {
      return;
    }
    this.setState(state => ({
      ...state,
      toggler: {
        ...state.toggler,
        [value]: !state.toggler[value],
      },
    }));
  };

  checkCurrentRoute = pathName => {
    const routesList = {
      [routes.PURCHASE_ORDER_ROUTE]: appMenu.PURCHASE,
      [routes.INTAKE_DOCKET_ROUTE]: appMenu.PURCHASE,
      [routes.PRODUCTION_ORDER_ROUTE]: appMenu.PRODUCTION,
      [routes.SALES_ORDER_ROUTE]: appMenu.SALE,
      [routes.DISPATCH_DOCKET_ROUTE]: appMenu.SALE,
      [routes.INVOICE_ROUTE]: appMenu.SALE,
      [routes.SALES_RETURN_ROUTE]: appMenu.SALE,
      [routes.CREDIT_NOTE_ROUTE]: appMenu.SALE,
      [routes.VAN_SALES_ROUTE]: appMenu.SALE,
      [routes.REPORT_ROUTE]: appMenu.REPORT,
      [routes.SUPPLIERS_ROUTE]: appMenu.SETUP,
      [routes.CUSTOMERS_ROUTE]: appMenu.SETUP,
      [routes.PRODUCTS_ROUTE]: appMenu.SETUP,
      [routes.BASE_PRICE_ROUTE]: appMenu.PRICING,
      [routes.PRICE_LISTS_ROUTE]: appMenu.PRICING,
      [routes.CUSTOMER_PRICES_ROUTE]: appMenu.PRICING,
    };

    const currentRoute = Object.keys(routesList).find(route => pathName === route);
    if (currentRoute) {
      return routesList[currentRoute];
    }
    return null;
  };

  countNotifications = links => {
    const { notificationsData } = this.props;

    return links.reduce((p, c) => p + ((notificationsData[c.key] || {}).value || 0), 0);
  };

  checkActiveMenuItem = links => {
    const { pathname } = this.props;

    if (!pathname || !isArray(links)) {
      return false;
    }

    return links.some(item => {
      const link = isString(item) ? item : item.link;

      return `/${pathname.split('/')[1]}`.toLowerCase() === link.toLowerCase();
    });
  };

  logOut = () => {
    this.props.dispatch({
      type: 'RA/USER_LOGOUT',
      payload: { redirectTo: undefined },
      meta: { auth: true },
    });
  };

  renderNewDesignMenuItems = (links, isSubItems = false) => {
    const { classes, open, notificationsData } = this.props;
    const { toggler } = this.state;

    return (
      <>
        {links.map(({ key, sublinks, ...rest }) =>
          sublinks ? (
            <Fragment key={key}>
              <NewDesignMenuItem
                showExpandIcon
                expanded={toggler[key]}
                onClick={() => this.handleClick(key)}
                notifications={toggler[key] ? undefined : this.countNotifications(sublinks)}
                isSubItem={isSubItems}
                isActive={this.checkActiveMenuItem(sublinks)}
                {...rest}
              />

              <Collapse
                in={toggler[key]}
                unmountOnExit
                className={classNames(classes.collapseNewDesign, { [classes.collapseClosed]: !open })}
              >
                <div className={classes.collapseNewDesignInner}>{this.renderNewDesignMenuItems(sublinks, true)}</div>
              </Collapse>
            </Fragment>
          ) : (
            <NewDesignMenuItem
              key={key}
              notifications={notificationsData[key] && notificationsData[key].value}
              isSubItem={isSubItems}
              isActive={this.checkActiveMenuItem([rest.link])}
              {...rest}
            />
          ),
        )}

        {!isSubItems && (
          <>
            <span className={classes.separator} />
            <NewDesignMenuItem isNewIcon key="logout" name="Log Out" icon={ExitIcon} onClick={this.logOut} />
          </>
        )}
      </>
    );
  };

  render() {
    const { permissions, showSidebar } = this.props;

    if (showSidebar) {
      let menu = CUSTOMER_MENU_ITEMS;
      if (permissions === roles.USER_ROLE) {
        menu = MENU_ITEMS;
      }else if (permissions === roles.STAFF_USER_ROLE)
      {
        menu = STAFF_MENU_ITEMS;
      }

      return <NewDesignMenu>{this.renderNewDesignMenuItems(menu)}</NewDesignMenu>;
    }

    return null;
  }
}

AppMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  pathname: PropTypes.string,
  open: PropTypes.bool,
  messageResources: PropTypes.objectOf(PropTypes.object),
  messageResourcesUser: PropTypes.objectOf(PropTypes.object),
  translate: PropTypes.func,
  notificationsData: PropTypes.objectOf(PropTypes.object),
  crudGetAll: PropTypes.func,
  permissions: PropTypes.string,
  history: historyShape,
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location.pathname, // used to force redraw on navigation
  messageResources: state.i18n.messages.resources,
  messageResourcesUser: state.i18n.messages.resourcesUser,
  notificationsData: state.admin.resources[resources.MENU_NOTIFICATION].data,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      crudGetAll: crudGetAllAction,
      crudGetOne: crudGetOneAction,
      dispatch,
    },
    dispatch,
  );

const enhance = compose(
  withRouter,
  withShowSidebar,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index], // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open &&
        prev.notificationsData === next.notificationsData,
    },
  ),
  translateGlobal,
  withStyles(styles),
);

const AppMenuWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <AppMenu permissions={permissions} {...props} />} />
);

export default enhance(AppMenuWithPermissions);
