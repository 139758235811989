import React from 'react';
import { Resource } from 'react-admin';

import commonResources from './common';
import resources from '../constants/resources';
import products from '../modules/products';
import customers from '../modules/customers';
import suppliers from '../modules/suppliers';
import purchaseOrder from '../modules/purchaseOrder';
import productionOrder from '../modules/productionOrder';
import productionTemplate from '../modules/productionTemplate';
import intakeDockets from '../modules/intakeDockets';
import invoices from '../modules/invoices';
import creditNote from '../modules/creditNote';
import vanSales from '../modules/vanSales';
import reports from '../modules/reports';
import reportData from '../modules/reportData';
import prices from '../modules/pricing/priceLists';

export default [
  <Resource name={resources.PRODUCT} {...products} />,
  <Resource name={resources.SUPPLIER} {...suppliers} />,
  <Resource name={resources.CUSTOMER} {...customers} />,
  <Resource name={resources.PURCHASE_ORDER} {...purchaseOrder} />,
  <Resource name={resources.PRODUCTION_ORDER} {...productionOrder} />,
  <Resource name={resources.PRODUCTION_TEMPLATE} {...productionTemplate} />,
  <Resource name={resources.INTAKE_DOCKET} {...intakeDockets} />,
  <Resource name={resources.INVOICE} {...invoices} />,
  <Resource name={resources.CREDIT_NOTE} {...creditNote} />,
  <Resource name={resources.VAN_SALES} {...vanSales} />,
  <Resource name={resources.REPORT} {...reports} />,
  <Resource name={resources.REPORT_DATA} {...reportData} />,
  <Resource name={resources.SUPPLIER_GROUP} />,
  <Resource name={resources.AGENT} />,
  <Resource name={resources.COUNTRY} />,
  <Resource name={resources.PRODUCT_GROUP} />,
  <Resource name={resources.PRODUCT_STATE} />,
  <Resource name={resources.WEIGHT_BAND} />,
  <Resource name={resources.DEPARTMENT} />,
  <Resource name={resources.GRADE_GROUP} />,
  <Resource name={resources.PRICES} list={prices} />,
  <Resource name={resources.PRICE_LIST_ITEM} />,
  <Resource name={resources.ADDRESSES} />,
  <Resource name={resources.PURCHASE_ORDER_ITEM} />,
  <Resource name={resources.SALES_ORDER_ITEM} />,
  <Resource name={resources.BEST_PRODUCT_SALES_STATISTICS} />,
  <Resource name={resources.SALES_PURCHASE_STATISTICS} />,
  <Resource name={resources.STOCKS_REALTIME_STATISTICS} />,
  <Resource name={resources.INTAKE_DOCKET_ITEM} />,
  <Resource name={resources.DASHBOARD_STATISTICS} />,
  <Resource name={resources.RETURN_ITEM} />,
  <Resource name={resources.SPIECES} />,
  <Resource name={resources.BOX} />,
  <Resource name={resources.PRICE_METHOD} />,
  <Resource name={resources.OTHER_METHOD} />,
  <Resource name={resources.TARE_METHOD} />,
  <Resource name={resources.DATE_FORWARD_METHOD} />,
  <Resource name={resources.STOCK_TYPE} />,
  <Resource name={resources.DISPATCH_METHOD} />,
  <Resource name={resources.BARCODE_TYPE} />,
  <Resource name={resources.HEAD_ON_OPTION} />,
  <Resource name={resources.INNER_LABEL_METHOD} />,
  <Resource name={resources.PCL_MODE} />,
  <Resource name={resources.ALLERGEN} />,
  <Resource name={resources.WARNING} />,
  <Resource name={resources.BATCH} />,
  <Resource name={resources.CREDIT_NOTE_ITEM} />,
  <Resource name={resources.PRODUCT_PRICE} />,
  ...commonResources,
];
