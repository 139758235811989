import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showNotification as showNotificationAction, userLogin as userLoginAction } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { clearTokens, getDBId } from '../../../provider/tokenUtils';
import { authLogin } from '../../../provider/authProvider';
import config from '../../../config';
import { customHistory } from '../../../history';
import { SUPPLIER_SELECTION_ROUTE } from '../../../constants/routes';
import { Redirect } from 'react-router-dom';

const delta = 1000;
// const loginIdleTime = 1000;

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey[100],
    zIndex: theme.zIndex.modal + 300,
    position: 'relative',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
});

let timer;

class InactiveScreen extends Component {
  state = {
    isIdle: false,
    isLoggedIn: true,
    isLoading: false,
    lastRole: null,
    lastUserId: null,
  };

  constructor() {
    super();

    this.login = this.login.bind(this);
    this.setLastActiveTime();
  }

  setTimerForIdle = () => {
    timer = setInterval(() => {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      if (!isLoggedIn) {
        this.setInactiveScreen();
        clearInterval(timer);
      }
    }, 1000);
  };

  setInactiveScreen = () => {
    const lastRole = localStorage.getItem('role');
    const lastUserId = localStorage.getItem('userId');

    clearTokens();
    localStorage.removeItem('isLoggedIn');
    this.setState({ isLoggedIn: false, isIdle: true, lastRole, lastUserId });
    // document.getElementById('root').style.display = 'none';
  };

  hideInactiveScreen = () => {
    const { lastRole, lastUserId } = this.state;
    const newRole = localStorage.getItem('role');
    const newUserId = localStorage.getItem('userId');

    localStorage.setItem('isLoggedIn', 'true');
    if (lastRole !== newRole || lastUserId !== newUserId) {
      // need to refresh App manually, to show new setup of routes/menuitems matched new role/user
      window.location.reload();
    } else {
      this.setState({ isLoggedIn: true, isIdle: false });
      document.getElementById('root').style.display = 'block';
    }
  };

  setLastActiveTime = () => {
    localStorage.setItem(
      'lastActiveTime',
      moment()
        .valueOf()
        .toString(),
    );
    localStorage.setItem('isLoggedIn', 'true');
  };

  onIdle = () => {
    const lastActiveTime = localStorage.getItem('lastActiveTime');
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const currentTime = moment().valueOf();

    if (!isLoggedIn) return;

    if (moment(+lastActiveTime).valueOf() > currentTime - config.idleTime + delta) {
      this.setTimerForIdle();
      return;
    }
    this.setInactiveScreen();
  };

  onAction = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) return;
    this.setLastActiveTime();
  };

  onActive = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      if (timer) clearInterval(timer);
      return;
    }
    this.setInactiveScreen();
  };

  // onLoginActive = () => {
  //   const isLoggedIn = localStorage.getItem('isLoggedIn');
  //   if (!isLoggedIn) return;
  //   this.hideInactiveScreen();
  // };

  async login(auth) {
    const { showNotification, dispatch } = this.props;
    this.setState({ isLoading: true });

    authLogin(auth)
      .then(() => {
        this.hideInactiveScreen();

        if (!getDBId()) {
          customHistory.replace(SUPPLIER_SELECTION_ROUTE);
        }
      })
      .catch(error => showNotification(error, 'warning'))
      .finally(() => {
        this.setState({ isLoading: false });
        dispatch({ type: 'RA/REFRESH_VIEW' });
      });
  }

  render() {
    const { isIdle, isLoggedIn } = this.state;

    return (
      <Fragment>
        <IdleTimer
          element={document.getElementById('root')}
          onIdle={this.onIdle}
          debounce={250}
          timeout={config.idleTime}
          onAction={this.onAction}
          onActive={this.onActive}
        />
        {/*<IdleTimer element={document.getElementById('portals')} timeout={loginIdleTime} onActive={this.onLoginActive} />*/}
        {isIdle && !isLoggedIn && <Redirect to="/login" />}
      </Fragment>
    );
  }
}

InactiveScreen.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  showNotification: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  userLogin: userLoginAction,
  showNotification: showNotificationAction,
  dispatch,
});

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
)(InactiveScreen);
