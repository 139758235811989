import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  DisabledInput,
  ArrayInput,
  BooleanInput,
  ReferenceField,
  TextField,
  FormDataConsumer,
  NumberInput,
  maxValue,
  REDUX_FORM_NAME,
  TextInput,
} from 'react-admin';
import { change } from 'redux-form';
import { Card, AppBar, Typography, Checkbox, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import resources from '../../constants/resources';
import TableFormIterator from './TableFormIterator';
import { getBasePrice } from '../../services/calcOrdersFunc';
import formats from '../../constants/formats';
import { ordersConstants } from '../../constants/ordersConstants';
import numberDigits from '../../constants/numberFormat';

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit * 2.5,
  },
  appBar: {
    padding: theme.spacing.unit * 1.6,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    textTransform: 'uppercase',
    marginLeft: theme.spacing.unit * 7,
  },
});

const significantDigits = (value = 0) => new Intl.NumberFormat(formats.LOCALES, numberDigits).format(value);
const TotalPriceInput = ({
  record: { priceMethod, pricePreDiscount, invoiceQuantity = 0, invoiceWeight = 0 },
  currency,
}) => {
  const invoiceTotal = getBasePrice(priceMethod, pricePreDiscount, invoiceQuantity, invoiceWeight);
  return (
    <DisabledInput
      label=""
      InputProps={{
        inputProps: { value: significantDigits(invoiceTotal) },
        startAdornment: currency && (
          <InputAdornment className="do-currency-sign" position="start">
            {currency}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
};
TotalPriceInput.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  currency: PropTypes.string,
};

const requestPrevCreditValueProps = ({ invoiceId, productId, returnItemId }) => ({
  type: 'GET_LIST',
  resource: resources.CREDIT_NOTE_ITEM,
  payload: {
    pagination: { page: 1, perPage: 1 },
    sort: { field: 'creditnotedate', order: 'DESC' },
    filter: { invoiceId, productId, returnItemId: returnItemId || 0 },
  },
});

class CreditNoteItem extends Component {
  changeEditableRowState = (item, getSource) => (e, data) => {
    const { changeValue } = this.props;
    if (data) {
      changeValue(getSource('quantity'), item.invoiceQuantity);
      changeValue(getSource('weight'), item.invoiceWeight);
      changeValue(getSource('totalExVat'), item.invoicedTotal);
    } else {
      changeValue(getSource('quantity'), 0);
      changeValue(getSource('weight'), 0);
      changeValue(getSource('totalExVat'), 0);
    }
  };

  changePriceRelatedValues = (item, getSource, sourceCurrentInput) => (e, data) => {
    const { changeValue } = this.props;
    const itemValues = {
      quantity: item.quantity,
      weight: item.weight,
      price: item.pricePostDiscount,
      [sourceCurrentInput]: data,
    };
    const totalPrice = getBasePrice(item.priceMethod, itemValues.price, itemValues.quantity, itemValues.weight);
    changeValue(getSource('totalExVat'), totalPrice);
  };

  onPrevCreditTotalReceived = (prevCreditTotal, getSource) => {
    const { changeValue } = this.props;
    changeValue(getSource('prevCreditedTotal'), prevCreditTotal);
  };

  renderPrevCreditTotal = (prevCreditTotal = 0, initialValue, getSource) => {
    if (prevCreditTotal !== initialValue) {
      this.onPrevCreditTotalReceived(prevCreditTotal, getSource);
    }
    return <span>{prevCreditTotal}</span>;
  };

  render() {
    const {
      classes,
      className,
      basePath,
      resource,
      record,
      record: { currencySymbol },
      isCreate,
      save,
      ...rest
    } = this.props;

    return (
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Typography className={classes.title} color="inherit">
            Credit Note
          </Typography>
        </AppBar>
        <ArrayInput basePath={basePath} label="" source="creditNoteItems" {...rest}>
          <TableFormIterator
            disableAdd
            disableRemove
            className={classes.table}
            tableRowDisable={!isCreate}
            requestQuery={requestPrevCreditValueProps}
          >
            <ReferenceField
              basePath={basePath}
              label="Product code"
              record={record}
              source="productId"
              resource={resource}
              reference={resources.PRODUCT}
              linkType={false}
            >
              <TextField source="productCode" />
            </ReferenceField>
            <ReferenceField
              basePath={basePath}
              label="Product"
              record={record}
              source="productId"
              resource={resource}
              reference={resources.PRODUCT}
            >
              <TextField source="description" />
            </ReferenceField>
            <DisabledInput
              {...(isCreate
                ? {
                    fromQuery: true,
                    querySource: 'quantity',
                  }
                : {})}
              source="prevCreditedQuantity"
              label="Prev Credited Quantity"
            />
            <DisabledInput
              {...(isCreate
                ? {
                    fromQuery: true,
                    querySource: 'weight',
                  }
                : {})}
              source="prevCreditedWeight"
              label="Prev Credited Weight"
            />
            <DisabledInput
              {...(isCreate
                ? {
                    fromQuery: true,
                    querySource: 'totalExVat',
                  }
                : {})}
              source="prevCreditedTotal"
              label="Prev Credited Total"
            />
            <DisabledInput
              source="invoiceQuantity"
              label="Invoiced Quantity"
              format={value => (value === null ? 0 : Number(value))}
            />
            <DisabledInput source="invoiceWeight" label="Invoiced Weight" format={significantDigits} />
            <DisabledInput
              source="pricePreDiscount"
              label="Invoice Price"
              format={significantDigits}
              InputProps={
                currencySymbol && {
                  startAdornment: (
                    <InputAdornment className="do-currency-sign" position="start">
                      {currencySymbol}
                    </InputAdornment>
                  ),
                }
              }
            />
            <FormDataConsumer label="Invoice Total">
              {({ getSource, scopedFormData }) =>
                isCreate ? (
                  <DisabledInput label="" source={getSource('invoicedTotal')} format={significantDigits} />
                ) : (
                  <TotalPriceInput
                    disabled
                    source={getSource('invoicedTotal')}
                    record={scopedFormData}
                    currency={currencySymbol || ''}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer label="Credit Quantity">
              {({ getSource, formData, scopedFormData }) => (
                <NumberInput
                  disabled={
                    !isCreate ||
                    !scopedFormData.selected ||
                    scopedFormData.priceMethod === ordersConstants.weightPriceMethod ||
                    scopedFormData.priceMethod === ordersConstants.typicalPriceWeightMethod
                  }
                  label=""
                  source={getSource('quantity')}
                  record={formData}
                  onChange={this.changePriceRelatedValues(scopedFormData, getSource, 'quantity')}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer label="Credit Weight">
              {({ getSource, formData, scopedFormData }) => (
                <NumberInput
                  disabled={
                    !isCreate || !scopedFormData.selected || scopedFormData.priceMethod === ordersConstants.quantityPriceMethod
                  }
                  label=""
                  source={getSource('weight')}
                  format={!scopedFormData.selected && significantDigits}
                  record={formData}
                  InputProps={{ inputProps: { step: '0.01' } }}
                  onChange={this.changePriceRelatedValues(scopedFormData, getSource, 'weight')}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer label="Credit Price">
              {({ getSource, formData, scopedFormData }) => (
                <NumberInput
                  label=""
                  disabled={!isCreate || !scopedFormData.selected}
                  source={getSource('pricePostDiscount')}
                  format={!scopedFormData.selected && significantDigits}
                  record={formData}
                  onChange={this.changePriceRelatedValues(scopedFormData, getSource, 'price')}
                  InputProps={{
                    inputProps: { step: '0.01' },
                    startAdornment: currencySymbol && (
                      <InputAdornment className="do-currency-sign" position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </FormDataConsumer>
            <FormDataConsumer label="Credit Total">
              {({ getSource, formData, scopedFormData }) => (
                <TextInput
                  disabled
                  label=""
                  source={getSource('totalExVat')}
                  format={significantDigits}
                  record={formData}
                  validate={
                    isCreate && scopedFormData.selected
                      ? maxValue(
                          scopedFormData.invoicedTotal - scopedFormData.prevCreditedTotal,
                          'credit note has return value greater than invoice value',
                        )
                      : null
                  }
                  InputProps={
                    currencySymbol && {
                      startAdornment: (
                        <InputAdornment className="do-currency-sign" position="start">
                          {currencySymbol}
                        </InputAdornment>
                      ),
                    }
                  }
                />
              )}
            </FormDataConsumer>
            {isCreate ? (
              <FormDataConsumer label="Select">
                {({ getSource, scopedFormData }) => (
                  <BooleanInput
                    label=""
                    source={getSource('selected')}
                    onChange={this.changeEditableRowState(scopedFormData, getSource)}
                  />
                )}
              </FormDataConsumer>
            ) : (
              <Checkbox label="Select" checked disabled />
            )}
          </TableFormIterator>
        </ArrayInput>
      </Card>
    );
  }
}

CreditNoteItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  isCreate: PropTypes.bool,
  currencySymbol: PropTypes.string,
  record: PropTypes.objectOf(PropTypes.any),
  save: PropTypes.func,
  changeValue: PropTypes.func,
};

const enhance = compose(
  connect(
    null,
    {
      changeValue: (source, value) => change(REDUX_FORM_NAME, source, value),
    },
  ),
  withStyles(styles),
);

export default enhance(CreditNoteItem);
