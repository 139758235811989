import moment from 'moment';

const convertUtcToLocal = val => {
  if (val) {
    const utcDate = new Date(val);
    return new Date(
      Date.UTC(
        utcDate.getFullYear(),
        utcDate.getMonth(),
        utcDate.getDate(),
        utcDate.getHours(),
        utcDate.getMinutes(),
        utcDate.getSeconds(),
      ),
    );
  }
  return val;
};

const formatDate = (val, format) => moment(val).format(format);

export { convertUtcToLocal, formatDate };
