import React from 'react';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import { UiSwitch } from '../ui/NewDesign/inputs/Switch';
import { UiBaseInput } from '../ui/NewDesign/inputs/BaseInput';

const renderTextField = ({ meta: { touched, error } = {}, input: inputProps, ...props }) => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

export const renderInput = ({ meta: { touched, error } = {}, input: inputProps, ...props }) => {
  return (
    <UiBaseInput
      component={TextField}
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      className={classnames(props.className, touched && error ? 'customError' : undefined)}
    />
  );
};

export const renderSwitch = ({ meta: { touched, error } = {}, input: inputProps, ...props }) => {
  return (
    <UiSwitch
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      className={classnames(props.className, touched && error ? 'customError' : undefined)}
    />
  );
};

export default renderTextField;
