import React from 'react';
import { Datagrid, DateField, EditButton, List, TextField } from 'react-admin';
import ListActionBar from '../common/ui/ListActionBar';
import Filter from './Filter';
import formats from '../../constants/formats';
import exporter from './exporter';

const ProductionOrderList = props => (
  <List {...props} filters={<Filter />} bulkActionButtons={null} actions={<ListActionBar />} exporter={exporter}>
    <Datagrid rowClick="edit">
      <TextField label="Production Order" source="id" />
      <TextField label="Template Name" source="description" />
      <DateField label="Due Date" source="dueDate" locales={formats.LOCALES} showTime />
      <TextField label="Status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ProductionOrderList;
