import React from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  formClassName,
  headerClassName,
  label,
  linkType,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  source,
  textAlign,
  translateChoice,
  ...rest
}) => rest;

const styles = theme => ({
  checkbox: {
    marginTop: theme.spacing.unit * 3.5,
  },
  naLabel: {
    color: theme.palette.grey[500],
  },
});

const DOTextField = ({ classes, className, source, label, record = {}, ...rest }) => {
  const value = get(record, source);
  const isValueValid = typeof value === 'number' || (typeof value === 'string' && value.length !== 0);
  return (
    <Typography component="span" body1="body1" className={className} {...sanitizeRestProps(rest)}>
      {isValueValid ? value : <span className={classes.naLabel}>N/A</span>}
    </Typography>
  );
};

DOTextField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.instanceOf(Object),
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(DOTextField);
