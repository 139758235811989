import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  ReferenceManyFieldController,
  getReferenceResource,
  nameRelatedTo,
  BooleanField,
  getIds,
  FunctionField,
} from 'react-admin';
import { Card, AppBar, Tabs, Tab, CircularProgress, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import resources from '../../constants/resources';
import { PRODUCTS_ROUTE } from '../../constants/routes';
import { getBasePrice } from '../../services/calcOrdersFunc';
import numberDigits from '../../constants/numberFormat';

const getDataByIds = (intakeDocketItemStore, ids) =>
  Object.keys(intakeDocketItemStore)
    .filter(i => ids.includes(+i))
    .reduce((p, c) => ({ ...p, [c]: intakeDocketItemStore[c] }), {});

const dataForCurrentItem = intakeDocketItem =>
  Object.values(intakeDocketItem).reduce((p, c) => {
    const dataByProduct = p[c.productId] || {
      id: c.productId,
      price: c.pricePerUnit,
      totalPrice: 0,
      intakeWeight: 0,
      intakeQuantity: 0,
      weightOutstanding: 0,
      orderFilled: !!c.orderItemFilled,
      options: null /* customer comment: just ignore it at the moment. */,
    };
    const totalPrice = getBasePrice(c.priceMethod, c.pricePerUnit, c.intakeQuantity, c.intakeWeight);
    dataByProduct.totalPrice += totalPrice;
    dataByProduct.intakeWeight += c.intakeWeight;
    dataByProduct.intakeQuantity += c.intakeQuantity;
    const weightOutstanding = c.orderItemWeight - c.orderItemWeightReceived;
    dataByProduct.weightOutstanding = weightOutstanding > 0 ? weightOutstanding : 0;
    return {
      ...p,
      [c.productId]: dataByProduct,
    };
  }, {});

const styles = theme => ({
  tableWrapper: {
    marginBottom: theme.spacing.unit * 2.5,
  },
  productDescription: {
    display: 'inline-block',
  },
  progress: {
    margin: theme.spacing.unit * 4,
  },
});

class IntakeDocketItemTabs extends Component {
  state = {
    currentTab: 0,
    intakeDocketItemIds: [],
    idsCurrentItem: [],
    currentIntakeDocketItem: null,
    intakeDocketItem: null,
  };

  componentDidUpdate(prevProps) {
    const { intakeDocketItemStore, ids } = this.props;
    if (prevProps.intakeDocketItemStore.fetchedAt === intakeDocketItemStore.fetchedAt) return;
    const intakeDocketItem = getDataByIds(intakeDocketItemStore, ids);
    const currentIntakeDocketItem = dataForCurrentItem(intakeDocketItem);

    const intakeDocketItemIds = Object.keys(intakeDocketItem);
    const idsCurrentItem = Object.keys(currentIntakeDocketItem);
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      currentIntakeDocketItem,
      intakeDocketItemIds,
      idsCurrentItem,
      intakeDocketItem,
    });
  }

  handleChange = (event, value) => {
    const { intakeDocketItemStore, ids } = this.props;
    const intakeDocketItem = getDataByIds(intakeDocketItemStore, ids);
    const intakeDocketItemIds = Object.keys(intakeDocketItem);
    this.setState({ intakeDocketItemIds, intakeDocketItem, currentTab: value });
  };

  filterCurrentItem = id => {
    const { intakeDocketItem } = this.state;
    const selectedItem = Object.values(intakeDocketItem)
      .filter(item => item.productId === +id)
      .reduce((p, c) => ({ ...p, [c.id]: c }), {});
    const intakeDocketItemIds = Object.keys(selectedItem);
    this.setState({ intakeDocketItem: selectedItem, intakeDocketItemIds, currentTab: 1 });
  };

  render() {
    const { currentTab, idsCurrentItem, currentIntakeDocketItem, intakeDocketItemIds, intakeDocketItem } = this.state;
    const { classes, currencyId, currencyData, ...rest } = this.props;
    const currency = currencyId && get(currencyData, [currencyId, 'symbol'], null);

    return (
      <Card>
        <AppBar position="static" color="default">
          <Tabs value={currentTab} onChange={this.handleChange} scrollable scrollButtons="on">
            <Tab label="Current Docket" />
            <Tab label="Docket Items" />
          </Tabs>
        </AppBar>
        <ReferenceManyFieldController
          reference={resources.INTAKE_DOCKET_ITEM}
          target="intakeId"
          perPage={100}
          {...rest}
        >
          {props => (
            <div className={classes.tableWrapper}>
              {currentTab === 0 &&
                // eslint-disable-next-line no-nested-ternary
                (currentIntakeDocketItem ? (
                  idsCurrentItem.length ? (
                    <Datagrid
                      basePath={props.referenceBasePath}
                      ids={idsCurrentItem}
                      data={currentIntakeDocketItem}
                      currentSort={props.currentSort}
                      rowClick={this.filterCurrentItem}
                    >
                      <ReferenceField
                        label="Product Code"
                        basePath={PRODUCTS_ROUTE}
                        source="id"
                        reference={resources.PRODUCT}
                        sortable={false}
                        linkType={false}
                      >
                        <TextField source="productCode" className={classes.productDescription} />
                      </ReferenceField>
                      <ReferenceField
                        label="Product"
                        basePath={PRODUCTS_ROUTE}
                        source="id"
                        reference={resources.PRODUCT}
                        sortable={false}
                        linkType="show"
                      >
                        <TextField source="description" className={classes.productDescription} />
                      </ReferenceField>
                      <NumberField
                        source="weightOutstanding"
                        label="Weight Outstanding"
                        options={numberDigits}
                        sortable={false}
                      />
                      <NumberField
                        source="intakeQuantity"
                        label="Intake Quantity"
                        sortable={false}
                        options={numberDigits}
                      />
                      <NumberField
                        source="intakeWeight"
                        label="Intake Weight"
                        sortable={false}
                        options={numberDigits}
                      />
                      <FunctionField label="Price" render={record => `${record.price} ${currency || ''}`} />
                      <FunctionField label="Total Price" render={record => `${record.totalPrice} ${currency || ''}`} />
                      <BooleanField source="orderFilled" textAlign="right" label="Order Filled" sortable={false} />
                    </Datagrid>
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">Product Code</TableCell>
                          <TableCell padding="checkbox">Product</TableCell>
                          <TableCell padding="checkbox">Weight Outstanding</TableCell>
                          <TableCell padding="checkbox">Intake Quantity</TableCell>
                          <TableCell padding="checkbox">Intake Weight</TableCell>
                          <TableCell padding="checkbox">Price</TableCell>
                          <TableCell padding="checkbox">Total Price</TableCell>
                          <TableCell padding="checkbox">Order Filled</TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  )
                ) : (
                  <CircularProgress className={classes.progress} />
                ))}
              {currentTab === 1 && (
                <Datagrid
                  basePath={props.referenceBasePath}
                  ids={intakeDocketItemIds}
                  data={intakeDocketItem}
                  currentSort={props.currentSort}
                >
                  <NumberField
                    source="id"
                    label="Intake Item ID"
                    textAlign="left"
                    sortable={false}
                    options={{ useGrouping: false }}
                  />
                  <NumberField
                    source="stockId"
                    label="Label"
                    textAlign="left"
                    options={{ useGrouping: false }}
                    sortable={false}
                  />
                  <ReferenceField
                    label="Product"
                    basePath={PRODUCTS_ROUTE}
                    source="productId"
                    reference={resources.PRODUCT}
                    linkType={false}
                    sortable={false}
                  >
                    <TextField source="description" className={classes.productDescription} />
                  </ReferenceField>
                  <NumberField source="intakeBatch" label="Batch" sortable={false} />
                  <NumberField
                    source="intakeQuantity"
                    label="Intake Quantity"
                    sortable={false}
                    options={numberDigits}
                  />
                  <NumberField
                    source="intakeWeight"
                    label="Intake Weight"
                    textAlign="right"
                    sortable={false}
                    options={numberDigits}
                  />
                </Datagrid>
              )}
            </div>
          )}
        </ReferenceManyFieldController>
      </Card>
    );
  }
}
IntakeDocketItemTabs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  intakeDocketItemStore: PropTypes.objectOf(PropTypes.object),
  ids: PropTypes.arrayOf(PropTypes.number),
  currencyId: PropTypes.number,
  currencyData: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, props) => {
  const relatedTo = nameRelatedTo(
    resources.INTAKE_DOCKET_ITEM, // reference
    props.record.id, // source
    props.resource, // resource,
    'intakeId', // target
    {}, // filter
  );
  const { purchaseOrderId } = props.record;
  return {
    intakeDocketItemStore: getReferenceResource(state, { reference: resources.INTAKE_DOCKET_ITEM }).data,
    ids: getIds(state, relatedTo),
    currencyId: purchaseOrderId && (state.admin.resources.purchaseOrder.data[purchaseOrderId] || {}).currencyId,
    currencyData: state.admin.resources[resources.CURRENCY].data,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(IntakeDocketItemTabs);
