import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { showNotification as showNotificationAction } from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { parse } from 'query-string';

import { downloadFile } from '../common/utils';
import { fetchReportData } from '../../services/reports';

const styles = {
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

class LinkDownloadPDF extends Component {
  state = {
    isLoading: false,
  };

  handleClick = () => {
    const {
      record: { id },
      showNotification,
      search,
      isRecordId,
    } = this.props;
    this.setState({ isLoading: true });

    const { reportType } = JSON.parse(parse(search).filter || '{}') || {};

    fetchReportData({
      id: isRecordId ? reportType : id,
      criteria: {},
      recordId: isRecordId && id,
    })
      .then(response => {
        if (response.ok) {
          response.blob().then(blob => {
            downloadFile(blob, { type: 'application/pdf' });
          });
        } else {
          showNotification(response.statusText, 'warning');
        }
      })
      .catch(e => showNotification(`${e.name} ${e.status}`, 'warning'))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    return (
      <Button size="small" color="primary" className={classes.link} onClick={this.handleClick} disabled={isLoading}>
        {isLoading ? <CircularProgress size={20} /> : 'Open'}
      </Button>
    );
  }
}

LinkDownloadPDF.propTypes = {
  isRecordId: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  record: PropTypes.instanceOf(Object),
  search: PropTypes.string,
  showNotification: PropTypes.func,
};

export default compose(
  connect(
    null,
    {
      showNotification: showNotificationAction,
    },
  ),
  withStyles(styles),
)(LinkDownloadPDF);
