import React from 'react';
import { List, Datagrid, TextField} from 'react-admin';
import ListActionBar from '../common/ui/ListActionBar';
import EditButton from '../common/ui/EditButton';

const UserList = props => (
  //<List {...props} bulkActionButtons={null} exporter={false} actions={null} sort={{ field: 'name', order: 'ASC' }}>
  <List {...props} actions={<ListActionBar/>} sort={{field: 'fullName', order: 'ASC' }}>
    <Datagrid rowClick="edit">
    <TextField label="ID" source="id" />
    <TextField label="FullName" source="fullName" />
    <TextField label="DepartmentId" source="departmentId" />
     <EditButton />
    </Datagrid>
  </List>
);

export default UserList;