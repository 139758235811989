import React from 'react';
import PropTypes from 'prop-types';
import { DateField, NumberField, ReferenceField, TextField, WithPermissions } from 'react-admin';
import { Clear as FalseIcon, Done as TrueIcon } from '@material-ui/icons';
import { SendReports } from '../common/ui/SendReports';
import resources from '../../constants/resources';
import formats from '../../constants/formats';
import exporter from './exporter';
import numberDigits from '../../constants/numberFormat';
import { roles } from '../../constants/roles';
import { UiList } from '../common/ui/NewDesign/List';
import { NewDesignFilter } from './NewDesignFilter';
import { UiTable } from '../common/ui/NewDesign/Table';
import { CustomerTableCell } from '../common/tableCells/Customer';
import { useMediaQuery } from '../../utils/hooks/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  table: {
    '& .deliveryAddress': {
      width: 276,

      '@media (max-width: 1440px)': {
        width: 174,
      },
    },
  },
});

const BooleanFieldIcon = ({ source, record = {} }) => (record[source] ? <TrueIcon /> : <FalseIcon />);

BooleanFieldIcon.propTypes = {
  record: PropTypes.instanceOf(Object),
  source: PropTypes.string,
};

const DispatchDocketList = ({ permissions, classes, ...props }) => {
  const hideDeliveryField = useMediaQuery('(max-width: 1024px)');
  const isUser = permissions === roles.USER_ROLE;

  return (
    <UiList
      classNameTable={classes.table}
      title="Dispatch"
      {...props}
      filters={<NewDesignFilter />}
      bulkActionButtons={<SendReports url={resources.SEND_DISPATCH_DOCKET_REPORTS} />}
      exporter={exporter}
      sort={{ field: 'customerId', order: 'DESC' }}
      key={hideDeliveryField}
    >
      {tableProps => (
        <UiTable rowClick="edit" showEditAction {...tableProps}>
          {/*<TextField label="ID" source="id" />*/}
          {/*<NumberField label="Dispatch Number" source="id" options={{ useGrouping: false }} />*/}

          <CustomerTableCell permissions={permissions} label="Customer" sortBy="customerId" />
          <NumberField label="Sale Order" source="salesId" options={{ useGrouping: false }} />
          <ReferenceField
            sortBy="poNumber"
            label="PO Number"
            source="salesId"
            reference={resources.SALE_ORDER}
            linkType={false}
            allowEmpty
          >
            <TextField source="purchaseOrderNumber" />
          </ReferenceField>
          <BooleanFieldIcon label="Order Complete" source="orderComplete" />
          {isUser && <BooleanFieldIcon label="Invoiced" source="invoiced" />}
          {!hideDeliveryField && (
            <ReferenceField
              label="Delivery Address"
              source="salesId"
              reference={resources.SALE_ORDER}
              linkType={false}
              allowEmpty
              className="deliveryAddress"
            >
              <TextField source="deliveryAddress" />
            </ReferenceField>
          )}
          <ReferenceField
            sortBy="total"
            label="Total"
            source="salesId"
            reference={resources.SALE_ORDER}
            linkType={false}
            allowEmpty
          >
            <NumberField source="netTotal" options={numberDigits} />
          </ReferenceField>
          <ReferenceField
            label="Order Date"
            source="salesId"
            linkType={false}
            reference={resources.SALE_ORDER}
            allowEmpty
          >
            <DateField
              source="timeDate"
              locales={formats.LOCALES}
              options={{ year: 'numeric', month: 'short', day: 'numeric' }}
            />
          </ReferenceField>

          {/*<ReferenceField*/}
          {/*  sortBy="currencyRate"*/}
          {/*  label="Currency Rate"*/}
          {/*  source="salesId"*/}
          {/*  linkType={false}*/}
          {/*  reference={resources.SALE_ORDER}*/}
          {/*  allowEmpty*/}
          {/*>*/}
          {/*  <NumberField source="currencyRate" options={numberDigits} />*/}
          {/*</ReferenceField>*/}
        </UiTable>
      )}
    </UiList>
  );
};

DispatchDocketList.propTypes = {
  permissions: PropTypes.string,
};

const DispatchDocketListWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <DispatchDocketList permissions={permissions} {...props} />} />
);

export default withStyles(styles)(DispatchDocketListWithPermissions);
