import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Card, List, ListItem, ListItemText } from '@material-ui/core/';
import classnames from 'classnames';
import Search from './Search';

const styles = theme => ({
  card: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing.unit * 1.5,
  },
  list: {
    maxHeight: 500,
    position: 'relative',
    overflow: 'auto',
  },
  active: {
    backgroundColor: theme.palette.grey[300],
  },
});

const CustomerList = ({ classes, customerList, searchCustomer, setActiveCustomer, activeCustomer }) => (
  <Card className={classes.card}>
    <Search searchCustomer={searchCustomer} />
    <List>
      {customerList.map(({ id, companyName }) => (
        <ListItem
          key={id}
          button
          className={classnames({ [classes.active]: id === activeCustomer.id })}
          onClick={() => {
            setActiveCustomer(id);
          }}
        >
          <ListItemText primary={`${companyName}`} />
        </ListItem>
      ))}
    </List>
  </Card>
);

CustomerList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  customerList: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchCustomer: PropTypes.func.isRequired,
  setActiveCustomer: PropTypes.func.isRequired,
  activeCustomer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
    customerId: PropTypes.number.isRequired,
  }),
};

export default withStyles(styles)(CustomerList);
