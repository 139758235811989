import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { WithPermissions } from 'react-admin';

import * as routes from '../constants/routes';
import { roles } from '../constants/roles';
import HelpAboutPage from '../modules/helpAbout/HelpAboutPage';
//import vanSales2 from '../modules/vanSales';
import CustomerPrices from '../modules/pricing/customerPrices';
import BasePriceFormTable from '../modules/pricing/basePrice';
import Profile from '../modules/profile';
import { SupplierSelection } from '../modules/supplierSelection/index';

const RouteWithPermissions = (AppRoute, isAllRoles = false) => props => (
  <WithPermissions
    render={({ permissions }) => {
      if (!permissions) return null;

      const allowedRoles = [roles.USER_ROLE];
      if (isAllRoles) {
        allowedRoles.push(roles.CUSTOMER_ROLE);
        allowedRoles.push(roles.STAFF_USER_ROLE);
      }

      return allowedRoles.includes(permissions) ? <AppRoute {...props} /> : <Redirect to="/" />;
    }}
  />
);

export default [
  <Route exact path={routes.HELP_ROUTE} component={HelpAboutPage} />,
  <Route exact path={routes.BASE_PRICE_ROUTE} component={RouteWithPermissions(BasePriceFormTable)} />,
  <Route path={`${routes.CUSTOMER_PRICES_ROUTE}/:id?`} component={RouteWithPermissions(CustomerPrices)} />,
  <Route path={routes.SUPPLIER_SELECTION_ROUTE} component={RouteWithPermissions(SupplierSelection, true)} />,
  <Route exact path={routes.PROFILE} component={Profile} />,
  //<Route exact path={routes.VAN_SALES_ROUTE} component={RouteWithUserPermissions(vanSales2)} />,
];
