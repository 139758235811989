import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import * as PropTypes from 'prop-types';
import randomColor from 'randomcolor';
import { blue, amber, deepPurple, red, indigo } from '@material-ui/core/colors';

import Chart from './Chart';

class DoughnutChart extends Component {
  shouldComponentUpdate(nextProps) {
    const { ids, data } = this.props;
    return ids !== nextProps.ids || data !== nextProps.data;
  }

  getChartData() {
    const labels = [];
    const chartData = [];
    const { data, ids } = this.props;
    const backgroundColor = [
      blue[400],
      amber[500],
      deepPurple[500],
      red[400],
      indigo[500],
      ...randomColor({ luminosity: 'bright', seed: 'DoughnutChart', count: ids.length - 5 }),
    ];

    ids.forEach(id => {
      labels.push(data[id].name);
      chartData.push(data[id].count);
    });

    return {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor,
        },
      ],
    };
  }

  render() {
    const { ids } = this.props;
    return (
      <Chart
        data={this.getChartData()}
        component={Doughnut}
        title="Most Sold Products"
        isEmptyData={!ids.length}
      />
    );
  }
}

DoughnutChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
  ids: PropTypes.arrayOf(PropTypes.number),
};

export default DoughnutChart;
