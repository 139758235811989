import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateInput } from 'react-admin-date-inputs';
import { ReferenceInput, ReferenceField, SelectInput, DisabledInput, Edit, Button, Toolbar } from 'react-admin';
import { AttachMoney } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

import SubReferenceField from '../common/form/SubReferenceField';
import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import { convertUtcToLocal } from '../../services/date';
import DOTextField from '../common/ui/DOTextField';
import resources from '../../constants/resources';
import FormWrapper from '../common/ui/FormWrapper';
import ProductList from './ProductList';
import { CREDIT_NOTE_ROUTE } from '../../constants/routes';
import formats from '../../constants/formats';

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const EditToolbar = connect(mapStateToProps)(({ record, isLoading, ...rest }) => (
  <Toolbar {...rest}>
    <Fragment>
      <Button
        component={Link}
        variant="raised"
        size="medium"
        color="secondary"
        disabled={isLoading}
        label="Create a Credit Note"
        to={{
          pathname: `${CREDIT_NOTE_ROUTE}/create`,
          state: { invoiceId: record.id },
        }}
      >
        {isLoading ? <CircularProgress color="secondary" size={25} thickness={2} /> : <AttachMoney />}
      </Button>
    </Fragment>
  </Toolbar>
));

const AddressInput = ({ record }) => <span>{!record.deliveryAddressId ? 'Default address' : ''}</span>;

AddressInput.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

EditToolbar.propTypes = {
  record: PropTypes.instanceOf(Object),
};

const Form = props => (
  <DOForm {...props}>
    <Content position="main">
      <ReferenceInput source="customerId" label="Customer code" reference={resources.CUSTOMER}>
        <SelectInput disabled optionText="customerCode" />
      </ReferenceInput>
      <ReferenceInput source="customerId" label="Customer name" reference={resources.CUSTOMER}>
        <SelectInput disabled optionText="companyName" />
      </ReferenceInput>
      <ReferenceInput source="saleOrderId" reference={resources.SALE_ORDER} label="PO Number">
        <SelectInput disabled optionText="purchaseOrderNumber" />
      </ReferenceInput>
    </Content>

    <Content position="side">
      <FormTab label="Address" tabIndex={-1}>
        <ReferenceInput source="saleOrderId" reference={resources.SALE_ORDER} label="Select address" xs={12}>
          <SelectInput disabled source="deliveryAddressId" optionText={<AddressInput />} />
        </ReferenceInput>

        <ReferenceInput source="saleOrderId" reference={resources.SALE_ORDER} label="Address" xs={12}>
          <SelectInput disabled optionText="deliveryAddress" multiline />
        </ReferenceInput>

        <ReferenceField
          source="saleOrderId"
          linkType={false}
          reference={resources.SALE_ORDER}
          label="Route"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <SubReferenceField source="routeId" reference={resources.ROUTE}>
            <DOTextField source="name" />
          </SubReferenceField>
        </ReferenceField>

        <ReferenceField
          source="saleOrderId"
          linkType={false}
          reference={resources.SALE_ORDER}
          label="Haulier"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <SubReferenceField source="haulierId" reference={resources.HAULIER}>
            <DOTextField source="name" />
          </SubReferenceField>
        </ReferenceField>
      </FormTab>

      <FormTab label="Sale Details" tabIndex={-1}>
        <ReferenceInput source="saleOrderId" reference={resources.SALE_ORDER} label="Order No">
          <SelectInput disabled optionText="id" />
        </ReferenceInput>
        <DateInput
          source="timeDate"
          label="Sale Date"
          format={convertUtcToLocal}
          options={{ format: formats.DATE, disabled: true }}
        />
        <DisabledInput source="saleOrderUserName" label="Sales Person" />

        <ReferenceInput source="saleOrderId" reference={resources.SALE_ORDER} label="Currency">
          <SelectInput disabled optionText="currency.description" />
        </ReferenceInput>

        <DisabledInput source="dispatchId" label="Dispatch No" />
        <ReferenceInput source="saleOrderId" reference={resources.SALE_ORDER} label="Dispatch Date">
          <SelectInput
            disabled
            optionText={item => item.deliveryDate && moment(item.deliveryDate).format(formats.MOMENT)}
          />
        </ReferenceInput>

        <ReferenceInput source="userId" reference={resources.USER} label="Dispatcher">
          <SelectInput disabled optionText="fullName" />
        </ReferenceInput>
      </FormTab>

      <FormTab label="Dispatch Notes" tabIndex={-1}>
        <DisabledInput disabled source="notes" multiline rows="5" xs={12} />
      </FormTab>

      <FormTab label="Popup Notes" tabIndex={-1}>
        <DisabledInput multiline rows="5" source="popupNotes" optionText="popupNotes" label="Popup Notes" xs={12} />
      </FormTab>

      <FormTab label="Contact details" tabIndex={-1}>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Contact">
          <SelectInput disabled optionText="contactName" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Phone">
          <SelectInput disabled optionText="phone" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Phone Other">
          <SelectInput disabled optionText="phoneOther" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Fax">
          <SelectInput disabled optionText="fax" />
        </ReferenceInput>
        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Email">
          <SelectInput disabled optionText="email" />
        </ReferenceInput>

        <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Accounts Contacts">
          <SelectInput disabled optionText="secondaryContact" />
        </ReferenceInput>
      </FormTab>
    </Content>
    <Content position="bottom">
      <ProductList {...props} />
    </Content>
  </DOForm>
);

const InvoicesEdit = props => (
  <FormWrapper>
    <Edit {...props}>
      <Form toolbar={<EditToolbar />} />
    </Edit>
  </FormWrapper>
);

export default InvoicesEdit;
