import React, { useMemo } from 'react';
import { bool, func, instanceOf, number, object, objectOf, string } from 'prop-types';
import { FormDataConsumer, maxLength, ReferenceInput, required, SelectInput } from 'react-admin';

import { FormTab } from '../../../../common/ui/DOForm';
import resources from '../../../../../constants/resources';
import { roles } from '../../../../../constants/roles';
import { useCustomerAccountStore } from '../../../../../stores/customerAccount';
import { CircularProgress } from '@material-ui/core';
import { getCustomerId } from '../../../../../provider/tokenUtils';
import { UiTextarea } from '../../../../common/ui/NewDesign/inputs/Textarea';

const AddressDropdown = ({ items, onChange, disabled, ...other }) => (
  <FormDataConsumer {...other}>
    {() => (
      <SelectInput
        fullWidth
        optionValue="id"
        optionText="name"
        source="deliveryAddressId"
        label="Select address"
        disabled={disabled}
        choices={items}
        onChange={onChange}
        validate={required()}
      />
    )}
  </FormDataConsumer>
);

const Address = ({
  changeValue,
  customers,
  record = {},
  context,
  resource,
  hidden,
  onChange,
  value,
  position,
  permissions,
}) => {
  const { dispatched } = record;

  const { isLoading, model } = useCustomerAccountStore();

  const items = useMemo(() => {
    const addresses = [];

    const customerDeliveryAddress = (customers[getCustomerId()] && customers[getCustomerId()].deliveryAddress) || 'asdf';
    const initialAddress = record.deliveryAddressId ? record.deliveryAddress : undefined;

    if (initialAddress || customerDeliveryAddress) {
      addresses.push({
        id: 0,
        name: initialAddress || customerDeliveryAddress,
      });
    }

    if (model && model.deliveryAddresses.length) {
      addresses.push(...model.deliveryAddresses.map(item => ({ id: item.id, name: item.address })));
    }

    return addresses;
  }, [model]);

  const handleChangeDeliveryAddress = (e, id) => {
    if (id) {
      const foundAddress = items.find(item => item.id === id);

      if (~foundAddress) {
        changeValue('deliveryAddress', foundAddress.name);
      }
    }
  };

  if (isLoading || !permissions) {
    return (
      <FormTab
        label="Address"
        tabIndex={-1}
        context={context}
        resource={resource}
        hidden={hidden}
        onChange={onChange}
        value={value}
        position={position}
        permissions={permissions}
      >
        <CircularProgress />
      </FormTab>
    );
  }

  const disabled = !!dispatched;

  const isUser = permissions === roles.USER_ROLE;

  return (
    <FormTab
      isSimpleInputs
      label="Address"
      tabIndex={-1}
      context={context}
      resource={resource}
      hidden={hidden}
      onChange={onChange}
      value={value}
      position={position}
      permissions={permissions}
    >
      {!!model && model.deliveryAddresses.length ? (
        <AddressDropdown xs={12} disabled={disabled} items={items} onChange={handleChangeDeliveryAddress} />
      ) : (
        <UiTextarea
          multiline
          rows="5"
          label="Address"
          source="deliveryAddress"
          disabled={!!dispatched}
          InputProps={{ inputProps: { tabIndex: -1 } }}
          validate={[maxLength(254), required()]}
          xs={12}
        />
      )}

      {isUser && (
        <ReferenceInput
          label="Route"
          source="routeId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <SelectInput optionText="name" disabled={!!dispatched} />
        </ReferenceInput>
      )}

      {isUser && (
        <ReferenceInput
          source="haulierId"
          reference={resources.HAULIER}
          label="Haulier"
          allowEmpty
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <SelectInput optionText="name" disabled={!!dispatched} />
        </ReferenceInput>
      )}
    </FormTab>
  );
};

export default Address;

Address.propTypes = {
  isEdit: bool,
  changeValue: func,
  customers: objectOf(object),
  record: instanceOf(Object),
  resource: string,
  context: string,
  hidden: bool,
  onChange: func,
  value: number,
  position: string,
  permissions: string,
};
