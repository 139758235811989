import React from 'react';
import { Filter, NumberInput, TextInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import formats from '../../constants/formats';

export default props => (
  <Filter {...props}>
    <TextInput label="Production Order Search" source="search" alwaysOn />
    <NumberInput label="Production Order# From" source="productionOrderFrom" alwaysOn />
    <NumberInput label="Production Order# To" source="productionOrderTo" alwaysOn />
    <TextInput label="Template Name" source="Description" alwaysOn />
    <DateInput
      label="Due Date From"
      source="dueDateFrom"
      alwaysOn
      isRequired={false}
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
    />
    <DateInput
      label="Due Date To"
      source="dueDateTo"
      alwaysOn
      isRequired={false}
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
    />
  </Filter>
);
