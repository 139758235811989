import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, submit, isSubmitting, reset } from 'redux-form';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  CREATE,
  DELETE,
  REDUX_FORM_NAME,
  crudGetList,
  setListSelectedIds,
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import historyShape from '../../../common/ui/historyShape';
import resources from '../../../../constants/resources';
import { PRICE_LISTS_ROUTE } from '../../../../constants/routes';
import dataProvider from '../../../../provider/dataProvider';
import CreateDialog from './CreateDialog';
import DeleteDialog from './DeleteDialog';

const formName = 'PRICE_LIST_CREATE';
const styles = theme => ({
  wrap: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginRight: theme.spacing.unit * 2,
  },
});

const PriceListActions = ({
  handleChange,
  handleReset,
  handleFetchEnd,
  handleFetchStart,
  handleCrudGetList,
  handleShowNotification,
  onSubmit,
  saving,
  page,
  perPage,
  priceLists,
  classes,
  selectedIds,
  setSelectedIds,
  history: { replace },
}) => {
  const [showCreateDialog, changeShowCreateDialog] = useState(false);
  const [showDeleteDialog, changeShowDeleteDialog] = useState(false);

  const handleHideCreateDialog = () => changeShowCreateDialog(false);
  const handleHideDeleteDialog = () => changeShowDeleteDialog(false);

  const handleSubmit = values => {
    handleFetchStart();

    dataProvider(CREATE, resources.PRICES, { data: values })
      .then(({ data }) => {
        handleCrudGetList(resources.PRICES, { page, perPage: perPage || 10 }, { field: 'id', order: 'DESC' }, {});
        handleChange(REDUX_FORM_NAME, 'post_id', data.id);
      })
      .catch(error => {
        handleShowNotification(error.message, 'error');
      })
      .finally(() => {
        handleFetchEnd();
        handleHideCreateDialog();
        handleReset(formName);
      });
  };

  const handleDelete = () => {
    handleFetchStart();

    dataProvider(DELETE, resources.PRICES, { id: selectedIds[0] })
      .then(() => {
        handleCrudGetList(resources.PRICES, { page, perPage: perPage || 10 }, { field: 'id', order: 'DESC' }, {});
        setSelectedIds(resources.PRICES, []);
      })
      .catch(error => {
        handleShowNotification(error.message, 'error');
      })
      .finally(() => {
        handleFetchEnd();
        handleHideDeleteDialog();
        replace(PRICE_LISTS_ROUTE);
      });
  };

  const currentPriceDescription = get(priceLists, [selectedIds[0], 'description'], '');
  return (
    <Fragment>
      <div className={classes.wrap}>
        <Button
          onClick={() => {
            handleReset(formName);
            changeShowCreateDialog(true);
          }}
          color="primary"
          variant="raised"
          className={classes.button}
        >
          New
        </Button>

        <Button onClick={() => changeShowDeleteDialog(true)} variant="raised" disabled={!selectedIds.length}>
          Delete
        </Button>
      </div>

      <CreateDialog
        open={showCreateDialog}
        onClose={handleHideCreateDialog}
        formName={formName}
        onSubmit={handleSubmit}
        saving={saving}
        handleSaveClick={() => onSubmit(formName)}
      />

      <DeleteDialog
        open={showDeleteDialog}
        onClose={handleHideDeleteDialog}
        handleDelete={handleDelete}
        currentPriceDescription={currentPriceDescription}
      />
    </Fragment>
  );
};

PriceListActions.propTypes = {
  saving: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  perPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceLists: PropTypes.objectOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleFetchEnd: PropTypes.func.isRequired,
  handleFetchStart: PropTypes.func.isRequired,
  handleCrudGetList: PropTypes.func.isRequired,
  handleShowNotification: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  history: historyShape,
};

const mapStateToProps = state => {
  const resourceState = state.admin.resources[resources.PRICES];

  return {
    saving: isSubmitting(formName)(state),
    page: resourceState.list.params.page,
    perPage: resourceState.list.params.perPage,
    priceLists: resourceState.data,
  };
};

const mapDispatchToProps = {
  handleChange: change,
  handleReset: reset,
  handleFetchEnd: fetchEnd,
  handleFetchStart: fetchStart,
  handleCrudGetList: crudGetList,
  handleShowNotification: showNotification,
  onSubmit: submit,
  setSelectedIds: setListSelectedIds,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withStyles(styles),
);

export default enhance(PriceListActions);
