import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';
import { linkToRecord } from 'ra-core';
import { DatagridCell } from 'react-admin';

import { UiTableCheckbox } from './Checkbox';

const sanitizeRestProps = ({
  basePath,
  children,
  classes,
  className,
  rowClick,
  id,
  isLoading,
  onToggleItem,
  push,
  record,
  resource,
  selected,
  style,
  styles,
  ...rest
}) => rest;

const Component = props => {
  const handleToggle = event => {
    props.onToggleItem(props.id);
    event.stopPropagation();
  };

  const handleClick = () => {
    const { basePath, rowClick, id, push } = props;
    if (!rowClick) return;
    if (rowClick === 'edit') {
      push(linkToRecord(basePath, id));
    }
    if (rowClick === 'show') {
      push(linkToRecord(basePath, id, 'show'));
    }
    if (typeof rowClick === 'function') {
      push(rowClick(id, basePath));
    }
  };

  const {
    basePath,
    children,
    classes,
    className,
    hasBulkActions,
    hover,
    id,
    record,
    resource,
    selected,
    style,
    styles,
    ...rest
  } = props;
  return (
    <TableRow
      className={className}
      key={id}
      style={style}
      hover={hover}
      onClick={handleClick}
      {...sanitizeRestProps(rest)}
    >
      {hasBulkActions && (
        <TableCell padding="none" className={classnames(classes.cell, classes.rowCell, 'checkboxCell')}>
          <UiTableCheckbox className={`select-item ${classes.checkbox}`} checked={selected} onClick={handleToggle} />
        </TableCell>
      )}
      {React.Children.map(children, (field, index) =>
        field ? (
          <DatagridCell
            key={`${id}-${field.props.source || index}`}
            className={classnames(`column-${field.props.source}`, classes.cell, classes.rowCell)}
            record={record}
            id={id}
            {...{ field, basePath, resource }}
          />
        ) : null,
      )}
    </TableRow>
  );
};

Component.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object,
};

Component.defaultProps = {
  hasBulkActions: false,
  hover: true,
  record: {},
  selected: false,
};

export const UiDataGridRow = connect(
  null,
  { push },
)(Component);
