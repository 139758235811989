import React, { useMemo, useState } from 'react';
import { ColumnField } from './common/ColumnField';
import { MoreModal } from './common/MoreModal';

export const AllergensField = ({ model }) => {
  if (!model) {
    return null;
  }

  const allergens = useMemo(
    () =>
      model.allergens
        .map(item => item.name)
        .filter(Boolean)
        .join(', '),
    [model.allergens],
  );

  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const openModal = () => setIsOpenedModal(true);
  const closeModal = () => setIsOpenedModal(false);

  return (
    <>
      <ColumnField checked={!!allergens} onClick={openModal} />
      <MoreModal open={isOpenedModal} onClose={closeModal} title="Allergens">
        {allergens}
      </MoreModal>
    </>
  );
};
