import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { UiInput } from './Input';
import { SearchIcon } from './icons/Search';

const styles = () => ({
  filter: {
    width: 328,
    margin: 0,

    '& .filter-field': {
      width: '100%',

      '& > div:last-child': {
        display: 'none',
      },
    },
  },

  input: {
    color: '#818B9E',
    width: '100%',

    '& > div': {
      height: 48,

      '&:before': {
        backgroundColor: '#F6F7F9',
        border: 'none !important',
      },
    },

    '& input': {
      padding: '12px 16px 12px 44px',

      '&::placeholder': {
        color: '#818B9E',
      },
    },
  },

  icon: {
    width: 24,
    height: 24,
    fill: 'none',
    position: 'absolute',
    left: 16,
    top: 12,
  },
});

export const UiSearchInput = withStyles(styles)(({ classes, className, source = 'search', ...props }) => (
  <Filter className={classnames(classes.filter, className)} {...props}>
    <UiInput
      label={false}
      placeholder="Search"
      source={source}
      className={classes.input}
      alwaysOn
      InputProps={{
        startAdornment: <SearchIcon className={classes.icon} />,
      }}
    />
  </Filter>
));

UiSearchInput.propTypes = {
  resource: PropTypes.string.isRequired,
  debounce: PropTypes.number,
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  hideFilter: PropTypes.func,
  setFilters: PropTypes.func,
  showFilter: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  source: PropTypes.string,
};
