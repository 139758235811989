import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import renderTextField from '../../common/form/renderTextField';
import recordShape from '../../common/ui/recordShape';
import { MAX_SAFE_MONEY } from '../../../constants/common';
import { MAX_NUMBER } from '../../../constants/errorMessages';

const styles = {
  root: {
    maxWidth: 73,
  },
};
// There should be a setTimeout otherwise redux-form will override my onBlur
const required = val => (val ? null : 'Required');
const maxValue = val => (Number(val) && Number(val) > MAX_SAFE_MONEY ? MAX_NUMBER : null);
const EditableNumberField = ({ record, source, classes, blur, change }) => {
  const fieldName = `price-${record.id}.${source}`;
  return (
    <Field
      name={fieldName}
      component={renderTextField}
      classes={classes}
      autoComplete="off"
      validate={[required, maxValue]}
      inputProps={{
        onChange: ({ target }) => {
          const { value } = target;
          const filtered = value.match(/^[0-9.]+$/);
          change(fieldName, filtered);
        },
      }}
      onBlur={(e, newVal) =>
        setTimeout(() => {
          if (newVal) {
            blur(fieldName, parseFloat(newVal).toFixed(2));
            return;
          }
          blur(fieldName, '');
        })
      }
    />
  );
};

EditableNumberField.propTypes = {
  source: PropTypes.string,
  record: recordShape,
  classes: PropTypes.objectOf(PropTypes.string),
  blur: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditableNumberField);
