import React from 'react';
import { Field, fieldPropTypes } from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';

import recordShape from '../../../common/ui/recordShape';

const EnhancedCheckBox = ({ input: inputProps }) => <Checkbox {...inputProps} checked={inputProps.value} value="" />;
const CheckboxField = ({ record }) => <Field name={`shouldDelete-${record.id}`} component={EnhancedCheckBox} />;

CheckboxField.propTypes = { record: recordShape };
EnhancedCheckBox.propTypes = { ...fieldPropTypes };
export default CheckboxField;
