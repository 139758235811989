import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import { Typography } from '@material-ui/core';
import { convertUtcToLocal } from '../../../services/date';

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  formClassName,
  headerClassName,
  label,
  linkType,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  source,
  textAlign,
  translateChoice,
  ...props
}) => props;

const toLocaleStringSupportsLocales = (() => {
  try {
    new Date().toLocaleString('i');
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();

export const DateField = ({ className, locales, options, record, showTime = false, source, ...rest }) => {
  if (!record) return null;
  const value = get(record, source);
  if (value == null) return null;
  const date = value instanceof Date ? convertUtcToLocal(value) : new Date(convertUtcToLocal(value));
  // eslint-disable-next-line no-nested-ternary
  const dateString = showTime
    ? toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, options)
      : date.toLocaleString()
    : toLocaleStringSupportsLocales
    ? date.toLocaleDateString(locales, options)
    : date.toLocaleDateString();

  return (
    <Typography component="span" body1="body1" className={className} {...sanitizeRestProps(rest)}>
      {dateString}
    </Typography>
  );
};

DateField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  locales: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  options: PropTypes.object,
  record: PropTypes.object,
  showTime: PropTypes.bool,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
};

const PureDateField = pure(DateField);

PureDateField.defaultProps = {
  addLabel: true,
};

export default PureDateField;
