import { httpGet } from './http';

// {
//   productId,
//   customerId,
//   quantity,
//   weight;
// }

export const apiProductPriceCalc = params => httpGet('ProductPriceCalc', params);
