import React from 'react';
import { instanceOf, string, bool, func, number } from 'prop-types';
import { SelectInput, ReferenceInput, FormDataConsumer, ReferenceField } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { FormTab } from '../../../../common/ui/DOForm';
import DOTextField from '../../../../common/ui/DOTextField';
import { convertUtcToLocal } from '../../../../../services/date';
import formats from '../../../../../constants/formats';
import resources from '../../../../../constants/resources';

const SaleDetails = ({
  isEdit,
  record: { dispatched, userId } = {},
  basePath,
  context,
  resource,
  hidden,
  onChange,
  value,
  position,
  permissions,
}) => {
  return (
    <FormTab
      label="Sale details"
      tabIndex={-1}
      context={context}
      resource={resource}
      hidden={hidden}
      onChange={onChange}
      value={value}
      position={position}
      permissions={permissions}
    >
      <DOTextField source="id" label="Order Number" />
      <ReferenceInput
        label="Currency"
        source="currencyId"
        reference={resources.CURRENCY}
        allowEmpty
        basePath={basePath}
      >
        <SelectInput optionText="description" disabled={!!dispatched} />
      </ReferenceInput>
      <DateInput
        source="timeDate"
        label="Sale Date"
        format={convertUtcToLocal}
        InputProps={{ inputProps: { tabIndex: -1 } }}
        options={{ format: formats.DATE, disabled: true }}
      />
      <FormDataConsumer label="Credit Limit">
        {({ formData }) => (
          <ReferenceField
            source="customerId"
            reference={resources.CUSTOMER}
            linkType={false}
            allowEmpty
            record={formData}
            basePath={basePath}
          >
            <DOTextField source="creditLimit" />
          </ReferenceField>
        )}
      </FormDataConsumer>
      <FormDataConsumer label="Balance">
        {({ formData }) => (
          <ReferenceField
            source="customerId"
            reference={resources.CUSTOMER}
            linkType={false}
            allowEmpty
            record={formData}
            basePath={basePath}
          >
            <DOTextField source="currentCredit" />
          </ReferenceField>
        )}
      </FormDataConsumer>
      <FormDataConsumer label="Sales Person">
        {({ formData }) =>
          isEdit ? (
            <ReferenceField
              source={userId ? 'userId' : 'customerId'}
              reference={userId ? resources.USER : resources.CUSTOMER}
              linkType={false}
              record={formData}
              basePath={basePath}
            >
              <DOTextField source={userId ? 'fullName' : 'customerCode'} />
            </ReferenceField>
          ) : (
            <DOTextField
              source="fullName"
              record={{ fullName: localStorage.getItem('fullName') || localStorage.getItem('username') }}
            />
          )
        }
      </FormDataConsumer>
    </FormTab>
  );
};

export default SaleDetails;

SaleDetails.propTypes = {
  isEdit: bool,
  record: instanceOf(Object),
  resource: string,
  basePath: string,
  context: string,
  hidden: bool,
  onChange: func,
  value: number,
  position: string,
  permissions: string,
};
