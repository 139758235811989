import React from 'react';
import PropTypes from 'prop-types';
import { DateField, NumberField, ReferenceField, TextField, WithPermissions } from 'react-admin';
import { SendReports } from '../common/ui/SendReports';
import resources from '../../constants/resources';
import formats from '../../constants/formats';
import exporter from './exporter';
import numberDigits from '../../constants/numberFormat';
import { roles } from '../../constants/roles';
import { CUSTOMERS_ROUTE } from '../../constants/routes';
import { UiList } from '../common/ui/NewDesign/List';
import { NewDesignFilter } from './NewDesignFilter';
import { UiTable } from '../common/ui/NewDesign/Table/index';
import { CustomerTableCell } from '../common/tableCells/Customer';
import { useMediaQuery } from '../../utils/hooks/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  table: {
    '& .deliveryAddress': {
      width: 400,

      '@media (max-width: 1440px)': {
        width: 228,
      },
    },
  },
  field: {
    display: 'inline-block',
    width: '100%',
  },
});

const OrderedCompletedField = withStyles(styles)(({ record, classes }) => (
  <span className={classes.field}>{record.orderFilled ? 'Completed' : 'Ordered'}</span>
));

const SalesOrdersList = ({ permissions, classes, ...props }) => {
  const hideDeliveryField = useMediaQuery('(max-width: 1024px)');

  const isUser = permissions === roles.USER_ROLE;

  return permissions ? (
    <UiList
      classNameTable={classes.table}
      title={isUser ? 'Orders' : 'Orders'}
      {...props}
      filters={<NewDesignFilter isUser={isUser} />}
      bulkActionButtons={isUser ? <SendReports url={resources.SEND_SALE_ORDER_REPORTS} /> : undefined}
      exporter={exporter}
      sort={{ field: 'customerId', order: 'DESC' }}
      key={hideDeliveryField}
    >
      {tableProps => (
        <UiTable rowClick="edit" showEditAction {...tableProps}>
          {/*<NumberField label="ID" source="id" options={{ useGrouping: false }} />*/}
          <ReferenceField
            label="Customer"
            source="customerId"
            reference="customer"
            basePath={CUSTOMERS_ROUTE}
            linkType={isUser ? 'show' : false}
            allowEmpty
          >
            <CustomerTableCell />
          </ReferenceField>
          <NumberField label={isUser ? 'Order' : 'Sale Order'} source="id" options={{ useGrouping: false }} />
          <NumberField label="PO Number" source="purchaseOrderNumber" options={{ useGrouping: false }} />
          <NumberField label="Currency Rate" source="currency.rate" textAlign="left" options={numberDigits} />
          {!hideDeliveryField && (
            <TextField label="Delivery Address" source="deliveryAddress" className="deliveryAddress" />
          )}
          <NumberField label="Total" source="netTotal" textAlign="left" options={numberDigits} />
          <DateField
            label="Order Date"
            source="timeDate"
            locales={formats.LOCALES}
            options={{ year: 'numeric', month: 'short', day: 'numeric' }}
          />
          <OrderedCompletedField label="Status" source="orderFilled" />
        </UiTable>
      )}
    </UiList>
  ) : null;
};

SalesOrdersList.propTypes = {
  permissions: PropTypes.string,
};

const SalesOrdersListWithPermissions = props => (
  <WithPermissions render={({ permissions }) => <SalesOrdersList permissions={permissions} {...props} />} />
);

export default withStyles(styles)(SalesOrdersListWithPermissions);
