/* eslint no-throw-literal: 0 */
import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import { API_AUTHORIZATION_URL, API_URL } from '../constants/urls';
import {
  clearDBId,
  clearTokens,
  getDBId,
  getHeaders,
  getRole,
  hasTokens,
  saveCustomerId,
  saveDBId,
  saveTokens,
} from './tokenUtils';
import resources from '../constants/resources';
import { customHistory } from '../history';
import { SUPPLIER_SELECTION_ROUTE } from '../constants/routes';
import { roles } from '../constants/roles';

export const getUserInfo = async () => {
  const request = new Request(API_AUTHORIZATION_URL, {
    headers: getHeaders(true),
  });

  const response = await fetch(request);

  if (response.status < 200 || response.status >= 300) {
    throw response.statusText;
  }

  const data = await response.json();

  saveCustomerId(data.customerID);
};

export const getCurrentSupplier = async () => {
  const request = new Request(`${API_URL}/${resources.SUPPLIER_SELECTION}/single-supplier`, {
    headers: getHeaders(true),
  });

  const response = await fetch(request);
  const data = await response.json();

  if (data.hasMultipleSuppliers) {
    clearDBId();
  } else if (data.currentSupplier) {
    saveDBId(data.currentSupplier.uuid);
    await getUserInfo();
  }
};

export const authLogin = async params => {
  const { username, password } = params;
  const request = new Request(API_AUTHORIZATION_URL, {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: getHeaders(),
  });
  const response = await fetch(request);

  if (response.status === 401 || response.status === 403) {
    throw 'Your username or password is incorrect, if you don’t remember your credentials - contact your admin';
  }

  if (response.status < 200 || response.status >= 300) {
    throw response.statusText;
  }

  const data = await response.json();

  if (data.role === roles.API_USER_ROLE) {
    throw 'Wrong role!';
  }

  saveTokens(data);

  await getCurrentSupplier();
};

const authProvider = async (type, params) => {
  switch (type) {
    case AUTH_LOGIN: {
      await authLogin(params);

      return;
    }
    case AUTH_LOGOUT: {
      clearTokens();
      return;
    }

    case AUTH_ERROR: {
      return;
    }

    case AUTH_CHECK: {
      if (hasTokens()) {
        if (!getDBId()) {
          return customHistory.replace(SUPPLIER_SELECTION_ROUTE);
        }

        return;
      }
      throw null;
    }

    case AUTH_GET_PERMISSIONS: {
      const role = getRole();
      return role ? Promise.resolve(role) : Promise.reject();
    }

    default:
      throw 'Unknown method';
  }
};

export default authProvider;
