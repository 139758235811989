import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import resources from '../../constants/resources';
import formats from '../../constants/formats';

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'customerId', resources.CUSTOMER).then(customers => {
    const data = records.map(
      ({ id, purchaseOrderNumber, customerId, currencyRate, deliveryAddress, netTotal, timeDate }) => ({
        ID: id,
        'Sale Order': id,
        'PO Number': purchaseOrderNumber,
        Customer: ((customers[customerId] || {}).companyName || '').trim(),
        'Currency Rate': currencyRate,
        'Delivery Address': (deliveryAddress || '').trim(),
        Total: netTotal,
        'Order Date': moment(timeDate).format(formats.DATE_TIME_EXPORT),
      }),
    );
    const csv = convertToCSV({
      data,
    });
    downloadCSV(csv, `sales_orders_${moment().format(formats.MOMENT)}`);
  });
};

export default exporter;
