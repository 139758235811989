import { API_URL } from '../constants/urls';
import { getHeaders } from '../provider/tokenUtils';

const getQueryString = obj => `?${new URLSearchParams(obj).toString()}`;

export const httpGet = (url, data, options) => {
  const request = new Request(`${API_URL}/${url}${data ? getQueryString(data) : ''}`, {
    headers: getHeaders(true),
    ...options,
  });

  return fetch(request).then(response => response.json());
};

export const httpPost = (url, data, options) => {
  const request = new Request(`${API_URL}/${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getHeaders(true),
    ...options,
  });

  return fetch(request).then(response => response.json());
};
